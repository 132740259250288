export const readFile = <T>(callback: (reader: FileReader) => void) => {
  return new Promise<T | undefined>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => resolve(event.target?.result as T);
    reader.onerror = (event) => reject(event.target?.error ?? new Error(`Unknown error while reading file.`));

    callback(reader);
  });
};

export const readFileAsText = async (file: File) => {
  return (await readFile<string>((r) => r.readAsText(file, 'UTF-8'))) ?? '';
};
