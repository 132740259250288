import { translations } from '@binhatch/locale';
import { BeneficiaryKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface Props {
  type: BeneficiaryKind;
  beneficiaries?: { id: string; name?: string; url?: string }[];
}

export const CustomDealBeneficiaries: React.FC<Props> = ({ type, beneficiaries }) => (
  <React.Fragment>
    {beneficiaries?.map((beneficiary, index) => (
      <div className="truncate" key={index}>
        <FormattedMessage
          id={translations.components.customDealBeneficiaries.beneficiaries}
          values={{
            type,
            beneficiary: beneficiary.name ?? '???',
            a: (children) => {
              if (!beneficiary.url) return <span>{children}</span>;
              return (
                <Link className="font-semibold" state={{ from: 1 }} to={beneficiary.url}>
                  {children}
                </Link>
              );
            }
          }}
        />
      </div>
    ))}
  </React.Fragment>
);
