import { useRemoteData } from '@binhatch/hooks';
import { BackofficeConfig } from 'flexinet-api';
import React from 'react';
import { useLocalStorage } from 'react-use';
import { createContainer } from 'unstated-next';

import { configurationApi } from '@/integrations/api';

import { Auth } from './useAuth';

interface RemoteConfiguration extends BackofficeConfig {}

interface LocalConfiguration {
  realtime?: boolean;
}

const useConfiguration = (initial: LocalConfiguration = {}) => {
  const { context } = Auth.useContainer();
  const [local, setLocal] = useLocalStorage('config', initial);

  const userId = context?.user.id;

  const features = useRemoteData({ key: 'useFeatures', userId, skip: !userId }, ({ userId }) => {
    if (!userId) throw new Error('No user specified.');

    return configurationApi.listFeatures().then((r) => new Set(r.data.features));
  });

  const remote = useRemoteData({ key: 'useConfiguration', userId, skip: !userId }, async ({ userId }): Promise<RemoteConfiguration> => {
    if (!userId) throw new Error('No user specified.');

    return await configurationApi.listConfigurations().then((r) => r.data);
  });

  return React.useMemo(() => {
    return [
      {
        value: { ...local, ...remote.data, features: features.data },
        loading: remote.isLoading || features.isLoading
      },
      setLocal,
      () => remote.mutate()
    ] as const;
  }, [local, setLocal, remote, features]);
};

export const Configuration = createContainer(useConfiguration);
