import { promotionApi, reportApi } from '@/integrations/api';
import { useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  AsyncSelect,
  DatePickerInput,
  Form,
  InputWithLabel,
  InstanceProps,
  LoadingState,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  SearchableSelect,
  Select,
  SubmitError,
  ValidatedField
} from '@binhatch/ui';
import { fromPromotion, sortPeriods, toDate } from '@binhatch/utility';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { Promotion, ReportResponse } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

interface Props extends InstanceProps<ReportResponse> {}

const schema = yup
  .object({
    type: yup.string().required().label(translations.fields.reportType.label),
    promotionId: yup.string().nullable().label(translations.fields.reportPromotion.label),
    periodId: yup.number().nullable().label(translations.fields.reportPeriod.label),
    startAt: yup.date().label(translations.fields.reportStart.label),
    endAt: yup.date().min(yup.ref('startAt')).label(translations.fields.reportEnd.label)
  })
  .required();

export const CreateReportModal = React.forwardRef<HTMLDivElement, Props>(({ initialFocus, className, onAction, onClose }, ref) => {
  const intl = useIntl();

  const [promotions, setPromotions] = React.useState<Promotion[]>([]);

  const types = useRemoteData({ key: `useReportTypes` }, async () => reportApi.getReports().then((r) => r.data.types));

  const initialValues = React.useMemo(
    () => ({
      type: types.data?.[0] ?? '',
      promotionId: null,
      periodId: null,
      startAt: undefined,
      endAt: undefined
    }),
    [types.data]
  );

  const onSubmit = React.useCallback(
    async ({ type, startAt, endAt, promotionId }: yup.InferType<typeof schema>) => {
      const report = await reportApi
        .generateReport(type, {
          startDate: startAt ? toDate(startAt.toISOString()) : undefined,
          endDate: endAt ? toDate(dayjs(endAt).endOf('day').toISOString()) : undefined,
          referenceID: promotionId ? promotionId : undefined
        })
        .then((r) => r.data);

      onAction(report);
    },
    [onAction]
  );

  return (
    <div {...{ ref }} className={classnames(className, 'max-w-lg')}>
      <ModalLayout>
        <ModalHeader {...{ onClose }}>
          <FormattedMessage id={translations.modals.createReport.title} />
        </ModalHeader>

        <Form {...{ schema, initialValues, onSubmit }}>
          {({ values, submitting, submitError, handleSubmit, form }) => (
            <LoadingState loading={types.isLoading}>
              <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                <ValidatedField
                  field={InputWithLabel}
                  id="type"
                  input={Select}
                  items={
                    types.data?.map((type) => ({
                      value: type,
                      name: intl.formatMessage({
                        id:
                          type in translations.enum.reportType
                            ? // TODO
                              translations.enum.reportType[type as keyof typeof translations.enum.reportType]
                            : translations.enum.reportType.unknown
                      })
                    })) ?? []
                  }
                  label={<FormattedMessage id={translations.fields.reportType.label} />}
                  name="type"
                  placeholder={intl.formatMessage({ id: translations.fields.reportType.placeholder })}
                  readOnly={!!submitting}
                />

                <ValidatedField
                  allowNull
                  field={InputWithLabel}
                  getItemsByIds={(ids: string[]) =>
                    Promise.all(ids.map((id) => promotionApi.getPromotion(id).then((r) => fromPromotion(r.data)))).then((items) => [
                      { value: null, name: intl.formatMessage({ id: translations.pages.reports.all }) },
                      ...items.map(({ id, name }) => ({ value: id, name }))
                    ])
                  }
                  getItemsBySearch={async (search: string) => {
                    const items = await promotionApi
                      .listPromotions(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, search)
                      .then((r) => r.data.promotions.map(fromPromotion));

                    setPromotions(items);

                    return [
                      { value: null, name: intl.formatMessage({ id: translations.pages.reports.all }) },
                      ...items.map((promotion) => ({ value: promotion.id, name: promotion.name }))
                    ];
                  }}
                  id="promotion"
                  input={AsyncSelect}
                  label={<FormattedMessage id={translations.fields.reportPromotion.label} />}
                  name="promotionId"
                  placeholder={intl.formatMessage({ id: translations.fields.reportPromotion.placeholder })}
                  readOnly={!!submitting}
                  onChange={(promotionId: string) => {
                    const promotion = promotions.find((p) => p.id === promotionId);
                    const startAt = promotion?.startAt;
                    const endAt = promotion?.endAt;

                    form.change('periodId', null);

                    if (startAt) form.change('startAt', new Date(startAt));
                    if (endAt) form.change('endAt', new Date(endAt));
                  }}
                />

                <ValidatedField
                  allowNull
                  field={InputWithLabel}
                  id="period"
                  input={SearchableSelect}
                  items={[
                    { value: null, name: intl.formatMessage({ id: translations.pages.reports.all }) },
                    ...sortPeriods(promotions.find((p) => p.id === values.promotionId)?.periods ?? []).map((p) => ({
                      value: p.id,
                      name: intl.formatDateTimeRange(new Date(p.startAt), new Date(p.endAt), {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                      })
                    }))
                  ]}
                  label={<FormattedMessage id={translations.fields.reportPeriod.label} />}
                  name="periodId"
                  placeholder={intl.formatMessage({ id: translations.fields.reportPeriod.placeholder })}
                  readOnly={!!submitting}
                  onChange={(periodId?: number) => {
                    const promotion = promotions.find((p) => p.id === values.promotionId);
                    const period = promotion?.periods?.find((p) => p.id === periodId);
                    const startAt = period?.startAt ?? promotion?.startAt;
                    const endAt = period?.endAt ?? promotion?.endAt;
                    if (startAt) form.change('startAt', new Date(startAt));
                    if (endAt) form.change('endAt', new Date(endAt));
                  }}
                />

                <div className="grid grid-cols-2 gap-2">
                  <ValidatedField
                    as={DatePickerInput}
                    clearable
                    disabled={!!submitting}
                    field={InputWithLabel}
                    id="start-at"
                    label={<FormattedMessage id={translations.fields.reportStart.label} />}
                    max={values.endAt}
                    name="startAt"
                    placeholder={intl.formatMessage({ id: translations.fields.reportStart.placeholder })}
                    type="text"
                  />

                  <ValidatedField
                    as={DatePickerInput}
                    clearable
                    disabled={!!submitting}
                    field={InputWithLabel}
                    id="end-at"
                    label={<FormattedMessage id={translations.fields.reportEnd.label} />}
                    min={values.startAt}
                    name="endAt"
                    placeholder={intl.formatMessage({ id: translations.fields.reportEnd.placeholder })}
                    type="text"
                    view={values.endAt ?? values.startAt}
                  />
                </div>

                <SubmitError error={submitError} />

                <div className="flex flex-row-reverse space-x-2">
                  <ModalPrimaryButton ref={initialFocus} onAction={() => handleSubmit()}>
                    <FormattedMessage id={translations.buttons.add} />
                  </ModalPrimaryButton>

                  <ModalSecondaryButton {...{ onClose }}>
                    <FormattedMessage id={translations.buttons.back} />
                  </ModalSecondaryButton>
                </div>
              </form>
            </LoadingState>
          )}
        </Form>
      </ModalLayout>
    </div>
  );
});
