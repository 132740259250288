import { translations } from '@binhatch/locale';
import { CalendarIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Period, Promotion, UserPromotion } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DayCount } from './DayCount';
import { PromotionPeriod } from './PromotionPeriod';

interface Props {
  promotion: Promotion | UserPromotion;
  days: number;
  period?: Period;
  className?: string;
}

export const PromotionItemPeriod: React.FC<Props> = ({ promotion, period, days, className }) => (
  <div className={classNames('flex flex-wrap items-center gap-x-2 gap-y-1 text-sm', className)}>
    <CalendarIcon className="h-6 w-6" />

    {!!period && <PromotionPeriod endAt={new Date(period.endAt)} startAt={new Date(period.startAt)} />}

    {!!promotion.repetition && (
      <span>
        (<FormattedMessage id={translations.enum.repetition[promotion.repetition]} />)
      </span>
    )}

    <DayCount className="whitespace-nowrap" count={days} />
  </div>
);
