import { translations } from '@binhatch/locale';
import { Coin, NavigationButton, NavigationContentWithIcon, NavigationItem, NavigationLink, Skeleton } from '@binhatch/ui';
import { getUserName } from '@binhatch/utility';
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  BriefcaseIcon,
  BuildingStorefrontIcon,
  Cog8ToothIcon,
  GiftIcon,
  PresentationChartLineIcon,
  SparklesIcon,
  StarIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Feature, TransactionCurrencyKind, UserRole } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';
import { SideNavigation as NavigationState } from '@/containers/useSideNavigation';
import { useBalance } from '@/hooks/useBalance';

import { ENVIRONMENT, VERSION } from '@/config';

import { Configuration } from '@/containers/useConfiguration';
import { Logo } from './Logo';

const UserBalance: React.FC = () => {
  const { context } = Auth.useContainer();
  const balance = useBalance();

  if (context?.user.role !== UserRole.SystemManager) return null;

  return (
    <React.Fragment>
      {[TransactionCurrencyKind.CustomDealBudget, TransactionCurrencyKind.SalesBudget].map((type) => (
        <div className="flex items-center justify-between" key={type}>
          <FormattedMessage id={translations.enum.transactionCurrencyKind[type]} />

          <Coin>
            <Skeleton className="w-10" size="h-5" visible={!balance}>
              <FormattedNumber style="decimal" value={balance.data?.[type]?.amount ?? 0} />
            </Skeleton>
          </Coin>
        </div>
      ))}
    </React.Fragment>
  );
};

export const Navigation: React.FC = () => {
  const { context, logout } = Auth.useContainer();
  const [{ value: config }] = Configuration.useContainer();
  const { isOpen, toggle } = NavigationState.useContainer();

  return (
    <header className="z-10 h-14 w-full flex-shrink-0 lg:h-full lg:w-72">
      <div className={classNames('bg-shade fixed left-0 top-0 z-10 w-full lg:w-72', isOpen ? 'h-full' : 'h-14 lg:h-full')}>
        <div className="relative flex items-center px-4 py-2 xl:px-8 xl:py-6">
          <Logo className="h-10 xl:h-14" />

          <button
            className={classNames(
              'absolute right-4 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded border border-gray-200 lg:hidden'
            )}
            onClick={toggle}
          >
            {isOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
          </button>
        </div>

        <div
          className={classNames('absolute bottom-0 left-0 top-14 flex w-full flex-col overflow-y-auto xl:top-28', {
            'hidden lg:flex': !isOpen
          })}
        >
          <ul className="flex-1">
            <li>
              <NavigationItem className="space-y-2">
                <div className="flex items-center font-medium">
                  <UserCircleIcon className="mr-3 h-6 w-6 flex-shrink-0" />
                  <div className="truncate">{context?.user ? getUserName(context.user) || context.user.details.email : ' '}</div>
                </div>

                <UserBalance />
              </NavigationItem>
            </li>

            <li>
              <NavigationLink to={urls.promotions.root}>
                <NavigationContentWithIcon icon={StarIcon}>
                  <FormattedMessage id={translations.pages.promotionList.title} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            {config.features?.has(Feature.CustomDeals) && (
              <li>
                <NavigationLink to={urls.customDeals.root}>
                  <NavigationContentWithIcon icon={SparklesIcon}>
                    <FormattedMessage id={translations.pages.customDealList.title} />
                  </NavigationContentWithIcon>
                </NavigationLink>
              </li>
            )}

            {(context?.user.role === UserRole.SystemAdmin || config.features?.has(Feature.CustomDeals)) && (
              <li>
                <NavigationLink to={urls.shop.root}>
                  <NavigationContentWithIcon icon={BuildingStorefrontIcon}>
                    <FormattedMessage
                      id={context?.user.role === UserRole.SystemAdmin ? translations.pages.shop.title.system : translations.pages.shop.title.customer}
                    />
                  </NavigationContentWithIcon>
                </NavigationLink>
              </li>
            )}

            <li>
              <NavigationLink to={urls.orders.root}>
                <NavigationContentWithIcon icon={GiftIcon}>
                  <FormattedMessage id={translations.pages.orderList.title} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationLink to={urls.sales.root}>
                <NavigationContentWithIcon icon={UsersIcon}>
                  <FormattedMessage id={translations.pages.salesUserList.title} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationLink to={urls.clients.root}>
                <NavigationContentWithIcon icon={BriefcaseIcon}>
                  <FormattedMessage id={translations.pages.clientList.title} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            {context?.user.role === UserRole.SystemAdmin && (
              <li>
                <NavigationLink to={urls.reports}>
                  <NavigationContentWithIcon icon={PresentationChartLineIcon}>
                    <FormattedMessage id={translations.pages.reports.title} />
                  </NavigationContentWithIcon>
                </NavigationLink>
              </li>
            )}

            <li>
              <NavigationLink to={urls.settings.root}>
                <NavigationContentWithIcon icon={Cog8ToothIcon}>
                  <FormattedMessage id={translations.pages.settings.title} />
                </NavigationContentWithIcon>
              </NavigationLink>
            </li>

            <li>
              <NavigationButton onClick={() => logout()}>
                <NavigationContentWithIcon icon={ArrowLeftOnRectangleIcon}>
                  <FormattedMessage id={translations.buttons.logout} />
                </NavigationContentWithIcon>
              </NavigationButton>
            </li>
          </ul>

          <div className="p-4 text-center text-sm">
            {ENVIRONMENT} - v{VERSION}
          </div>
        </div>
      </div>
    </header>
  );
};
