import { useRemoteData } from '@binhatch/hooks';
import { getAllFromApi, getUserName } from '@binhatch/utility';
import { Promotion } from 'flexinet-api';

import { userApi } from '@/integrations/api';
import { urls } from '@/utils/url';

export const useCustomDealOwner = (promotions?: Promotion[]) => {
  return useRemoteData({ key: 'useCustomDealOwner', promotions }, async ({ promotions }) => {
    const ownerIds = promotions?.map((p) => p.ownerID);

    const owners = ownerIds?.length
      ? await getAllFromApi(
          (nextToken) =>
            userApi
              .listUsers(nextToken, undefined, undefined, undefined, Array.from(new Set(ownerIds)))
              .then((r) => r.data),
          (r) => r.users
        )
      : [];

    return promotions?.reduce((map, promotion) => {
      const owner = owners.find((u) => u.id === promotion.ownerID);

      if (!owner) return map;

      map.set(promotion.id, {
        id: owner.id,
        name: getUserName(owner) || owner.details.email,
        url: urls.sales.getOne({ userId: owner.id })
      });

      return map;
    }, new Map<string, { id: string; name?: string; url: string }>());
  });
};
