import { QueryParamConfig, decodeSingleQueryParam, useQueryParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { DynamicBackButton, EmptyState, LoadingState, PageHeading, Pagination, ProductImage } from '@binhatch/ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { urls } from '@/utils/url';

import { usePendigProducts } from './hooks/usePendingProducts';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const config = { page };

export const ProductReviewPage: React.FC = () => {
  const [query, updateQuery] = useQueryParams({ config });

  const products = usePendigProducts({ nextToken: query.page });

  return (
    <main className="flex flex-col gap-8">
      <div>
        <DynamicBackButton />

        <PageHeading title={<FormattedMessage id={translations.pages.productReview.title} />} />
      </div>

      <LoadingState loading={products.isLoading || products.isValidating}>
        {!products.isLoading && !products.data?.data.length && (
          <EmptyState>
            <FormattedMessage id={translations.pages.productList.empty} />
          </EmptyState>
        )}

        <ul className="grid min-h-[7rem] w-full flex-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {products.data?.data.map((product) => (
            <li className="relative overflow-hidden rounded-lg shadow" key={product.id}>
              <Link
                className="relative block h-full w-full space-y-2 overflow-hidden rounded-lg bg-white p-4"
                state={{ from: 1 }}
                to={urls.shop.products.getOne({ productId: product.id }, urls.shop.products.one)}
              >
                <ProductImage className="w-full" square src={product.media[0]} />

                <div className="flex flex-col items-center py-2">
                  <div className="max-w-full truncate font-semibold">{product.name}</div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </LoadingState>

      <Pagination
        hasNext={!!products.data?.nextToken}
        hasPrevious={!!products.data?.prevToken}
        onNext={() => updateQuery({ page: products.data?.nextToken })}
        onPrevious={() => updateQuery({ page: products.data?.prevToken })}
      />
    </main>
  );
};
