import React from 'react';
import { useLocation, useNavigate } from 'react-router';

export const useDynamicBack = (fallback?: () => void) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return React.useCallback(() => {
    if (!state?.from) return fallback?.();

    return navigate(-1);
  }, [state?.form, fallback]);
};
