import { translations } from '@binhatch/locale';
import { Button, CoinValue, Loading, LoadingIndicator, ProductImage, Stepper, ValidationMessage } from '@binhatch/ui';
import { Dialog, Transition } from '@headlessui/react';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';
import { Cart } from '@/containers/useCart';

import { useBalance } from '@/hooks/useBalance';

export const CartSlideOver: React.FC = () => {
  const navigate = useNavigate();
  const { context } = Auth.useContainer();
  const cart = Cart.useContainer();
  const balance = useBalance();

  const exceeded = !context || (balance.data?.salesBudget?.amount ?? 0) < cart.total;
  const outOfStock = cart.items.some((item) => item.quantity > (item.product?.quantity ?? 0));
  const disabled = exceeded || outOfStock || cart.loading || cart.items.length === 0;

  const onClose = React.useCallback(() => cart.toggle(false), [cart]);

  React.useEffect(() => {
    cart.toggle(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <Transition.Root as={React.Fragment} show={cart.open}>
      <Dialog as="div" className="relative z-10" {...{ onClose }}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="bg-main flex h-full flex-col overflow-y-scroll py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="flex h-10 items-center gap-4 font-semibold leading-6 text-gray-900">
                          <div>
                            <FormattedMessage id={translations.pages.shop.cart.title} />
                          </div>

                          <Loading visible={cart.loading}>
                            <LoadingIndicator className="h-5 w-5" />
                          </Loading>
                        </Dialog.Title>

                        <div className="ml-3 flex h-7 items-center">
                          <button className="focus:ring-brand relative rounded" type="button" onClick={onClose}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">
                              <FormattedMessage id={translations.pages.shop.cart.continue} />
                            </span>
                            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="relative mt-6 flex flex-1 flex-col gap-4 px-4 sm:px-6">
                      <div className="flex flex-col gap-4 rounded-lg bg-white p-6">
                        {cart.items.length > 0 ? (
                          <React.Fragment>
                            <ul className="border-shade space-y-4 border-b">
                              {cart.items.map((item) => (
                                <li className="relative flex gap-4" key={item.productId}>
                                  <ProductImage className="w-16 md:w-24" square src={item.product.media[0]} />

                                  <div className="flex flex-1 flex-col gap-2">
                                    <div className="font-semibold">{item.product?.name}</div>

                                    <div className="flex items-center justify-between">
                                      <Stepper
                                        value={item.quantity}
                                        onChange={(value) => cart.update(item.product, value)}
                                      />

                                      <CoinValue value={item.product?.value ?? 0} />
                                    </div>

                                    <ValidationMessage visible={item.quantity > (item.product?.quantity ?? 0)}>
                                      <FormattedMessage id={translations.fields.productQuantity.label} />:{' '}
                                      {item.product?.quantity}
                                    </ValidationMessage>

                                    <div className="-mr-4 flex justify-end">
                                      <Button
                                        appearance="secondary"
                                        className="h-10 px-4"
                                        onClick={() => cart.update(item.product, 0)}
                                      >
                                        <TrashIcon className="mr-2 h-4 w-4" />
                                        <FormattedMessage id={translations.buttons.delete} />
                                      </Button>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>

                            <div className="flex items-center justify-between">
                              <FormattedMessage id={translations.pages.shop.cart.total} />

                              <CoinValue value={cart.total} />
                            </div>

                            <div className="flex items-start justify-between">
                              <div>
                                <div>
                                  <FormattedMessage id={translations.enum.transactionCurrencyKind.salesBudget} />
                                </div>
                              </div>

                              <CoinValue value={balance?.data?.salesBudget?.amount ?? 0} />
                            </div>
                          </React.Fragment>
                        ) : (
                          <div>
                            <FormattedMessage id={translations.pages.shop.cart.empty} />
                          </div>
                        )}

                        {exceeded && (
                          <div className="text-error">
                            <FormattedMessage id={translations.pages.shop.cart.errors.balanceExceeded} />
                          </div>
                        )}

                        {!!cart.outOfStock && (
                          <div className="bg-warning p-4 text-yellow-700">
                            <FormattedMessage id={translations.pages.shop.cart.errors.outOfStock} />
                          </div>
                        )}

                        {cart.items.length > 0 && (
                          <div>
                            <Button
                              appearance="primary"
                              className={classNames('h-14 w-full px-4', { 'pointer-events-none': exceeded })}
                              {...{ disabled }}
                              onClick={() => navigate(urls.shop.cart, { state: { from: 1 } })}
                            >
                              <FormattedMessage id={translations.pages.shop.cart.checkout} />
                            </Button>
                          </div>
                        )}
                      </div>

                      <div>
                        <Button appearance="secondary" className="h-14 w-full px-4" onClick={onClose}>
                          <FormattedMessage id={translations.pages.shop.cart.continue} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
