import classNames from 'classnames';
import React from 'react';

import { MaybeImage } from '../MaybeImage';

interface Props {
  image?: string;
  logo?: string;
  className?: string;
}

export const PromotionImage: React.FC<Props> = ({ logo, image, className }) => (
  <div className={classNames('relative flex-shrink-0', className)}>
    <MaybeImage className="aspect-square w-full rounded-t object-contain object-center" src={image} />
    <MaybeImage className="aspect-square w-full rounded-b object-contain object-center" src={logo} />
  </div>
);
