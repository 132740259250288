import { useAsyncFn, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  AsyncButton,
  Button,
  Card,
  Input,
  InputProps,
  InputWithLabel,
  LoadingState,
  Modal,
  useModal
} from '@binhatch/ui';
import { getAllFromApi } from '@binhatch/utility';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { integrationApi } from '@/integrations/api';
import { ApiKeyKind } from 'flexinet-api';
import { CreateWebhookModal } from './CreateWebhookModal';

const CopyButton: React.FC<{ copy: string }> = ({ copy }) => {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (!copied) return;

    const id = setTimeout(() => setCopied(false), 3000);

    return () => clearTimeout(id);
  }, [copied]);

  return (
    <AsyncButton
      appearance="primary"
      className="h-8 px-2"
      onClick={() => {
        navigator.clipboard.writeText(copy);
        setCopied(true);
      }}
    >
      <FormattedMessage
        id={copied ? translations.pages.settings.integrations.copied : translations.pages.settings.integrations.copy}
      />
    </AsyncButton>
  );
};

export const SettingsIntegrationsPage: React.FC = () => {
  const modal = useModal(CreateWebhookModal);

  const [{ value: apiKey }, generateApiKey] = useAsyncFn(() =>
    integrationApi.generateApiKey({ kind: ApiKeyKind.Api }).then((r) => r.data.key)
  );

  const [{ value: webhookApiKey }, generateWebhookApiKey] = useAsyncFn(() =>
    integrationApi.generateApiKey({ kind: ApiKeyKind.Webhook }).then((r) => r.data.key)
  );

  const webhooks = useRemoteData({ key: 'useWebhooks' }, () =>
    getAllFromApi(
      () => integrationApi.listWebhooks().then((r) => r.data),
      (r) => r.webhooks
    )
  );

  return (
    <React.Fragment>
      <div className="flex flex-col gap-8">
        <Card className="flex max-w-3xl flex-col gap-4">
          <div className="flex items-end gap-2">
            <InputWithLabel
              className="w-full max-w-sm"
              input={(props: InputProps<any>) => (
                <div className="group relative">
                  <Input {...props} className="w-full font-mono" />

                  {!!apiKey && (
                    <div className="invisible absolute right-1 top-1 group-hover:visible">
                      <CopyButton copy={apiKey} />
                    </div>
                  )}
                </div>
              )}
              label="Api Key"
              readOnly
              value={apiKey ?? '••••••••••••••••'}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
            />

            <AsyncButton appearance="outline" className="h-10 px-4" onClick={() => generateApiKey()}>
              Generate new
            </AsyncButton>
          </div>

          <div className="flex max-w-3xl items-end gap-2">
            <InputWithLabel
              className="w-full max-w-sm"
              input={(props: InputProps<any>) => (
                <div className="group relative">
                  <Input {...props} className="w-full font-mono" />

                  {!!webhookApiKey && (
                    <div className="invisible absolute right-1 top-1 group-hover:visible">
                      <CopyButton copy={webhookApiKey} />
                    </div>
                  )}
                </div>
              )}
              label="Webhook Api Key"
              readOnly
              value={webhookApiKey ?? '••••••••••••••••'}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
            />

            <AsyncButton appearance="outline" className="h-10 px-4" onClick={() => generateWebhookApiKey()}>
              Generate new
            </AsyncButton>
          </div>
        </Card>

        <Card className="max-w-3xl">
          <LoadingState loading={webhooks.isLoading}>
            <div className="flex max-w-sm flex-col gap-4">
              <InputWithLabel input={() => null} label="Webhooks" />

              {!!webhooks.data?.length && (
                <table className="text-sm">
                  <thead>
                    <tr>
                      <td className="py-2 font-semibold">
                        <FormattedMessage id={translations.fields.webhookUrl.label} />
                      </td>
                      <td className="py-2 pl-8 font-semibold">
                        <FormattedMessage id={translations.fields.webhookType.label} />
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {webhooks.data?.map((webhook, index) => (
                      <tr key={index}>
                        <td className="max-w-sm py-2">
                          <div className="truncate">{webhook.url}</div>
                        </td>
                        <td className="py-2 pl-8">
                          <FormattedMessage id={translations.enum.webhookKind[webhook.kind]} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              <div>
                <Button
                  appearance="outline"
                  className="h-10 px-4"
                  onClick={() =>
                    modal
                      .open({})
                      .then(() => webhooks.mutate())
                      .catch(() => void 0)
                  }
                >
                  <FormattedMessage id={translations.buttons.add} />
                </Button>
              </div>
            </div>
          </LoadingState>
        </Card>
      </div>

      <Modal {...modal.props} />
    </React.Fragment>
  );
};
