import { Balance, BeneficiaryKind } from 'flexinet-api';

import { balanceApi } from '@/integrations/api';

import { useRemoteData } from '@binhatch/hooks';

interface UseBalancesOptions {
  ids?: string[];
  beneficiaryKind: BeneficiaryKind;
}

export const useBalances = ({ ids, beneficiaryKind }: UseBalancesOptions) => {
  return useRemoteData({ key: `useBalances`, ids, beneficiaryKind }, async ({ ids, beneficiaryKind }) => {
    const balances = ids?.length ? await balanceApi.getBalances(ids, beneficiaryKind).then((r) => r.data.balances) : [];

    return balances.reduce((map, balance) => {
      const balances = map.get(balance.beneficiary.value) ?? [];

      balances.push(balance);

      map.set(balance.beneficiary.value, balances);

      return map;
    }, new Map<string, Balance[]>());
  });
};
