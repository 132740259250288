import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import * as React from 'react';

import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { DateInput } from './DateInput';
import { DatePicker } from './DatePicker';
import { Input } from './Input';

interface Props extends React.ComponentProps<typeof DateInput> {
  value?: Date | string;
  view?: Date;
  min?: Date;
  max?: Date;
  clearable?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
  onFocus(): void;
  onBlur(): void;
  onClick(): void;
}

const useMergeEventHandlers = <T extends (event: E) => void, E = any>(handler: T | undefined, callback: T) => {
  return React.useCallback(
    (event: E) => {
      callback(event);
      if (handler) handler(event);
    },
    [callback, handler]
  );
};

export const DatePickerInput = React.forwardRef(function DatePickerInput(
  { value, readOnly, disabled, className, min, max, view, clearable, ...props }: Props,
  ref: React.Ref<any>
) {
  const intl = useIntl();
  const [isOpen, setIsOpen] = React.useState(false);

  const onFocus = useMergeEventHandlers(props.onFocus, () => setIsOpen(!readOnly && !disabled));
  const onClick = useMergeEventHandlers(props.onClick, () => setIsOpen(!readOnly && !disabled));
  const onBlur = useMergeEventHandlers(props.onBlur, () => setIsOpen(false));
  const onChange = useMergeEventHandlers(props.onChange, () => onBlur({}));

  const normalizedValue =
    typeof value === 'string'
      ? dayjs(min || max)
          .startOf('day')
          .toDate()
      : value;

  return (
    <div className="relative">
      <Input
        {...props}
        {...{ ref, onClick, onFocus, onBlur }}
        className={classNames('w-full flex-1', { 'pr-10': !!clearable }, className)}
        readOnly
        value={value ? intl.formatDate(value, { dateStyle: 'long' }) : ''}
      />

      {!!clearable && !!value && (
        <button className="absolute right-0 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center" type="button">
          <XMarkIcon className="h-5 w-5" onClick={() => onChange(undefined)} />
        </button>
      )}

      <div className="relative">
        <Transition
          as={React.Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={isOpen}
        >
          <div
            className="absolute left-0 top-1 z-10 w-72 rounded border border-solid border-[#003F2E30] bg-white shadow-lg"
            onMouseDown={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <DatePicker
              value={typeof value === 'string' ? undefined : value}
              {...{ min, max, view }}
              onChange={(date) => onChange(dayjs(date).set('hours', dayjs(normalizedValue).hour()).toDate())}
            />
          </div>
        </Transition>
      </div>
    </div>
  );
});
