import { translations } from '@binhatch/locale';
import {
  Button,
  CoinInput,
  CustomDealRule,
  DeletableTag,
  Form,
  InputWithLabel,
  InstanceProps,
  Loading,
  LoadingIndicator,
  LoadingMessage,
  Modal,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  NumberInput,
  Select,
  SubmitError,
  ValidatedField,
  useModal
} from '@binhatch/ui';
import { PlusIcon } from '@heroicons/react/20/solid';
import classnames from 'classnames';
import { CustomDealRestrictionPriority, MinTurnoverRestrictionValue, TagRule } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { useTags } from '@/hooks/useTags';
import { replaceItemAtIndex } from '@binhatch/utility';
import { CreateRestrictionConditionModal } from './CreateRestrictionConditionModal';

interface Props extends InstanceProps<void, { value: Partial<MinTurnoverRestrictionValue>; onUpdate(value: MinTurnoverRestrictionValue): Promise<void> }> {}

const schema = yup
  .object({
    rules: yup.array().of(yup.mixed<TagRule>().required()).min(1).required().label(translations.fields.promotionRuleCondition.label),
    minTurnoverPercent: yup.number().min(0).max(100).required().label(translations.fields.minTurnoverPercent.label),
    priority: yup.mixed<CustomDealRestrictionPriority>().required().label(translations.fields.priority.label),
    pointsPercent: yup.number().min(0).max(100).required().label(translations.fields.promotionTargetPoint.label)
  })
  .required();

export const CreateMinTurnoverRestrictionModal = React.forwardRef<HTMLDivElement, Props>(
  ({ data: { value: data, onUpdate }, initialFocus, className, onAction, onClose }, ref) => {
    const createConditionModal = useModal(CreateRestrictionConditionModal);

    const intl = useIntl();

    const tags = useTags();
    const initialValues = React.useMemo<yup.InferType<typeof schema>>(
      () => ({
        rules: data.rules ?? [],
        minTurnoverPercent: data.minTurnoverPercent !== undefined ? parseFloat(data.minTurnoverPercent) : (undefined as unknown as number),
        priority: data.priority ?? CustomDealRestrictionPriority.Medium,
        pointsPercent: data.pointsPercent !== undefined ? parseFloat(data.pointsPercent) : (undefined as unknown as number)
      }),
      [data]
    );

    const onSubmit = React.useCallback(
      async ({ rules, minTurnoverPercent, priority, pointsPercent }: yup.InferType<typeof schema>) => {
        await onUpdate({
          rules,
          minTurnoverPercent: minTurnoverPercent.toString(),
          priority,
          pointsPercent: pointsPercent.toString()
        });
        await onAction();
      },
      [onUpdate, onAction]
    );

    return (
      <React.Fragment>
        <div {...{ ref }} className={classnames(className, 'max-w-md')}>
          <ModalLayout>
            <ModalHeader {...{ onClose }}>
              <FormattedMessage id={translations.modals.updateRule.createTitle} values={{ isNegation: false }} />
            </ModalHeader>

            <div className="relative min-h-28">
              <Loading className="absolute inset-0 z-10 flex items-center justify-center" visible={tags.isLoading}>
                <LoadingMessage center>
                  <LoadingIndicator className="h-5 w-5" />
                  <div>
                    <FormattedMessage id={translations.utils.loading} />
                  </div>
                </LoadingMessage>
              </Loading>

              {!!tags.data && (
                <Form {...{ schema, initialValues, onSubmit }}>
                  {({ values, invalid, dirtySinceLastSubmit, submitting, submitError, handleSubmit, form }) => (
                    <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                      <ValidatedField
                        field={InputWithLabel}
                        id="rules"
                        input={({ value, onFocus, onBlur }: { value: TagRule[]; onFocus?: Function; onBlur?: Function }) => (
                          <div className="flex flex-col gap-2">
                            {value.length > 0 && (
                              <ul className="flex flex-wrap gap-2">
                                {value.map((rule, index) => (
                                  <li key={index}>
                                    <DeletableTag
                                      onClick={() => {
                                        onFocus?.();

                                        return createConditionModal
                                          .open({ ...rule })
                                          .then((rule) => form.change('rules', replaceItemAtIndex(values.rules, index, rule)))
                                          .catch(() => void 0)
                                          .finally(() => onBlur?.());
                                      }}
                                      onDelete={() => form.change('rules', replaceItemAtIndex(values.rules, index))}
                                    >
                                      <CustomDealRule {...{ rule }} tags={tags.data} />
                                    </DeletableTag>
                                  </li>
                                ))}
                              </ul>
                            )}

                            <div>
                              <Button
                                appearance="outline"
                                className="h-full min-h-10 px-4"
                                type="button"
                                onClick={() => {
                                  onFocus?.();

                                  return createConditionModal
                                    .open({})
                                    .then((rule) => form.change('rules', values.rules.concat(rule)))
                                    .catch(() => void 0)
                                    .finally(() => onBlur?.());
                                }}
                              >
                                <PlusIcon className="mr-2 h-5 w-5" />
                                <FormattedMessage id={translations.fields.promotionAddCondition.label} />
                              </Button>
                            </div>
                          </div>
                        )}
                        label={<FormattedMessage id={translations.fields.promotionRuleConditions.label} />}
                        name="rules"
                        readOnly={submitting}
                      />

                      <ValidatedField
                        as={NumberInput}
                        description={<FormattedMessage id={translations.fields.minTurnoverPercent.description} />}
                        field={InputWithLabel}
                        id="min-turnover"
                        label={<FormattedMessage id={translations.fields.minTurnoverPercent.label} />}
                        name="minTurnoverPercent"
                        placeholder={intl.formatMessage({ id: translations.fields.minTurnoverPercent.placeholder })}
                        readOnly={!!submitting}
                      />

                      <ValidatedField
                        field={InputWithLabel}
                        id="priority"
                        input={Select}
                        items={[CustomDealRestrictionPriority.Low, CustomDealRestrictionPriority.Medium, CustomDealRestrictionPriority.High].map((value) => ({
                          value,
                          name: intl.formatMessage({ id: translations.enum.customDealRestrictionPriority[value] })
                        }))}
                        label={<FormattedMessage id={translations.fields.priority.label} />}
                        name="priority"
                        placeholder={intl.formatMessage({ id: translations.buttons.select })}
                        readOnly={!!submitting}
                      />

                      <ValidatedField
                        description={<FormattedMessage id={translations.fields.bonusPointPercent.description} />}
                        field={InputWithLabel}
                        id="points-percent"
                        input={CoinInput}
                        inputClassName="w-full"
                        label={<FormattedMessage id={translations.fields.promotionTargetPoint.label} />}
                        name="pointsPercent"
                        placeholder={intl.formatMessage({
                          id: translations.fields.bonusPointPercent.placeholder
                        })}
                        readOnly={submitting}
                      />

                      <SubmitError error={submitError} />

                      <div className="flex flex-row-reverse space-x-2">
                        <ModalPrimaryButton disabled={invalid && !dirtySinceLastSubmit} ref={initialFocus} onAction={() => handleSubmit()}>
                          <FormattedMessage id={translations.buttons.select} />
                        </ModalPrimaryButton>

                        <ModalSecondaryButton {...{ onClose }}>
                          <FormattedMessage id={translations.buttons.back} />
                        </ModalSecondaryButton>
                      </div>
                    </form>
                  )}
                </Form>
              )}
            </div>
          </ModalLayout>
        </div>

        <Modal {...createConditionModal.props} />
      </React.Fragment>
    );
  }
);
