import { useRemoteData } from '@binhatch/hooks';
import { ProductKind, ProductStatus, ProductUsage } from 'flexinet-api';

import { productApi } from '@/integrations/api';

interface UsePendigProductsOptions {
  nextToken?: string;
}

export const usePendigProducts = ({ nextToken }: UsePendigProductsOptions = {}) => {
  return useRemoteData({ key: `usePendingProducts`, nextToken }, ({ nextToken }) => {
    return productApi
      .listProducts(
        ProductKind.Item,
        undefined,
        undefined,
        ProductUsage.Webshop,
        ProductStatus.Pending,
        undefined,
        undefined,
        nextToken
      )
      .then((r) => r.data);
  });
};
