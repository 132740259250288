import { translations } from '@binhatch/locale';
import { EmptyState, LoadingState } from '@binhatch/ui';
import { getAllFromApi } from '@binhatch/utility';
import { BeneficiaryKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { clientApi } from '@/integrations/api';

import { useRemoteData, useStrictParams } from '@binhatch/hooks';

import { useBalances } from '@/hooks/useBalances';

import { useGroupedBalances } from '@/hooks/useGroupedBalances';
import { ClientItem } from '@/pages/clients/components/ClientItem';

export const SalesUserClientPage: React.FC = () => {
  const { userId } = useStrictParams<{ userId: string }>();

  const clients = useRemoteData({ key: `useUserClients`, userId }, async ({ userId }) => {
    if (!userId) return [];

    return getAllFromApi(
      (nextToken) => clientApi.listClients(nextToken, undefined, undefined, undefined, userId).then((r) => r.data),
      (r) => r.clients
    );
  });

  const balances = useBalances({ ids: clients.data?.map((u) => u.id), beneficiaryKind: BeneficiaryKind.Client });

  const groupedBalances = useGroupedBalances(balances.data);

  return (
    <LoadingState loading={clients.isLoading || clients.isValidating}>
      {!clients.isLoading && !clients.data?.length && (
        <EmptyState>
          <FormattedMessage id={translations.pages.clientList.empty} />
        </EmptyState>
      )}

      <ul className="flex flex-col gap-4">
        {clients.data?.map((client) => (
          <li key={client.id}>
            <ClientItem {...{ client }} balance={groupedBalances?.get(client.id)?.client?.amount} />
          </li>
        ))}
      </ul>
    </LoadingState>
  );
};
