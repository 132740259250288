import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  onClick?(): void;
}

export const Tag: React.FC<React.PropsWithChildren<Props>> = ({ onClick, ...props }) => (
  <span
    {...props}
    className={classNames(
      'inline-flex items-center gap-x-2 rounded-md px-2 py-1 text-sm font-medium',
      { 'bg-shade-light': !/(^|\s)bg-/.test(props.className ?? ''), 'cursor-pointer': !!onClick },
      props.className
    )}
    {...{ onClick }}
  />
);
