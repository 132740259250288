import classNames from 'classnames';
import React from 'react';

import { As, AsComponent, AsProps } from '../As';

type Props<T extends AsComponent> = AsProps<T> & {
  invalid?: boolean;
};

export const Label = React.forwardRef(function Label<T extends AsComponent>({ className, ...props }: Props<T>, ref: React.Ref<any>) {
  return <As {...props} {...{ ref }} className={classNames('flex items-center justify-between text-sm font-semibold', className)} />;
});
