import {
  AzureCloudInstance,
  BrowserCacheLocation,
  Configuration,
  EventType,
  NavigationClient,
  NavigationOptions,
  PublicClientApplication,
  RedirectRequest
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router';

import { VERSION } from '@/config';

import { Auth } from '@/containers/useAuth';

class ReactNavigationClient extends NavigationClient {
  constructor(private navigate: NavigateFunction) {
    super();
  }

  async navigateInternal(url: string, { noHistory }: NavigationOptions): Promise<boolean> {
    const relativePath = url.replace(window.location.origin, '');

    this.navigate(relativePath, noHistory ? { replace: true, state: { from: 1 } } : undefined);

    return false;
  }
}

interface Props {
  clientId: string;
  tenant: string;
}

export const AzureMsalAuthProvider: React.FC<React.PropsWithChildren<Props>> = ({ clientId, tenant, children }) => {
  const navigate = useNavigate();

  const instance = React.useMemo(() => {
    const configuration: Configuration = {
      auth: {
        clientId,
        azureCloudOptions: {
          azureCloudInstance: AzureCloudInstance.AzurePublic,
          tenant
        },
        redirectUri: `${window.location.origin}/auth/callback`,
        postLogoutRedirectUri: `${window.location.origin}/auth/callback`,
        navigateToLoginRequestUrl: false
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false
      },
      telemetry: { application: { appName: 'flexinet', appVersion: VERSION } }
    };

    const instance = new PublicClientApplication(configuration);

    const activeAccount = instance.getActiveAccount();

    if (!activeAccount) {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) instance.setActiveAccount(accounts[0]);
    }

    instance.addEventCallback((event) => {
      if (event.eventType !== EventType.LOGIN_SUCCESS) return;

      const payload = event.payload as RedirectRequest;

      if (!payload.account) return;

      instance.setActiveAccount(payload.account);
    });

    return instance;
  }, [clientId, tenant]);

  const client = React.useMemo(() => new ReactNavigationClient(navigate), [navigate]);

  instance.setNavigationClient(client);

  return (
    <MsalProvider {...{ instance }}>
      <Auth.Provider>{children}</Auth.Provider>
    </MsalProvider>
  );
};
