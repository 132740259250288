declare const CONFIG: {
  AZURE_ENTRA_TENANT_ID: string;
  AZURE_ENTRA_CLIENT_ID: string;
  VERSION: string;
  ENVIRONMENT: string;
  API_URL: string;
  TENANT: string;
};

export const { AZURE_ENTRA_TENANT_ID, AZURE_ENTRA_CLIENT_ID, VERSION, ENVIRONMENT, API_URL, TENANT } = CONFIG;
