import { BeneficiaryKind } from 'flexinet-api';
import React from 'react';

import { Auth } from '@/containers/useAuth';

import { useBalances } from './useBalances';
import { useGroupedBalances } from './useGroupedBalances';

export const useBalance = () => {
  const { context } = Auth.useContainer();

  const userId = context?.user.id;

  const ids = React.useMemo(() => {
    if (!userId) return [];
    return [userId];
  }, [userId]);

  const balances = useBalances({ ids, beneficiaryKind: BeneficiaryKind.User });
  const groupedBalances = useGroupedBalances(balances.data);

  return React.useMemo(() => {
    return { ...balances, data: userId ? groupedBalances?.get(userId) : undefined };
  }, [userId, balances, groupedBalances]);
};
