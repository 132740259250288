import { tagApi } from '@/integrations/api';
import { useImmutableData } from '@binhatch/hooks';

export const useTagValues = (tagKey?: string) => {
  return useImmutableData({ key: 'useTagValues', tagKey, skip: !tagKey }, async ({ tagKey }) => {
    if (!tagKey) return [];

    const tags = await tagApi.getTagByID(tagKey).then((r) => r.data);

    return tags.values;
  });
};
