import { translations } from '@binhatch/locale';
import { CoinValue, Skeleton, UserAvatar } from '@binhatch/ui';
import { getUserName, isSystemUserDetails } from '@binhatch/utility';
import { Feature, TransactionCurrencyKind, User } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Configuration } from '@/containers/useConfiguration';
import { GroupedBalance } from '@/hooks/useGroupedBalances';
import { urls } from '@/utils/url';

interface SalesUserProps {
  user?: User;
}

export const SalesUser: React.FC<SalesUserProps> = ({ user }) => (
  <React.Fragment>
    <UserAvatar className="flex-shrink-0" />

    <div className="min-w-0 flex-1 flex-wrap">
      <Skeleton size="w-40 h-5 mb-1" visible={!user}>
        <div className="truncate font-semibold">{user ? getUserName(user) || user.details.email || '???' : '???'}</div>
      </Skeleton>

      <Skeleton size="w-48 h-5 mb-1" visible={!user}>
        {!!user && <div className="truncate">{user.details.email}</div>}
      </Skeleton>

      <Skeleton size="w-32 h-5 mb-1" visible={!user}>
        {!!user && (
          <div>
            <span>
              <FormattedMessage id={translations.enum.userRole[user.role]} />
            </span>

            {isSystemUserDetails(user.details) && (
              <span>
                {' - '} {[user.details.jobTitle, user.details.department].filter(Boolean).join(' / ')}
              </span>
            )}
          </div>
        )}
      </Skeleton>
    </div>
  </React.Fragment>
);

interface SalesUserItemProps extends SalesUserProps {
  balance?: GroupedBalance;
}

export const SalesUserItem: React.FC<SalesUserItemProps> = ({ user, balance }) => {
  const [{ value: config }] = Configuration.useContainer();

  return (
    <Link
      className="flex flex-col gap-4 rounded bg-white p-8 shadow md:flex-row md:justify-between"
      state={{ from: 1 }}
      to={urls.sales.getOne({ userId: user?.id ?? '' })}
    >
      <div className="flex gap-4">
        <SalesUser {...{ user }} />
      </div>

      <div className="flex flex-col gap-2 whitespace-nowrap">
        {[
          ...(config.features?.has(Feature.CustomDeals) ? [TransactionCurrencyKind.CustomDealBudget] : []),
          ...(config.features?.has(Feature.SalesBudget) ? [TransactionCurrencyKind.SalesBudget] : [])
        ].map((kind) => (
          <div className="flex items-center gap-4" key={kind}>
            <FormattedMessage id={translations.enum.transactionCurrencyKind[kind]} />

            <div className="flex flex-1 justify-end">
              <CoinValue value={balance?.[kind]?.amount ?? 0} />
            </div>
          </div>
        ))}
      </div>
    </Link>
  );
};
