import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  AsyncButton,
  ButtonRadio,
  CustomDealItem,
  CustomDealItemContainer,
  CustomDealRule,
  EmptyState,
  LoadingState,
  Pagination,
  PromotionClaimed,
  PromotionTargetProgress,
  SearchInput
} from '@binhatch/ui';
import { fromPromotion, getAllFromApi, getCurrentPeriod, getFallbackPeriod, getLastCustomDealClientProgress, getPromotionDays } from '@binhatch/utility';
import { PromotionSortByField, PromotionType, SortDirection } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useTags } from '@/hooks/useTags';
import { promotionApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { Configuration } from '@/containers/useConfiguration';

import { CustomDealBeneficiaries } from '../customDeals/CustomDealBeneficiaries';
import { useCustomDealBeneficiaries } from '../customDeals/useCustomDealBeneficiaries';
import { useCustomDealProgress } from '../customDeals/useCustomDealProgress';

const statuses = ['active', 'scheduled', 'expired'] as const;
type Status = (typeof statuses)[number];

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const status: QueryParamConfig<Status> = {
  decode: (value) => {
    const v = decodeSingleQueryParam(value, undefined);

    if (v && statuses.includes(v as Status)) return v as Status;

    return 'active';
  },
  encode: (value) => value
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, status, search };

export const SalesUserCustomDealListPage: React.FC = () => {
  const { userId } = useStrictParams<{ userId: string }>();
  const intl = useIntl();
  const [query, updateQuery] = useQueryParams({ config });
  const [claimed, setClaimed] = React.useState<string[]>([]);
  const [{ value: configuration }] = Configuration.useContainer();

  const dates = React.useMemo(
    () => ({
      startingAfter: query.status === 'scheduled' ? new Date().toISOString() : undefined,
      endingBefore: query.status === 'expired' ? new Date().toISOString() : undefined,
      startingBefore: query.status === 'active' ? new Date().toISOString() : undefined,
      endingAfter: query.status === 'active' ? new Date().toISOString() : undefined
    }),
    [query.status]
  );

  const promotions = useRemoteData(
    { key: `useSalesUserCustomDeals`, userId, dates, search: query.search, page: query.page },
    async ({ search, userId, dates, page: token }) => {
      return await promotionApi
        .listPromotions(
          token,
          undefined,
          undefined,
          userId,
          PromotionType.CustomDeal,
          dates.startingAfter,
          dates.endingBefore,
          dates.startingBefore,
          dates.endingAfter,
          undefined,
          search,
          PromotionSortByField.EndsAt,
          SortDirection.Desc
        )
        .then((r) => r.data)
        .then((r) => ({ ...r, promotions: r.promotions.map(fromPromotion) }));
    }
  );

  const tags = useTags();
  const beneficiaries = useCustomDealBeneficiaries(promotions.data?.promotions);

  const progress = useCustomDealProgress({
    promotions: promotions.data?.promotions,
    realtime: configuration?.realtime
  });

  const claimable = useRemoteData({ key: `useClaimablePromotions`, userId, dates, search: query.search }, async ({ search, userId, dates }) => {
    const ids = await getAllFromApi(
      async (nextPage) => {
        const { data } = await promotionApi.listPromotions(
          nextPage,
          undefined,
          undefined,
          undefined,
          PromotionType.CustomDeal,
          dates.startingAfter,
          dates.endingBefore,
          dates.startingBefore,
          dates.endingAfter,
          [userId],
          search,
          undefined,
          undefined,
          true
        );

        return data;
      },
      (r) => r.promotions.map((p) => p.id)
    );

    return new Set(ids);
  });

  const items = React.useMemo(() => {
    return (
      promotions.data?.promotions.map((promotion) => {
        const period = getCurrentPeriod(promotion.periods ?? []) ?? getFallbackPeriod(promotion.periods ?? []);

        return {
          promotion,
          days: period ? getPromotionDays(period) : 0,
          period,
          progress: promotion.ruleGroups.map((group) => ({
            group,
            progress: getLastCustomDealClientProgress(promotion, period, group.target, progress.data ?? [], (progress) => {
              if (!group.id || !progress.value_detailed?.[group.id]) return 0;
              return parseFloat(progress.value_detailed[group.id]);
            })
          })),
          claimable: !!claimable.data?.has(promotion.id)
        };
      }) ?? []
    );
  }, [promotions.data, claimable.data, progress.data]);

  return (
    <div className="space-y-6">
      <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
        <ButtonRadio
          className="h-10"
          id="period"
          items={statuses.map((value) => ({
            value,
            name: intl.formatMessage({ id: translations.enum.promotionStatus[value] })
          }))}
          name="period"
          type="radio"
          value={query.status}
          onChange={(status: Status) => updateQuery({ page: undefined, status })}
        />

        <div className="flex items-center gap-4">
          <SearchInput
            className="w-full md:w-72"
            placeholder={intl.formatMessage({ id: translations.pages.promotionList.search })}
            value={query.search}
            onChange={(search: string) => updateQuery({ page: undefined, search })}
          />
        </div>
      </div>

      <LoadingState loading={promotions.isLoading || promotions.isValidating}>
        {!promotions.isLoading && !promotions.data?.promotions.length && (
          <EmptyState>
            <FormattedMessage id={translations.pages.promotionList.empty} />
          </EmptyState>
        )}

        <ul className="grid min-h-[7rem] w-full gap-4 rounded-lg xl:grid-cols-2 2xl:grid-cols-3">
          {items.map(({ promotion, days, period, progress, claimable }) => (
            <li className="min-w-0" key={promotion.id}>
              <CustomDealItemContainer>
                <div className="flex justify-between gap-4 px-4 pt-4 text-sm">
                  <CustomDealBeneficiaries beneficiaries={beneficiaries.data?.get(promotion.id)} type={promotion.beneficiaryKind} />
                </div>

                <Link className="flex flex-col gap-4 p-4" state={{ from: 1 }} to={urls.customDeals.getOne({ promotionId: promotion.id })}>
                  <CustomDealItem {...{ promotion, period, days }} />

                  {progress.map(({ group, progress }, index) => (
                    <PromotionTargetProgress
                      key={index}
                      name={
                        <React.Fragment key={index}>
                          {group.rules.map((rule, index) => (
                            <CustomDealRule {...{ rule }} key={index} tags={tags.data} />
                          ))}
                        </React.Fragment>
                      }
                      {...{ promotion, progress }}
                    />
                  ))}
                </Link>

                {(claimable || progress.some((p) => p.progress.claimed) || claimed.includes(promotion.id)) && (
                  <div className="-mt-4 flex justify-end p-4">
                    {progress.some((p) => p.progress.claimed) || claimed.includes(promotion.id) ? (
                      <PromotionClaimed />
                    ) : (
                      <AsyncButton
                        appearance="primary"
                        className="h-10 px-4"
                        onClick={async () => {
                          await promotionApi.claimPromotion(promotion.id, { clientId: userId });
                          setClaimed((claimed) => [...claimed, promotion.id]);
                          return false;
                        }}
                      >
                        <FormattedMessage id={translations.pages.promotionList.provideBonus} />
                      </AsyncButton>
                    )}
                  </div>
                )}
              </CustomDealItemContainer>
            </li>
          ))}
        </ul>
      </LoadingState>

      <Pagination
        hasNext={!!promotions.data?.nextToken}
        hasPrevious={!!promotions.data?.prevToken}
        onNext={() => updateQuery({ page: promotions.data?.nextToken })}
        onPrevious={() => updateQuery({ page: promotions.data?.prevToken })}
      />
    </div>
  );
};
