import { translations } from '@binhatch/locale';
import {
  DeletableTag,
  Form,
  InputWithLabel,
  InstanceProps,
  Loading,
  LoadingIndicator,
  LoadingMessage,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  SearchableSelect,
  SelectItem,
  SubmitError,
  ValidatedField
} from '@binhatch/ui';
import { ensureArray } from '@binhatch/utility';
import classnames from 'classnames';
import { Rule } from 'flexinet-api';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { useTagValues } from '@/hooks/useTagValues';
import { useTags } from '@/hooks/useTags';

interface Props extends InstanceProps<{ rule: Rule }, { rule: Omit<Rule, 'tagKey'> & Pick<Partial<Rule>, 'tagKey'> }> {}

const schema = yup
  .object({
    tagKey: yup.string().required().label(translations.fields.promotionRuleCondition.label),
    value: yup
      .array()
      .of(yup.string().min(0).required().label(translations.fields.promotionRuleValue.label))
      .min(1)
      .required()
      .label(translations.fields.promotionRuleValue.label)
  })
  .required();

export const UpdateRuleModal = React.forwardRef<HTMLDivElement, Props>(({ data, initialFocus, className, onAction, onClose }, ref) => {
  const intl = useIntl();
  const [tagKey, setTagKey] = React.useState(data.rule.tagKey);

  const tags = useTags();

  const tagValues = useTagValues(tagKey);

  const initialValues = React.useMemo(() => ({ tagKey: data.rule.tagKey ?? '', value: ensureArray(data.rule.value?.value ?? []).map((v) => v) }), [data]);

  const onSubmit = React.useCallback(
    async ({ tagKey, value }: yup.InferType<typeof schema>) => onAction({ rule: { ...data.rule, tagKey, value: { kind: 'array', value } } }),
    [data, onAction]
  );

  return (
    <div {...{ ref }} className={classnames(className, 'max-w-xl')}>
      <ModalLayout>
        <ModalHeader {...{ onClose }}>
          <FormattedMessage
            id={data.rule.value ? translations.modals.updateRule.updateTitle : translations.modals.updateRule.createTitle}
            values={{ isNegation: data.rule.isNegation }}
          />
        </ModalHeader>

        <div className="relative min-h-36">
          <Loading className="absolute inset-0 z-10 flex items-center justify-center" visible={tags.isLoading}>
            <LoadingMessage center>
              <LoadingIndicator className="h-5 w-5" />
              <div>
                <FormattedMessage id={translations.utils.loading} />
              </div>
            </LoadingMessage>
          </Loading>

          {!!tags.data && (
            <Form {...{ schema, initialValues, onSubmit }}>
              {({ values, invalid, submitting, submitError, handleSubmit, form }) => (
                <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                  <FormSpy subscription={{ values: true }} onChange={({ values }) => setTagKey(values.tagKey)} />

                  <ValidatedField
                    field={InputWithLabel}
                    id="tag-key"
                    input={SearchableSelect}
                    items={
                      tags.data?.map(({ key, description }) => ({
                        value: key,
                        name: key,
                        description
                      })) ?? []
                    }
                    label={<FormattedMessage id={translations.fields.promotionRuleCondition.label} />}
                    name="tagKey"
                    placeholder={intl.formatMessage({ id: translations.fields.promotionRuleCondition.placeholder })}
                    readOnly={!!submitting}
                    selectItem={({ item, getDisplayName, ...props }: any) => (
                      <SelectItem {...props}>
                        <div className="font-medium capitalize">{item.description}</div>
                        <div>{item.name}</div>
                      </SelectItem>
                    )}
                    onChange={() => form.change('value', [])}
                  />

                  {!!values.tagKey && !!tagValues.data && (
                    <React.Fragment>
                      <div className="flex flex-col gap-2">
                        {tagValues.data.length > 0 ? (
                          <React.Fragment>
                            <ValidatedField
                              as={SearchableSelect}
                              description={values.value.filter(Boolean).length}
                              field={InputWithLabel}
                              id="rule-value"
                              items={
                                tagValues.data?.map(({ value, description }) => ({
                                  value,
                                  name: value,
                                  description
                                })) ?? []
                              }
                              label={<div className="capitalize">{values.tagKey}</div>}
                              multiple
                              name="value"
                              placeholder={intl.formatMessage({ id: translations.buttons.select })}
                              readOnly={!!submitting}
                              selectItem={({ item, getDisplayName, ...props }: any) => (
                                <SelectItem {...props}>
                                  <div className="font-medium capitalize">{item.description}</div>
                                  <div>{item.name}</div>
                                </SelectItem>
                              )}
                            />

                            <ul className="flex flex-wrap gap-2">
                              {values.value.map((value) => (
                                <li key={value}>
                                  <DeletableTag
                                    onDelete={() =>
                                      form.change(
                                        'value',
                                        values.value.filter((v) => v !== value)
                                      )
                                    }
                                  >
                                    {value}
                                  </DeletableTag>
                                </li>
                              ))}
                            </ul>
                          </React.Fragment>
                        ) : (
                          <ValidatedField
                            as="textarea"
                            description={values.value.filter(Boolean).length}
                            field={InputWithLabel}
                            format={(v: string | string[]) => (Array.isArray(v) ? v.join('\r\n') : v)}
                            id="rule-value"
                            inputClassName="min-h-[8rem] h-full resize-none"
                            label={<div className="capitalize">{values.tagKey}</div>}
                            name="value"
                            parse={(v: string | string[]) => (Array.isArray(v) ? v : v.split(/\r?\n|,|;/).map((v) => v?.trim() ?? ''))}
                            placeholder={intl.formatMessage({ id: translations.buttons.select })}
                            readOnly={!!submitting}
                            validated={false}
                          />
                        )}
                      </div>
                    </React.Fragment>
                  )}

                  <SubmitError error={submitError} />

                  <div className="flex flex-row-reverse space-x-2">
                    <ModalPrimaryButton disabled={invalid} ref={initialFocus} onAction={() => handleSubmit()}>
                      <FormattedMessage id={translations.buttons.select} />
                    </ModalPrimaryButton>

                    <ModalSecondaryButton {...{ onClose }}>
                      <FormattedMessage id={translations.buttons.back} />
                    </ModalSecondaryButton>
                  </div>
                </form>
              )}
            </Form>
          )}
        </div>
      </ModalLayout>
    </div>
  );
});
