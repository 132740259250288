import React from 'react';

import classNames from 'classnames';

export const Group: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className, children }) => (
  <div {...{ className }}>{children}</div>
);

export const GroupHeader: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ className, ...props }) => (
  <div
    {...props}
    className={classNames('bg-shade-light mb-4 flex items-center gap-2 rounded-lg px-4 py-3 font-semibold', className)}
  />
);

export const GroupIcon: React.FC<{ icon: React.ComponentType<React.SVGProps<SVGSVGElement>> }> = ({ icon: Icon }) => (
  <Icon className="h-6 w-6" />
);
