import { translations } from '@binhatch/locale';
import { ClientAvatar, CoinValue } from '@binhatch/ui';
import { Client } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { urls } from '@/utils/url';

interface Props {
  client: Client;
  balance?: number;
}

export const ClientItem: React.FC<Props> = ({ client, balance }) => (
  <Link className="flex flex-col gap-4 rounded bg-white p-8 shadow md:flex-row" state={{ from: 1 }} to={urls.clients.getOne({ clientId: client.id })}>
    <div className="flex flex-1 gap-4">
      <ClientAvatar className="flex-shrink-0" />

      <div className="min-w-0 flex-1">
        <div className="truncate font-semibold">{client.name}</div>
        <div className="truncate">
          {client.referenceId}
          {!!client.isExcluded && (
            <React.Fragment>
              {' - '}
              <span className="text-error">
                <FormattedMessage id={translations.pages.clientDetail.clientExcluded} />
              </span>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>

    <div className="flex flex-col items-end">
      <FormattedMessage id={translations.utils.balance} />
      <CoinValue value={balance} />
    </div>
  </Link>
);
