import { generatePath } from 'react-router';

interface RouteConfig {
  [x: string]: string | RouteConfig | Function;
}

const createRoutes = <C extends RouteConfig>(base: string, config: C): C => {
  return Object.entries(config).reduce<C>((config, [key, value]) => {
    switch (typeof value) {
      case 'string': {
        // @ts-ignore
        config[key] = `${base}${value}`;
        break;
      }
      case 'function': {
        // @ts-ignore
        config[key] = value;
        break;
      }
      case 'object': {
        // @ts-ignore
        config[key] = createRoutes(base, value);
        break;
      }
    }

    return config;
  }, config);
};

const promotions = createRoutes('/promotions', {
  root: '',
  new: '/new',
  one: '/:promotionId',
  config: '/:promotionId/config',
  history: '/:promotionId/history',
  update: '/:promotionId/edit',
  clone: '/:promotionId/clone',
  getOne: (o: { promotionId: string }, path?: string) => generatePath(path ?? promotions.one, o)
});

const customDeals = createRoutes('/custom-deals', {
  root: '',
  new: '/new',
  one: '/:promotionId',
  config: '/:promotionId/config',
  history: '/:promotionId/history',
  update: '/:promotionId/edit',
  clone: '/:promotionId/clone',
  getOne: (o: { promotionId: string }, path?: string) => generatePath(path ?? customDeals.one, o)
});

const products = createRoutes('/products', {
  one: '/:productId',
  review: '/review',
  getOne: (o: { productId: string }, path?: string) => generatePath(path ?? products.one, o)
});

const orders = createRoutes('/orders', {
  root: '',
  getForUser: (o: { userId: string }) => `${generatePath(orders.root, o)}?userId=${o.userId}`,
  one: '/:orderId',
  getOne: (o: { orderId: string }) => generatePath(orders.one, o)
});

const shop = createRoutes('/shop', {
  root: '',
  cart: '/cart',
  products
});

const sales = createRoutes('/sales', {
  root: '',
  new: '/new',
  one: '/:userId',
  subordinates: '/:userId/subordinates',
  customDeals: '/:userId/custom-deals',
  transactions: '/:userId/transactions',
  clients: '/:userId/clients',
  getOne: (o: { userId: string }, path?: string) => generatePath(path ?? sales.subordinates, o)
});

const clients = createRoutes('/clients', {
  root: '',
  new: '/new',
  one: '/:clientId',
  promotions: '/:clientId/promotions',
  customDeals: '/:clientId/custom-deals',
  orders: '/:clientId/orders',
  transactions: '/:clientId/transactions',
  users: '/:clientId/users',
  getOne: (o: { clientId: string }, path?: string) => generatePath(path ?? clients.promotions, o)
});

const reports = `/reports`;
const notifications = '/notifications';

const settings = createRoutes('/settings', {
  root: '',
  integrations: '/integrations',
  notifications: '/notifications',
  clients: '/clients',
  promotions: '/promotions'
});

export const urls = {
  promotions,
  orders,
  customDeals,
  shop,
  sales,
  clients,
  reports,
  settings,
  notifications
};
