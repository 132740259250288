import { QueryParamConfig, decodeArrayQueryParam, decodeNumber, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { AsyncButton, Button, CoinValue, LoadingState, PageLoaderHeading, Pagination, ProductImage, SearchInput, SearchableSelect } from '@binhatch/ui';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { ProductAvailability, ProductKind, ProductStatus, ProductUsage } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { categoryApi, productApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';
import { Cart } from '@/containers/useCart';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const category: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const price: QueryParamConfig<[number, number] | undefined> = {
  decode: (value) => {
    const array = decodeArrayQueryParam(value, undefined) ?? [];
    return array.length === 2 ? [decodeNumber(array[0], 0), decodeNumber(array[1], 0)] : undefined;
  },
  encode: (value) => (value ? value : undefined)
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, category, price, search };

export const ShopPage: React.FC = () => {
  const intl = useIntl();
  const { context } = Auth.useContainer();
  const [query, updateQuery] = useQueryParams({ config });

  const cart = Cart.useContainer();

  const categories = useRemoteData({ key: `useCategories` }, async () => categoryApi.listCategories().then((r) => r.data.categories));

  const products = useRemoteData(
    {
      key: `useShopProducts`,
      category: query.category,
      price: query.price,
      search: query.search,
      page: query.page,
      skip: categories.isLoading || categories.isValidating
    },
    async ({ category, search, page: nextToken }) =>
      productApi
        .listProducts(ProductKind.Item, category, search, ProductUsage.SalesBudget, ProductStatus.Active, ProductAvailability.Available, undefined, nextToken)
        .then((r) => r.data)
  );

  return (
    <React.Fragment>
      <main className="space-y-6">
        <div className="flex flex-col flex-wrap justify-between gap-4 sm:flex-row sm:items-center">
          <PageLoaderHeading className="flex-1" loading={products.isLoading || products.isValidating}>
            <FormattedMessage id={translations.pages.shop.title.customer} />
          </PageLoaderHeading>

          <div className="flex items-center gap-4">
            <SearchInput
              className="w-full max-w-72"
              placeholder={intl.formatMessage({ id: translations.pages.shop.search })}
              value={query.search}
              onChange={(search: string) => updateQuery({ page: undefined, search })}
            />

            <SearchableSelect
              containerClassName="max-w-72 w-full"
              items={[
                { value: undefined, name: intl.formatMessage({ id: translations.pages.productList.allCategories }) },
                ...(categories.data?.map((category) => ({ value: category.id, name: category.name })) ?? [])
              ]}
              placeholder="Cauta categorie"
              value={query.category}
              onChange={(category?: string) => updateQuery({ page: undefined, category })}
            />
          </div>

          <div className="flex flex-col items-stretch gap-4 sm:flex-row sm:items-center">
            {!!context && (
              <Button
                appearance="secondary"
                as={Link}
                className="flex h-10 items-center whitespace-nowrap px-4"
                to={urls.orders.getForUser({ userId: context.user.id })}
              >
                <FormattedMessage id={translations.pages.orderList.title} />
              </Button>
            )}

            <Button appearance="secondary" className="flex h-10 items-center whitespace-nowrap px-4" onClick={() => cart.toggle(true)}>
              <ShoppingCartIcon className="mr-2 h-5 w-5" />
              <FormattedMessage id={translations.pages.shop.viewCart} values={{ count: cart.count }} />
            </Button>
          </div>
        </div>

        <LoadingState loading={products.isLoading || products.isValidating}>
          <div className="flex flex-col items-start gap-4 lg:flex-row">
            <ul className="grid min-h-[7rem] w-full flex-1 gap-4 sm:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5">
              {products.data?.data.map((product) => (
                <li className="relative flex flex-col rounded-lg bg-white shadow" key={product.id}>
                  <Link
                    className="relative block w-full space-y-2 overflow-hidden rounded-t-lg p-4"
                    state={{ from: 1 }}
                    to={urls.shop.products.getOne({ productId: product.id })}
                  >
                    <ProductImage className="w-full" square src={product.media[0]} />
                  </Link>

                  <div className="-mt-4 flex flex-1 flex-col gap-1 rounded-b-lg p-4">
                    <div className="flex max-w-full flex-1 items-end font-semibold">{product.name}</div>

                    <div className="flex items-center justify-between">
                      <CoinValue value={product.value} />

                      <div className="">
                        <AsyncButton
                          appearance="primary"
                          className="h-10 w-full px-4"
                          disabled={product.quantity <= (cart.items.find((i) => i.productId === product.id)?.quantity ?? 0)}
                          onClick={() => {
                            cart.toggle(true);
                            cart.adjust(product, 1);
                          }}
                        >
                          <FormattedMessage id={translations.buttons.addToCart} />
                        </AsyncButton>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </LoadingState>

        <Pagination
          hasNext={!!products.data?.nextToken}
          hasPrevious={!!products.data?.prevToken}
          onNext={() => updateQuery({ page: products.data?.nextToken })}
          onPrevious={() => updateQuery({ page: products.data?.prevToken })}
        />
      </main>
    </React.Fragment>
  );
};
