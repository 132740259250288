import { useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  AsyncSelect,
  Button,
  ButtonRadioInput,
  Card,
  CoinValue,
  ConditionSeparator,
  CustomDealRule,
  DatePickerInput,
  DynamicBackButton,
  Form,
  FormattedCurrency,
  Group,
  GroupHeader,
  GroupIcon,
  InputWithLabel,
  Item,
  LoadingButton,
  Modal,
  PageHeading,
  SelectItem,
  SubmitError,
  ValidatedField,
  ValidationMessage,
  useModal
} from '@binhatch/ui';
import {
  ApiErrorCode,
  fromPromotion,
  getAllFromApi,
  getCurrency,
  getUserName,
  handleApiError,
  replaceItemAtIndex,
  toDate,
  toPromotion
} from '@binhatch/utility';
import { PlusIcon } from '@heroicons/react/20/solid';
import { CalendarIcon, PencilSquareIcon, PuzzlePieceIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { BeneficiaryKind, BonusMu, Client, PromotionType, Rule, RuleGroupState, Target, TargetMu, User, UserSource } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';

import { clientApi, customDealsApi, promotionApi, userApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { useRestrictions } from '@/hooks/useRestrictions';
import { useTags } from '@/hooks/useTags';

import { UpdateRuleModal } from './modals/UpdateRuleModal';

const schema = yup
  .object({
    name: yup.string().required().label(translations.fields.promotionName.label),
    description: yup.string().required().label(translations.fields.promotionDescription.label),
    reference: yup.string().required().label(translations.fields.promotionContractNumber.label),
    beneficiaryKind: yup.mixed<BeneficiaryKind>().oneOf(Object.values(BeneficiaryKind)).required().label(translations.fields.promotionAudienceType.label),
    beneficiaryClient: yup.string().required().label(translations.fields.promotionAddAudience.label),
    beneficiaryUser: yup
      .string()
      .when('beneficiaryKind', { is: BeneficiaryKind.User, then: (s) => s.required() })
      .label(translations.fields.promotionAddAudience.label),
    startAt: yup.date().required().label(translations.fields.promotionPeriodStart.label),
    endAt: yup
      .date()
      .required()
      .test({
        name: 'min',
        test: (value, context) => {
          if (!(value instanceof Date)) return true;

          const startAt = context.resolve(yup.ref('startAt'));

          if (!(startAt instanceof Date)) return true;

          const min = dayjs(startAt.getTime()).add(6, 'day');

          if (value.getTime() >= min.valueOf()) return true;

          return context.createError({
            message: translations.validation.date.min,
            params: { min: min.toDate() }
          });
        }
      })
      .label(translations.fields.promotionPeriodEnd.label),
    ruleGroups: yup
      .array()
      .of(
        yup
          .object({
            rules: yup.array().of(yup.mixed<Rule>().required()).min(1).required().label(translations.fields.promotionRules.label),
            target: yup
              .object({
                targetMu: yup.mixed<TargetMu>().oneOf(Object.values(TargetMu)).required().label(translations.fields.unit.label),
                levels: yup
                  .array()
                  .of(
                    yup
                      .object({
                        value: yup.number().required().label(translations.fields.promotionTargetQuantity.label),
                        bonus: yup
                          .object({
                            value: yup
                              .number()
                              .when('mu', {
                                is: (value: BonusMu) => value === BonusMu.Points,
                                then: (s) => s.required()
                              })
                              .label(translations.fields.promotionTargetPoint.label),
                            ref: yup
                              .string()
                              .when('mu', {
                                is: (value: BonusMu) => value === BonusMu.Product,
                                then: (s) => s.required()
                              })
                              .label(translations.fields.promotionTargetProduct.label),
                            mu: yup.mixed<BonusMu>().oneOf(Object.values(BonusMu)).required().label(translations.fields.promotionTargetBonus.label)
                          })
                          .required()
                      })
                      .required()
                  )
                  .required()
              })
              .required()
          })
          .required()
      )
      .required()
      .min(1)
      .label(translations.fields.promotionRules.label)
  })
  .required();

const mapClient = (client: Client): BeneficiarySelectItem => ({
  value: client.id,
  name: client.name,
  description: client.referenceId
});

const mapUser = (user: User): BeneficiarySelectItem => ({
  value: user.id,
  name: getUserName(user) || user.details.email || '???',
  description: getUserName(user) ? '' : user.details.email
});

interface Props {
  clone?: boolean;
}

interface BeneficiarySelectItem extends Item<string> {
  description?: string;
}

export const CustomDealUpdatePage: React.FC<Props> = ({ clone }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { promotionId } = useParams();

  const promotion = useRemoteData({ key: 'useCustomDeal', promotionId, skip: !promotionId }, ({ promotionId }) => {
    if (!promotionId) throw new Error('Promotion id is missing.');

    return promotionApi
      .getPromotion(promotionId)
      .then((r) => r.data)
      .then(fromPromotion);
  });

  const restrictions = useRestrictions();
  const tags = useTags();

  const onSubmit = React.useCallback(
    async ({ beneficiaryClient, beneficiaryUser, beneficiaryKind, startAt, endAt, ruleGroups, ...values }: yup.InferType<typeof schema>) => {
      const targetValue = ruleGroups.reduce((t, g) => g.target.levels.reduce((t, l) => t + l.value, t), 0);
      const targetBonus = ruleGroups.reduce((t, g) => g.target.levels.reduce((t, l) => t + (l.bonus.value ?? 0), t), 0);

      const target: Target = {
        targetMu: TargetMu.Currency,
        levels: [{ value: targetValue.toString(), bonus: { mu: BonusMu.Points, value: targetBonus, ref: undefined } }]
      };

      const value = {
        ...values,
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
        beneficiaryKind,
        beneficiaryIds: [beneficiaryKind === BeneficiaryKind.User && beneficiaryUser ? beneficiaryUser : beneficiaryClient],
        ruleGroups: ruleGroups.map((group) => ({
          ...group,
          target: {
            ...group.target,
            levels: group.target.levels.map((level) => ({
              ...level,
              value: level.value.toString(),
              bonus: {
                ...level.bonus,
                value: level.bonus.mu === BonusMu.Points ? level.bonus.value ?? 0 : 0,
                ref: level.bonus.mu === BonusMu.Product ? level.bonus.ref : undefined
              }
            }))
          }
        })),
        target
      };

      const id =
        promotionId && !clone
          ? await promotionApi.updatePromotion(promotionId, toPromotion(value)).then((r) => r.data.id)
          : await promotionApi
              .createPromotion(
                toPromotion({
                  brandLogoURL: '',
                  imageURL: '',
                  tags: {},
                  repeatable: false,
                  ruleGroupsState: RuleGroupState.Visible,
                  type: PromotionType.CustomDeal,
                  ...value
                })
              )
              .then((r) => r.data.id);

      navigate(urls.customDeals.getOne({ promotionId: id }), { state: { from: 1 }, replace: true });
    },
    [promotionId, clone, navigate]
  );

  const { data: beneficiary } = useRemoteData({ key: 'useCustomDealBeneficiary', promotion: promotion.data }, async ({ promotion }) => {
    if (!promotion) return;

    const beneficiaryId = promotion.beneficiaryIds[0];

    if (promotion.beneficiaryKind === BeneficiaryKind.Client) return { clientId: beneficiaryId, userId: undefined };

    const clientId = await userApi
      .getUserByID(beneficiaryId)
      .then((r) => r.data)
      .then((r) => r.clientId);

    return { clientId, userId: beneficiaryId };
  });

  const initialValues = React.useMemo((): yup.InferType<typeof schema> => {
    if (promotion.data) {
      const { name, description, reference, beneficiaryKind, startAt, endAt, ruleGroups, target } = promotion.data;

      return {
        name,
        description,
        reference: reference as string,
        beneficiaryKind,
        beneficiaryClient: beneficiary?.clientId as string,
        beneficiaryUser: beneficiary?.userId,
        startAt: new Date(startAt),
        endAt: new Date(endAt),
        ruleGroups: ruleGroups.map((group) => ({
          ...group,
          target: {
            ...group.target,
            targetMu: group.target?.targetMu ?? target.targetMu,
            levels:
              group.target?.levels.map((l) => ({
                ...l,
                value: parseFloat(l.value),
                bonus: { ...l.bonus, ref: l.bonus.ref, mu: l.bonus.mu }
              })) ?? []
          }
        }))
      };
    }

    return {
      name: undefined as unknown as string,
      description: undefined as unknown as string,
      reference: undefined as unknown as string,
      beneficiaryClient: undefined as unknown as string,
      beneficiaryUser: undefined as unknown as string,
      beneficiaryKind: BeneficiaryKind.Client,
      startAt: dayjs().add(1, 'day').startOf('day').toDate(),
      endAt: undefined as unknown as Date,
      ruleGroups: []
    };
  }, [promotion.data, beneficiary]);

  const updateRuleModal = useModal(UpdateRuleModal);

  return (
    <main className="space-y-6">
      <div className="flex flex-col items-start">
        <DynamicBackButton />
        <PageHeading title={<FormattedMessage id={translations.pages.customDealCreate.title} />} />
      </div>

      <Form {...{ initialValues, schema, onSubmit }}>
        {({ invalid, values, submitting, submitFailed, submitError, handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <Card className="flex flex-col gap-8 rounded-b-none">
              <div className="grid gap-4 xl:grid-cols-2">
                <div className="col-span-1">
                  <div className="flex flex-col gap-4">
                    <ValidatedField
                      field={InputWithLabel}
                      id="name"
                      label={<FormattedMessage id={translations.fields.promotionName.label} />}
                      name="name"
                      placeholder={intl.formatMessage({ id: translations.fields.promotionName.placeholder }, { type: PromotionType.CustomDeal })}
                      readOnly={submitting}
                      type="text"
                    />

                    <ValidatedField
                      as="textarea"
                      className="flex flex-1 flex-col"
                      field={InputWithLabel}
                      fieldClassName="h-full"
                      id="description"
                      inputClassName="min-h-[10rem] h-full resize-y"
                      label={<FormattedMessage id={translations.fields.promotionDescription.label} />}
                      name="description"
                      placeholder={intl.formatMessage({ id: translations.fields.promotionDescription.placeholder }, { type: PromotionType.CustomDeal })}
                      readOnly={submitting}
                      type="text"
                    />

                    <ValidatedField
                      field={InputWithLabel}
                      id="reference"
                      label={<FormattedMessage id={translations.fields.promotionContractNumber.label} />}
                      name="reference"
                      placeholder={intl.formatMessage({ id: translations.fields.promotionContractNumber.placeholder })}
                      readOnly={submitting}
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div className="grid gap-4 xl:grid-cols-2">
                <Group>
                  <GroupHeader>
                    <GroupIcon icon={UsersIcon} />
                    <FormattedMessage id={translations.pages.promotionDetail.groups.segment.title} />
                  </GroupHeader>

                  <div className="flex flex-col items-start gap-2">
                    <ValidatedField
                      field={InputWithLabel}
                      id="beneficiary-kind"
                      input={ButtonRadioInput}
                      inputClassName="h-10"
                      items={[BeneficiaryKind.Client, BeneficiaryKind.User].map((value) => ({
                        value,
                        name: intl.formatMessage({ id: translations.enum.promotionBeneficiaryKind[value] })
                      }))}
                      label="Acordarea bonusului"
                      name="beneficiaryKind"
                      readOnly={submitting}
                      type="radio"
                    />

                    <ValidatedField
                      className="w-full"
                      field={AsyncSelect}
                      getItemsByIds={(ids: string[]) => {
                        if (!ids.length) return [];

                        return getAllFromApi(
                          (nextToken) => clientApi.listClients(nextToken, undefined, undefined, ids).then((r) => r.data),
                          (r) => r.clients.map(mapClient)
                        );
                      }}
                      getItemsBySearch={(search: string) => {
                        if (search.length < 3) return [];

                        return getAllFromApi(
                          (nextToken) => clientApi.listClients(nextToken, search).then((r) => r.data),
                          (r) => r.clients.map(mapClient),
                          100
                        );
                      }}
                      id="beneficiary-client"
                      name="beneficiaryClient"
                      placeholder={intl.formatMessage({ id: translations.buttons.select })}
                      readOnly={!!submitting}
                      selectItem={({ item, getDisplayName, ...props }: any) => (
                        <SelectItem {...props}>
                          <div className="font-medium">{item.name}</div>
                          <div>{item.description}</div>
                        </SelectItem>
                      )}
                    />

                    {values.beneficiaryKind === BeneficiaryKind.User && !!values.beneficiaryClient && (
                      <ValidatedField
                        className="w-full"
                        field={AsyncSelect}
                        getItemsByIds={(ids: string[]) => {
                          return getAllFromApi(
                            (nextToken) =>
                              userApi.listUsers(nextToken, undefined, undefined, [values.beneficiaryClient], ids, UserSource.Customer).then((r) => r.data),
                            (r) => r.users.map(mapUser)
                          );
                        }}
                        getItemsBySearch={(search: string) => {
                          return getAllFromApi(
                            (nextToken) =>
                              userApi
                                .listUsers(nextToken, undefined, search.length ? search : undefined, [values.beneficiaryClient], undefined, UserSource.Customer)
                                .then((r) => r.data),
                            (r) => r.users.map(mapUser),
                            100
                          );
                        }}
                        id="beneficiary-user"
                        key={values.beneficiaryClient}
                        name="beneficiaryUser"
                        placeholder={intl.formatMessage({ id: translations.buttons.select })}
                        readOnly={!!submitting}
                        selectItem={({ item, getDisplayName, ...props }: any) => (
                          <SelectItem {...props}>
                            <div className="font-medium">{item.name}</div>
                            <div>{item.description}</div>
                          </SelectItem>
                        )}
                      />
                    )}
                  </div>
                </Group>

                <Group>
                  <GroupHeader>
                    <GroupIcon icon={CalendarIcon} />
                    <FormattedMessage id={translations.pages.promotionDetail.groups.period.title} />
                  </GroupHeader>

                  <div className="grid max-w-52 gap-2">
                    <ValidatedField
                      as={DatePickerInput}
                      field={InputWithLabel}
                      id="start-at"
                      label={<FormattedMessage id={translations.fields.promotionPeriodStart.label} />}
                      max={new Date(Math.min(new Date(values.endAt).getTime(), dayjs(new Date(2024)).endOf('year').valueOf()))}
                      min={dayjs().add(1, 'day').startOf('day').toDate()}
                      name="startAt"
                      placeholder={intl.formatMessage({ id: translations.fields.promotionPeriodStart.placeholder })}
                      readOnly={submitting}
                      type="text"
                    />

                    <ValidatedField
                      as={DatePickerInput}
                      field={InputWithLabel}
                      id="end-at"
                      label={<FormattedMessage id={translations.fields.promotionPeriodEnd.label} />}
                      min={values.startAt ? dayjs(values.startAt).add(6, 'day').startOf('day').toDate() : undefined}
                      name="endAt"
                      parse={(value?: Date) => (value instanceof Date ? dayjs(value).endOf('day').toDate() : value)}
                      placeholder={intl.formatMessage({ id: translations.fields.promotionPeriodEnd.placeholder })}
                      readOnly={submitting}
                      type="text"
                      view={values.endAt ?? (values.startAt ? dayjs(values.startAt).startOf('day').add(6, 'day').toDate() : undefined)}
                    />
                  </div>
                </Group>
              </div>

              <Group>
                <GroupHeader>
                  <GroupIcon icon={PuzzlePieceIcon} />
                  <FormattedMessage id={translations.pages.promotionDetail.groups.conditions.title} values={{ type: PromotionType.CustomDeal }} />
                </GroupHeader>

                <div className="grid gap-4">
                  <ul className="flex flex-wrap gap-4">
                    {values.ruleGroups.map((group, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && (
                          <li>
                            <ConditionSeparator negation={false} />
                          </li>
                        )}

                        <li className="border-shade group rounded border bg-white p-4">
                          <div className="flex justify-between gap-8">
                            <div>
                              <div className="font-semibold">
                                {group.rules.map((rule, index) => (
                                  <CustomDealRule {...{ rule }} key={index} tags={tags.data} />
                                ))}
                              </div>

                              <div>
                                <FormattedMessage id={translations.fields.minTurnoverPercent.label} />
                                {': '}

                                <FormattedNumber
                                  style="percent"
                                  value={
                                    parseFloat(
                                      restrictions.data?.minTurnover.find(
                                        (r) => r.rules.map((r) => r.tagKey).join('#') === group.rules.map((r) => r.tagKey).join('#')
                                      )?.minTurnoverPercent ?? '0'
                                    ) / 100
                                  }
                                />
                              </div>
                            </div>

                            <div className="-mr-2 -mt-2 flex gap-1">
                              <button
                                className="hover:bg-shade inline-flex h-8 w-8 items-center justify-center rounded opacity-0 group-hover:opacity-100"
                                type="button"
                                onClick={() =>
                                  updateRuleModal
                                    .open({
                                      rules: group.rules,
                                      target: group.target.levels[0].value,
                                      getMinTargetValue: (rules) =>
                                        customDealsApi
                                          .getCustomDealMinTargetValue({
                                            startingAt: toDate((values.startAt || new Date()).toISOString()),
                                            endingAt: toDate((values.endAt || new Date()).toISOString()),
                                            beneficiaryKind: values.beneficiaryKind,
                                            beneficiaryValues: [
                                              values.beneficiaryKind === BeneficiaryKind.User ? values.beneficiaryUser : values.beneficiaryClient
                                            ].filter((v): v is string => !!v),
                                            rules
                                          })
                                          .then((r) => parseFloat(r.data.minTargetValue))
                                          .catch((error) =>
                                            handleApiError(error, {
                                              code: { [ApiErrorCode.RestrictionNotFound]: () => 0 }
                                            })
                                          )
                                    })
                                    .then(({ rules, target, bonus }) => {
                                      const ruleGroups = replaceItemAtIndex(values.ruleGroups, values.ruleGroups.indexOf(group), {
                                        ...group,
                                        rules,
                                        target: {
                                          ...group.target,
                                          levels: [
                                            {
                                              value: target,
                                              bonus: { mu: BonusMu.Points, value: bonus, ref: undefined }
                                            }
                                          ]
                                        }
                                      });

                                      form.change('ruleGroups', ruleGroups);
                                    })
                                    .catch(() => void 0)
                                }
                              >
                                <PencilSquareIcon className="h-5 w-5" />
                              </button>

                              <button
                                className="hover:bg-shade inline-flex h-8 w-8 items-center justify-center rounded opacity-0 group-hover:opacity-100"
                                type="button"
                                onClick={() => {
                                  const ruleGroups = values.ruleGroups.slice().filter((g) => g !== group);
                                  form.change('ruleGroups', ruleGroups);
                                }}
                              >
                                <XMarkIcon className="h-5 w-5" />
                              </button>
                            </div>
                          </div>

                          <div className="flex items-center justify-between">
                            <FormattedCurrency currency={getCurrency()} value={group.target.levels[0].value} />

                            <CoinValue value={group.target.levels[0].bonus.value} />
                          </div>
                        </li>
                      </React.Fragment>
                    ))}

                    <li>
                      <Button
                        appearance="outline"
                        className="h-full min-h-10 px-4"
                        disabled={
                          (values.beneficiaryKind === BeneficiaryKind.Client ? !values.beneficiaryClient : !values.beneficiaryUser) ||
                          !values.startAt ||
                          !values.endAt
                        }
                        type="button"
                        onClick={() =>
                          updateRuleModal
                            .open({
                              getMinTargetValue: (rules) =>
                                customDealsApi
                                  .getCustomDealMinTargetValue({
                                    startingAt: toDate((values.startAt || new Date()).toISOString()),
                                    endingAt: toDate((values.endAt || new Date()).toISOString()),
                                    beneficiaryKind: values.beneficiaryKind,
                                    beneficiaryValues: [
                                      values.beneficiaryKind === BeneficiaryKind.User ? values.beneficiaryUser : values.beneficiaryClient
                                    ].filter((v): v is string => !!v),
                                    rules
                                  })
                                  .then((r) => parseFloat(r.data.minTargetValue))
                                  .catch((error) =>
                                    handleApiError(error, {
                                      code: { [ApiErrorCode.RestrictionNotFound]: () => 0 }
                                    })
                                  )
                            })
                            .then(({ rules, target, bonus }) => {
                              const ruleGroups = values.ruleGroups.slice().concat([
                                {
                                  rules,
                                  target: {
                                    targetMu: TargetMu.Currency,
                                    levels: [
                                      {
                                        value: target,
                                        bonus: { mu: BonusMu.Points, value: bonus, ref: undefined }
                                      }
                                    ]
                                  }
                                }
                              ]);

                              form.change('ruleGroups', ruleGroups);
                            })
                            .catch(() => void 0)
                        }
                      >
                        <PlusIcon className="mr-2 h-5 w-5" />
                        <FormattedMessage id={translations.pages.promotionCreate.createCondition} values={{ isNegation: false }} />
                      </Button>
                    </li>
                  </ul>

                  <ValidatedField field={() => null} id="rule-groups" name="ruleGroups" />
                </div>
              </Group>
            </Card>

            <div className="border-t-shade sticky bottom-0 rounded-b-lg border-t-2 bg-white p-4">
              <div className="flex items-center justify-between">
                {submitError ? (
                  <SubmitError error={submitError} />
                ) : submitFailed && invalid ? (
                  <ValidationMessage visible>
                    <FormattedMessage id={translations.pages.promotionDetail.invalid} />
                  </ValidationMessage>
                ) : (
                  <div className="flex gap-8">
                    <div>
                      <div className="font-semibold">
                        <FormattedMessage id={translations.pages.customDealCreate.targetTotal} />
                      </div>

                      <div className="leading-6">
                        <FormattedCurrency
                          currency={getCurrency()}
                          value={values.ruleGroups.reduce(
                            (total, group) => group.target?.levels?.reduce((total, level) => total + level.value ?? 0, total) ?? 0,
                            0
                          )}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="font-semibold">
                        <FormattedMessage id={translations.pages.customDealCreate.bonusTotal} />
                      </div>

                      <CoinValue
                        value={values.ruleGroups.reduce(
                          (total, group) => group.target?.levels?.reduce((total, level) => total + (level.bonus.value ?? 0), total) ?? 0,
                          0
                        )}
                      />
                    </div>
                  </div>
                )}

                <div className="flex flex-1 justify-end gap-4">
                  <LoadingButton appearance="primary" className="h-14 px-4" loading={submitting} type="submit">
                    <FormattedMessage id={translations.buttons.save} />
                  </LoadingButton>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>

      <Modal {...updateRuleModal.props} />
    </main>
  );
};
