import { Balance, BeneficiaryKind, TransactionCurrencyKind } from 'flexinet-api';
import React from 'react';

export interface GroupedBalance {
  [BeneficiaryKind.User]?: Balance;
  [BeneficiaryKind.Client]?: Balance;
  [TransactionCurrencyKind.SalesBudget]?: Balance;
  [TransactionCurrencyKind.CustomDealBudget]?: Balance;
}

export const useGroupedBalances = (balances?: Map<string, Balance[]>) => {
  return React.useMemo(() => {
    if (!balances) return;

    const entries = Array.from(balances.entries()).map(([beneficiary, balances]) => {
      const group = balances.reduce<GroupedBalance>((group, balance) => {
        if (balance.currency !== TransactionCurrencyKind.Points) {
          group[balance.currency] = balance;
          return group;
        }

        group[balance.beneficiary.kind] = balance;
        return group;
      }, {});

      return [beneficiary, group] as const;
    });

    return new Map(entries);
  }, [balances]);
};
