import { useOrderParams, useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { OrderItem, OrderList, Pagination, SearchInput } from '@binhatch/ui';
import { getAllFromApi } from '@binhatch/utility';
import { Client, User } from 'flexinet-api';
import React from 'react';
import { useIntl } from 'react-intl';

import { clientApi, orderApi, userApi } from '@/integrations/api';
import { urls } from '@/utils/url';

export const ClientOrderListPage: React.FC = () => {
  const intl = useIntl();
  const { clientId } = useStrictParams<{ clientId: string }>();
  const [query, updateQuery] = useOrderParams();

  const orders = useRemoteData(
    { key: `useOrders`, clientId, search: query.search, before: query.before, after: query.after, page: query.page },
    async ({ search, before, after, page: nextToken }) => {
      if (!clientId) return;

      const { orders, ...r } = await orderApi
        .listOrders(nextToken, search, undefined, undefined, after?.toISOString(), before?.toISOString(), undefined, [clientId])
        .then((r) => r.data);

      const userIds = Array.from(new Set(orders.map((o) => o.userID).filter((id): id is string => !!id)));

      const users: User[] = userIds.length
        ? await getAllFromApi(
            (nextToken) => userApi.listUsers(nextToken, undefined, undefined, undefined, userIds).then((r) => r.data),
            (r) => r.users
          )
        : [];

      const clientIds = Array.from(new Set(users.map((u) => u.clientId).filter((id): id is string => !!id)));

      const clients: Client[] = clientIds.length
        ? await getAllFromApi(
            (nextToken) => clientApi.listClients(nextToken, undefined, undefined, clientIds).then((r) => r.data),
            (r) => r.clients
          )
        : [];

      return {
        ...r,
        orders: orders.map<OrderItem>((order) => {
          const user = users.find((u) => u.id === order.userID);
          const client = user ? clients.find((c) => c.id === user.clientId) : undefined;
          return { order, user, client, url: urls.orders.getOne({ orderId: order.id ?? '' }) };
        })
      };
    }
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <SearchInput
          className="w-full md:w-72"
          placeholder={intl.formatMessage({ id: translations.pages.orderList.search })}
          value={query.search}
          onChange={(search: string) => updateQuery({ page: undefined, search })}
        />
      </div>

      <OrderList loading={orders.isLoading || orders.isValidating} orders={orders.data?.orders ?? []} />

      <Pagination
        hasNext={!!orders.data?.nextToken}
        hasPrevious={!!orders.data?.prevToken}
        onNext={() => updateQuery({ page: orders.data?.nextToken })}
        onPrevious={() => updateQuery({ page: orders.data?.prevToken })}
      />
    </div>
  );
};
