import { translations } from '@binhatch/locale';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  onClose?(): void;
}

export const ModalHeader = React.forwardRef<HTMLElement, React.PropsWithChildren<Props>>(({ children, onClose }, ref) => (
  <header className="relative">
    <h1 className="font-semibold">{children}</h1>

    {!!onClose && (
      <button
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        className="absolute -right-2 top-1/2 -translate-y-1/2 rounded p-2 hover:bg-gray-100"
        type="button"
        onClick={onClose}
      >
        <span className="sr-only">
          <FormattedMessage id={translations.buttons.close} />
        </span>
        <XMarkIcon aria-hidden="true" className="h-5 w-5" />
      </button>
    )}
  </header>
));
