import { useAsyncFn } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  Coin,
  CoinInput,
  CustomDealRule,
  Form,
  InputWithLabel,
  InstanceProps,
  Item,
  Loading,
  LoadingIndicator,
  LoadingMessage,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  NumberInput,
  SearchableSelect,
  SelectItem,
  SubmitError,
  ValidatedField
} from '@binhatch/ui';
import { ensureArray, getCurrency } from '@binhatch/utility';
import classnames from 'classnames';
import { MinTurnoverRestrictionValue, Rule } from 'flexinet-api';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import * as yup from 'yup';

import { useRestrictions } from '@/hooks/useRestrictions';
import { useTags } from '@/hooks/useTags';

interface Props
  extends InstanceProps<
    { rules: Rule[]; target: number; bonus: number },
    { rules?: Rule[]; target?: number; getMinTargetValue: (rules: Rule[]) => Promise<number> }
  > {}

interface RestrictionSelectItem extends Item<string> {
  restriction: MinTurnoverRestrictionValue;
  rules: [string, string[]][];
}

const schema = yup
  .object({
    selected: yup.string().required().label(translations.fields.promotionRuleCondition.label),
    target: yup
      .number()
      .when(['$more'], (more: number | undefined, schema: yup.NumberSchema) => {
        if (more === undefined) return schema;
        return schema.moreThan(more);
      })
      .required()
      .label(translations.fields.targetValue.label)
  })
  .required();

const getIdFromRules = (rules: Rule[]) => rules.map((rule) => `${rule.tagKey}#${ensureArray(rule.value?.value).join('_')}`).join('!');
const matchRestriction = (id: string) => (restriction: MinTurnoverRestrictionValue) => getIdFromRules(restriction.rules) === id;

const parseFloatWithFallback = (value: string | undefined, fallback: number) => {
  if (value === undefined) return fallback;
  return parseFloat(value);
};

const getBonusFromValues = (target: number, percent: number) => Math.ceil((target * percent) / 100);

export const UpdateRuleModal = React.forwardRef<HTMLDivElement, Props>(({ data, initialFocus, className, onAction, onClose }, ref) => {
  const intl = useIntl();

  const restrictions = useRestrictions();
  const tags = useTags();

  const items = React.useMemo(() => {
    return (
      restrictions.data?.minTurnover.map<RestrictionSelectItem>((restriction) => {
        const rules = restriction.rules.map<[string, string[]]>((r) => {
          const name = capitalize(tags.data?.find((tag) => tag.key === r.tagKey)?.description ?? r.tagKey);
          const values = r.value
            ? ensureArray(r.value?.value).map<string>((v) => v.toString())
            : [intl.formatMessage({ id: translations.fields.allowAnyValue.label })];
          return [name, values];
        });

        return {
          value: getIdFromRules(restriction.rules),
          name: rules.map(([name, values]) => `${name}: ${values.join(', ')}`).join(' + '),
          restriction,
          rules
        };
      }) ?? []
    );
  }, [restrictions.data?.minTurnover, tags.data, intl]);

  const initialValues = React.useMemo(
    () => ({
      selected: data.rules ? getIdFromRules(data.rules) : (undefined as unknown as string),
      target: data.target ?? (undefined as unknown as number)
    }),
    [data]
  );

  const [{ value }, getMinTargetValue] = useAsyncFn(
    async (selected?: string) => {
      const restriction = restrictions.data?.minTurnover.find(matchRestriction(selected ?? initialValues.selected));

      if (!restriction) return 0;

      return data.getMinTargetValue(restriction.rules).then((v) => Math.ceil(v));
    },
    [restrictions.data?.minTurnover, data.getMinTargetValue, initialValues.selected]
  );

  React.useEffect(() => {
    getMinTargetValue(initialValues.selected).catch(() => void 0);
  }, [getMinTargetValue, initialValues.selected]);

  const context = React.useMemo(() => ({ more: value ?? 0 }), [value]);

  const onSubmit = React.useCallback(
    async ({ selected, target }: yup.InferType<typeof schema>) => {
      const restriction = restrictions.data?.minTurnover.find(matchRestriction(selected));

      if (!restriction) throw new Error(`No restriction found.`);

      const bonus = getBonusFromValues(target, parseFloat(restriction.pointsPercent));

      return onAction({
        rules: restriction.rules,
        target,
        bonus
      });
    },
    [restrictions.data, onAction]
  );

  return (
    <div {...{ ref }} className={classnames(className, 'max-w-md')}>
      <ModalLayout>
        <ModalHeader {...{ onClose }}>
          <FormattedMessage
            id={data.rules?.length ? translations.modals.updateRule.updateTitle : translations.modals.updateRule.createTitle}
            values={{ isNegation: false }}
          />
        </ModalHeader>

        <div className="relative min-h-36">
          <Loading className="absolute inset-0 z-10 flex items-center justify-center" visible={restrictions.isLoading || tags.isLoading}>
            <LoadingMessage center>
              <LoadingIndicator className="h-5 w-5" />
              <div>
                <FormattedMessage id={translations.utils.loading} />
              </div>
            </LoadingMessage>
          </Loading>

          {!!restrictions.data && !!tags.data && (
            <Form {...{ ref, schema, context, initialValues, onSubmit }}>
              {({ values, invalid, submitting, submitError, handleSubmit, form }) => (
                <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                  <ValidatedField
                    field={SearchableSelect}
                    id="selected"
                    {...{ items }}
                    containerClassName="max-w-full"
                    getSearchableAttributes={({ rules }: RestrictionSelectItem) => [
                      ...rules.map(([name]) => name),
                      ...rules.map(([, values]) => values).flat()
                    ]}
                    getSelectedDisplayName={({ selectedItems }: { selectedItems?: RestrictionSelectItem[] }) => selectedItems?.map((i) => i.name).join(' + ')}
                    name="selected"
                    placeholder={intl.formatMessage({ id: translations.buttons.select })}
                    readOnly={!!submitting}
                    selectItem={({ item, getDisplayName, ...props }: { item: RestrictionSelectItem; getDisplayName: unknown }) => (
                      <SelectItem {...props} truncate={false}>
                        <div className="flex items-center justify-between gap-4">
                          <div>
                            <div className="font-semibold">
                              {item.restriction.rules.map((rule, index) => (
                                <div key={index}>
                                  <CustomDealRule {...{ rule }} tags={tags.data} />
                                </div>
                              ))}
                            </div>

                            <div>
                              <FormattedMessage id={translations.fields.minTurnoverPercent.label} />

                              {': '}

                              <FormattedNumber style="percent" value={parseFloat(item.restriction.minTurnoverPercent) / 100} />
                            </div>
                          </div>

                          <Coin>
                            <FormattedNumber style="percent" value={parseFloat(item.restriction.pointsPercent) / 100} />
                          </Coin>
                        </div>
                      </SelectItem>
                    )}
                    onChange={(selected?: string) => {
                      getMinTargetValue(selected).then((value) => form.change('target', value + 1));
                    }}
                  />

                  <ValidatedField
                    as={NumberInput}
                    description={<FormattedMessage id={translations.fields.targetValue.description} values={{ currency: getCurrency() }} />}
                    field={InputWithLabel}
                    id="target"
                    includeThousandsSeparator
                    label={<FormattedMessage id={translations.fields.targetValue.label} />}
                    name="target"
                    placeholder={intl.formatMessage({ id: translations.fields.targetValue.placeholder })}
                    readOnly={!!submitting}
                  />

                  <InputWithLabel
                    allowDecimal
                    as={CoinInput}
                    disabled
                    inputClassName="w-full"
                    label={<FormattedMessage id={translations.fields.promotionTargetPoint.label} />}
                    placeholder={intl.formatMessage({ id: translations.fields.promotionTargetPoint.placeholder })}
                    readOnly={!!submitting}
                    value={getBonusFromValues(
                      values.target,
                      parseFloatWithFallback(restrictions.data?.minTurnover.find(matchRestriction(values.selected))?.pointsPercent, 0)
                    )}
                  />

                  <SubmitError error={submitError} />

                  <div className="flex flex-row-reverse space-x-2">
                    <ModalPrimaryButton disabled={invalid} ref={initialFocus} onAction={() => handleSubmit()}>
                      <FormattedMessage id={translations.buttons.select} />
                    </ModalPrimaryButton>

                    <ModalSecondaryButton {...{ onClose }}>
                      <FormattedMessage id={translations.buttons.back} />
                    </ModalSecondaryButton>
                  </div>
                </form>
              )}
            </Form>
          )}
        </div>
      </ModalLayout>
    </div>
  );
});
