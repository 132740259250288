import { translations } from '@binhatch/locale';
import { InstanceProps, ModalHeader, ModalLayout, ModalPrimaryButton, ModalSecondaryButton } from '@binhatch/ui';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export interface Props extends InstanceProps<void, { callback?: () => Promise<void> }> {}

export const DeleteConfirmationModal = React.forwardRef<HTMLDivElement, Props>(({ initialFocus, data, className, onAction, onClose }, ref) => {
  const onSubmit = React.useCallback(async () => {
    await data.callback?.();
    onAction();
  }, [data, onAction]);

  return (
    <div {...{ ref }} className={classnames(className, 'max-w-sm')}>
      <ModalLayout>
        <ModalHeader {...{ onClose }}>
          <FormattedMessage id={translations.modals.deleteConfirmation.title} />
        </ModalHeader>

        <div className="text-sm">
          <FormattedMessage id={translations.modals.deleteConfirmation.message} />
        </div>

        <div className="flex flex-row-reverse space-x-2">
          <ModalPrimaryButton appearance="danger" ref={initialFocus} onAction={onSubmit}>
            <FormattedMessage id={translations.modals.deleteConfirmation.delete} />
          </ModalPrimaryButton>

          <ModalSecondaryButton {...{ onClose }}>
            <FormattedMessage id={translations.buttons.back} />
          </ModalSecondaryButton>
        </div>
      </ModalLayout>
    </div>
  );
});
