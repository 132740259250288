import { QueryParamConfig, decodeNumber, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  ButtonRadio,
  Card,
  FormattedCurrency,
  Pagination,
  ProgressBars,
  PromotionLabels,
  PromotionProgress,
  PromotionTargetProgressNumbers,
  SearchInput,
  Select
} from '@binhatch/ui';
import { getAllFromApi, getCurrency, getCurrentPeriod, getFallbackPeriod } from '@binhatch/utility';
import classNames from 'classnames';
import { BeneficiaryKind, MetricsCollection, Progress, ProgressInterval, ProgressState, ProgressStateAggregation, Promotion } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link, useOutletContext } from 'react-router-dom';

import { clientApi, progressApi, promotionApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { Configuration } from '@/containers/useConfiguration';

import { RealtimeToggle } from '@/components/RealtimeToggle';

type Interval = ProgressInterval | typeof INTERVAL_ALL | typeof INTERVAL_NONE;

const INTERVAL_ALL = 'all' as const;
const INTERVAL_NONE = 'none' as const;

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const period: QueryParamConfig<number | undefined> = {
  decode: (value) => decodeNumber(decodeSingleQueryParam(value, undefined), undefined),
  encode: (value) => value
};

const interval: QueryParamConfig<Interval> = {
  decode: (value) => {
    const decoded = decodeSingleQueryParam<Interval | undefined>(value, undefined);
    switch (decoded) {
      case INTERVAL_NONE:
      case ProgressInterval._100:
      case ProgressInterval._9975:
      case ProgressInterval._7550:
      case ProgressInterval._501:
        return decoded;
      default:
        return INTERVAL_ALL;
    }
  },
  encode: (value) => (value === INTERVAL_ALL ? undefined : value)
};

const config = { page, search, period, interval };

export const PromotionProgressPage: React.FC = () => {
  const promotion = useOutletContext<Promotion>();
  const [query, updateQuery] = useQueryParams({ config });

  const intl = useIntl();

  const periodId = React.useMemo(() => {
    return query.period ?? getCurrentPeriod(promotion.periods ?? [])?.id ?? getFallbackPeriod(promotion.periods ?? [])?.id;
  }, [query.period, promotion]);

  const period = React.useMemo(() => promotion.periods?.find((p) => p.id === periodId), [promotion.periods, periodId]);

  const [{ value: configuration }] = Configuration.useContainer();

  const beneficiaries = useRemoteData(
    {
      key: 'usePromotionBeneficiaries',
      promotion,
      periodId,
      search: query.search,
      interval: query.interval,
      realtime: configuration.realtime,
      nextToken: query.page,
      skip: !periodId
    },
    async ({ promotion, periodId, search, interval, realtime, nextToken }) => {
      if (!period) return;

      const hasProgress = interval === INTERVAL_ALL ? undefined : interval !== INTERVAL_NONE;
      const progressInterval = INTERVAL_ALL === interval || interval === INTERVAL_NONE ? undefined : interval;

      const data = await promotionApi
        .listPromotionBeneficiaries(
          promotion.id,
          periodId,
          hasProgress,
          nextToken,
          search,
          progressInterval,
          realtime ? ProgressStateAggregation.Total : ProgressStateAggregation.Confirmed
        )
        .then((r) => r.data);

      if (data.kind === BeneficiaryKind.User) {
        return {
          ...data,
          beneficiaries: data.beneficiaries.map(({ id, details, clientId }) => ({
            id,
            name: details.email,
            clientId,
            referenceId: undefined,
            excluded: false
          }))
        };
      }

      if (data.kind === BeneficiaryKind.Client) {
        return {
          ...data,
          beneficiaries: data.beneficiaries.map(({ id, name, referenceId, isExcluded }) => ({
            id,
            name,
            clientId: undefined,
            referenceId,
            excluded: isExcluded
          }))
        };
      }

      return;
    }
  );

  const clientIds =
    promotion.beneficiaryKind === BeneficiaryKind.User
      ? Array.from(new Set(beneficiaries.data?.beneficiaries.map((b) => b.clientId).filter((id): id is string => !!id)))
      : [];

  const clients = useRemoteData(
    {
      key: 'useBeneficiaryClients',
      clientIds,
      skip: !clientIds.length
    },
    async ({ clientIds }) => {
      const clients = await getAllFromApi(
        (nextToken) => clientApi.listClients(nextToken, undefined, undefined, clientIds).then((r) => r.data),
        (r) => r.clients
      );

      return new Map(clients.map((c) => [c.id, c]));
    }
  );

  const progress = useRemoteData(
    {
      key: 'useProgress',
      promotionId: promotion.id,
      periodId,
      interval: query.interval,
      beneficiaries: beneficiaries.data?.beneficiaries,
      skip: !beneficiaries.data,
      realtime: !!configuration.realtime
    },
    async ({ promotionId, periodId, interval, beneficiaries, realtime }) => {
      const ids = beneficiaries?.map((b) => b.id) ?? [];

      const progress: Progress[] = await getAllFromApi(
        (nextToken) =>
          progressApi
            .listProgress(
              [promotionId],
              periodId,
              promotion.beneficiaryKind === BeneficiaryKind.User ? ids : undefined,
              promotion.beneficiaryKind === BeneficiaryKind.Client ? ids : undefined,
              interval === INTERVAL_ALL || interval === INTERVAL_NONE ? undefined : interval,
              nextToken,
              realtime ? ProgressStateAggregation.Total : ProgressStateAggregation.Confirmed
            )
            .then((r) => r.data),
        (r) => r.data
      );

      return progress.reduce((map, progress) => {
        const items = map.get(progress.beneficiary.value) ?? [];

        items.push(progress);

        map.set(progress.beneficiary.value, items);

        return map;
      }, new Map<string, Progress[]>());
    }
  );

  const metrics = React.useMemo(() => {
    if (!periodId) return;

    const metricKey: keyof MetricsCollection = configuration?.realtime ? 'total' : 'active';

    return promotion.metrics?.perPeriod[periodId]?.[metricKey];
  }, [periodId, promotion, configuration?.realtime]);

  const items = React.useMemo(() => {
    return (
      beneficiaries.data?.beneficiaries?.map((item) => {
        const items = progress.data?.get(item.id) ?? [];

        const [pending, active] = items.reduce(
          ([pending, active], item) => {
            if (item.state === ProgressState.Active) return [pending, active + parseFloat(item.value)];
            else if (item.state === ProgressState.Pending) return [pending + parseFloat(item.value), active];
            return [pending, active];
          },
          [0, 0]
        );

        return {
          item,
          claimed: (configuration?.realtime ? items : items.filter((i) => i.state === ProgressState.Active)).some((i) => i.claimedAt),
          levels: promotion.target.levels.map((level) => {
            const normalizedActive = Math.max(active, 0);
            const normalizedProgress = Math.max(configuration?.realtime ? pending : 0, 0);
            const levelValue = parseFloat(level.value);

            return {
              level,
              target: levelValue,
              active: normalizedActive,
              progress: normalizedProgress,
              normalizedProgress: Math.min(normalizedProgress, Math.max(levelValue - normalizedActive, 0))
            };
          })
        };
      }) ?? []
    );
  }, [beneficiaries.data, progress, promotion, configuration?.realtime]);

  return (
    <React.Fragment>
      <Card className="flex flex-col gap-4">
        <div className="flex items-center gap-8">
          <div className="font-semibold">
            <FormattedMessage id={translations.pages.promotionDetail.period.label} />
          </div>

          <Select
            containerClassName="w-52"
            contentClassName="w-full"
            items={
              promotion.periods
                ?.filter((period) => new Date(period.startAt).getTime() <= Date.now())
                .map((period) => ({
                  value: period.id,
                  name: intl.formatDateTimeRange(new Date(period.startAt), new Date(period.endAt), {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })
                })) ?? []
            }
            placeholder={intl.formatMessage({ id: translations.pages.promotionDetail.period.placeholder })}
            value={periodId}
            onChange={(period: number) => updateQuery({ period, page: undefined })}
          />
        </div>

        <div className="grid items-center gap-8 whitespace-nowrap">
          <div className="grid gap-2 lg:grid-cols-3">
            <div className="bg-shade-light flex items-center justify-between gap-4 rounded-l-lg p-4 font-semibold">
              <div className="text-sm uppercase tracking-wide text-slate-500">
                <FormattedMessage id={translations.pages.promotionDetail.progress.total} />
              </div>

              <div>
                <FormattedCurrency currency={getCurrency(promotion)} value={parseFloat(metrics?.salesValue ?? '0')} />
              </div>
            </div>

            <div className="bg-shade-light flex items-center justify-between gap-4 p-4 font-semibold">
              <div className="text-sm uppercase tracking-wide text-slate-500">
                <FormattedMessage id={translations.pages.promotionDetail.progress.participants} />
              </div>

              <div className="flex gap-2">
                {metrics?.participationCount ?? 0} / {metrics?.clientCount ?? 0}
                <div className="">
                  (
                  <FormattedNumber
                    maximumFractionDigits={2}
                    style="percent"
                    value={(metrics?.participationCount ?? 0) / Math.max(metrics?.clientCount ?? 1, 1)}
                  />
                  )
                </div>
              </div>
            </div>

            <div className="bg-shade-light flex items-center justify-between gap-4 rounded-r-lg p-4 font-semibold">
              <div className="text-sm uppercase tracking-wide text-slate-500">
                <FormattedMessage id={translations.pages.promotionDetail.progress.completed} />
              </div>

              <div className="flex gap-2">
                {metrics?.participationCountPerProgress?.[ProgressInterval._100] ?? 0} / {metrics?.participationCount ?? 0}
                <div>
                  (
                  <FormattedNumber
                    maximumFractionDigits={2}
                    style="percent"
                    value={(metrics?.participationCountPerProgress?.[ProgressInterval._100] ?? 0) / Math.max(metrics?.participationCount ?? 1, 1)}
                  />
                  )
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 xl:flex-row xl:items-center">
            <RealtimeToggle />

            {!!period && (
              <div className="flex-1 space-y-1">
                <PromotionProgress {...{ metrics }} />
                <PromotionLabels />
              </div>
            )}
          </div>
        </div>
      </Card>

      <Card className="flex flex-col gap-8">
        <div className="flex flex-col gap-2 xl:flex-row xl:items-center">
          <div className="font-semibold">
            <FormattedMessage id={translations.pages.promotionDetail.progress.title} values={{ type: promotion.beneficiaryKind }} />
          </div>

          <div className="flex-1">
            <ButtonRadio
              className="flex-wrap md:h-10"
              id="period"
              items={[INTERVAL_ALL, ProgressInterval._100, ProgressInterval._9975, ProgressInterval._7550, ProgressInterval._501, INTERVAL_NONE].map(
                (value) => ({
                  value,
                  name: intl.formatMessage({ id: translations.components.promotionProgress[value] })
                })
              )}
              name="period"
              type="radio"
              value={query.interval}
              onChange={(interval: Interval) => updateQuery({ interval, page: undefined })}
            />
          </div>

          <div>
            <SearchInput
              className="w-full md:w-72"
              placeholder={intl.formatMessage({ id: translations.pages.promotionList.search })}
              value={query.search}
              onChange={(search: string) => updateQuery({ page: undefined, search })}
            />
          </div>
        </div>

        <div className="flow-root">
          <div className="-mx-2 overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full table-fixed">
                <thead>
                  <tr className="text-left">
                    <th className="max-w-96 px-4 py-2" scope="col">
                      <FormattedMessage id={promotion.beneficiaryKind === BeneficiaryKind.User ? translations.tabs.users : translations.tabs.clients} />
                    </th>

                    {promotion.target.levels.map((_, index) => (
                      <th className="px-4 py-2" key={index} scope="col">
                        <FormattedMessage id={translations.pages.promotionCreate.targetLevelName} values={{ index: index + 1 }} />
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {items.map(({ item, claimed, levels }, index) => (
                    <tr className="group" key={index}>
                      <td
                        className={classNames('max-w-96 whitespace-nowrap px-4 py-2', {
                          'opacity-40 group-hover:opacity-100': item.excluded,
                          'text-success': claimed
                        })}
                      >
                        <div>
                          <Link
                            className="block truncate font-semibold"
                            state={{ from: 1 }}
                            to={urls.clients.getOne({
                              clientId: promotion.beneficiaryKind === BeneficiaryKind.Client ? item.id : item.clientId!
                            })}
                          >
                            {promotion.beneficiaryKind === BeneficiaryKind.Client ? item.name : clients.data?.get(item.clientId!)?.name}
                          </Link>
                        </div>

                        <div className="text-gray-400">
                          {promotion.beneficiaryKind === BeneficiaryKind.User ? item.name : item.referenceId}
                          {!!item.excluded && (
                            <React.Fragment>
                              {' - '}
                              <span className="text-error">
                                <FormattedMessage id={translations.pages.clientDetail.clientExcluded} />
                              </span>
                            </React.Fragment>
                          )}
                        </div>
                      </td>

                      {levels.map(({ level, target, progress, active, normalizedProgress }, index) => (
                        <td
                          className={classNames('w-full min-w-96 space-y-1 whitespace-nowrap px-4 py-2 align-middle', {
                            'opacity-40 group-hover:opacity-100': item.excluded
                          })}
                          key={index}
                        >
                          <PromotionTargetProgressNumbers
                            {...{ promotion }}
                            progress={{
                              index,
                              level,
                              value: active + progress,
                              percent: Math.min((active + normalizedProgress) / target, 1),
                              claimed: false
                            }}
                          />

                          <ProgressBars
                            bars={[
                              {
                                progress: Math.min(active / target, 1),
                                className:
                                  active / target < 0.5 ? 'bg-error' : active / target < 0.75 ? 'bg-brand' : active / target < 1 ? 'bg-brand' : 'bg-success'
                              },
                              { progress: normalizedProgress / target, className: 'bg-blue-500' }
                            ]}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}

                  {!items.length && (
                    <tr>
                      <td className="px-4 py-2" colSpan={promotion.target.levels.length + 1}>
                        <FormattedMessage id={translations.pages.promotionDetail.progress.empty} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {!!items.length && (
          <Pagination
            hasNext={!!beneficiaries.data?.nextToken}
            hasPrevious={!!beneficiaries.data?.prevToken}
            onNext={() => updateQuery({ page: beneficiaries.data?.nextToken })}
            onPrevious={() => updateQuery({ page: beneficiaries.data?.prevToken })}
          />
        )}
      </Card>
    </React.Fragment>
  );
};
