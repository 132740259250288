import classNames from 'classnames';
import React from 'react';

const apperances = {
  brand: 'bg-warning-light',
  secondary: 'bg-shade'
};

interface Props {
  apperance?: keyof typeof apperances;
  className?: string;
}

export const Banner: React.FC<React.PropsWithChildren<Props>> = ({ apperance = 'brand', ...props }) => (
  <div {...props} className={classNames('flex gap-4 rounded-lg p-4', apperances[apperance], props.className)} />
);
