import { Auth } from '@/containers/useAuth';
import { useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  BonusValue,
  Button,
  Card,
  DynamicBackButton,
  PageHeading,
  PromotionArticles,
  PromotionImage,
  PromotionItemPeriod,
  Tab,
  TabList,
  TargetValue
} from '@binhatch/ui';
import { fromPromotion, getCurrency, getCurrentPeriod, getFallbackPeriod, getPromotionDays, sortPeriods } from '@binhatch/utility';
import { UserRole } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Outlet } from 'react-router-dom';

import { productApi, promotionApi } from '@/integrations/api';
import { urls } from '@/utils/url';

export const PromotionDetailPage: React.FC = () => {
  const { context } = Auth.useContainer();
  const { promotionId } = useStrictParams<{ promotionId: string }>();

  const promotion = useRemoteData({ key: 'usePromotion', promotionId, skip: !promotionId }, async ({ promotionId }) => {
    if (!promotionId) throw new Error('Promotion id is missing.');

    const promotion = await promotionApi
      .getPromotion(promotionId)
      .then((r) => r.data)
      .then(fromPromotion);

    return { ...promotion, periods: sortPeriods(promotion.periods ?? []) };
  });

  const period = React.useMemo(() => {
    if (!promotion.data) return;

    return getCurrentPeriod(promotion.data.periods) ?? getFallbackPeriod(promotion.data.periods);
  }, [promotion.data]);

  return (
    <main className="space-y-6">
      <div className="flex justify-between gap-4">
        <DynamicBackButton />

        <div className="flex flex-1 items-center justify-end gap-4">
          {context?.user.role === UserRole.SystemAdmin && (
            <React.Fragment>
              <Button
                appearance="secondary"
                as={Link}
                className="h-10 px-4"
                state={{ from: 1 }}
                to={urls.promotions.getOne({ promotionId }, urls.promotions.clone)}
              >
                <FormattedMessage id={translations.buttons.copy} />
              </Button>

              <Button
                appearance="secondary"
                as={Link}
                className="h-10 px-4"
                state={{ from: 1 }}
                to={urls.promotions.getOne({ promotionId }, urls.promotions.update)}
              >
                <FormattedMessage id={translations.buttons.edit} />
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>

      <Card className="flex flex-col gap-4">
        <div className="flex flex-col gap-8 xl:flex-row">
          <div className="flex gap-8">
            <PromotionImage className="w-24 flex-shrink-0" image={promotion.data?.imageURL} logo={promotion.data?.brandLogoURL} />

            <div className="flex w-full flex-1 flex-col gap-2">
              {!!promotion.data && <PromotionItemPeriod days={period ? getPromotionDays(period) : 0} promotion={promotion.data} {...{ period }} />}

              <PageHeading title={promotion.data?.name} />

              <PromotionArticles tags={promotion.data?.tags ?? {}} />

              <div>{promotion.data?.description}</div>
            </div>
          </div>
        </div>

        {promotion.data && (
          <div className="bg-shade-light grid w-full gap-2 rounded p-4">
            {promotion.data?.target.levels.map((level, index) => (
              <div className="flex flex-col gap-4 md:flex-row md:items-center" key={index}>
                <div className="font-semibold">
                  <FormattedMessage id={translations.pages.promotionCreate.targetLevelName} values={{ index: index + 1 }} />
                </div>

                <div className="flex flex-wrap gap-4">
                  <TargetValue currency={getCurrency(promotion.data)} unit={promotion.data!.target.targetMu} value={parseFloat(level.value)} />

                  <div className="hidden md:block"> = </div>

                  <BonusValue
                    getProduct={(id) => productApi.getProductById(id).then((r) => r.data)}
                    reference={level.bonus.ref}
                    unit={level.bonus.mu}
                    value={level.bonus.value}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>

      <TabList>
        <Tab end to={urls.promotions.getOne({ promotionId })}>
          <FormattedMessage id={translations.tabs.promotionProgress} />
        </Tab>

        <Tab to={urls.promotions.getOne({ promotionId }, urls.promotions.config)}>
          <FormattedMessage id={translations.tabs.promotionConfig} />
        </Tab>

        {context?.user.role === UserRole.SystemAdmin && (
          <Tab to={urls.promotions.getOne({ promotionId }, urls.promotions.history)}>
            <FormattedMessage id={translations.tabs.promotionHistory} />
          </Tab>
        )}
      </TabList>

      {!!promotion.data && <Outlet context={promotion.data} />}
    </main>
  );
};
