import { useRemoteData } from '@binhatch/hooks';
import { getAllFromApi } from '@binhatch/utility';
import { BeneficiaryKind, ProgressStateAggregation, Promotion } from 'flexinet-api';

import { progressApi } from '@/integrations/api';

interface UseCustomDealProgressOptions {
  promotions?: Promotion[];
  realtime?: boolean;
}

export const useCustomDealProgress = ({ promotions, realtime }: UseCustomDealProgressOptions) => {
  return useRemoteData({ key: 'useCustomDealProgress', promotions, realtime }, async ({ promotions, realtime }) => {
    const ids = promotions?.map((p) => p.id) ?? [];

    if (!ids.length) return;

    const clientIds = promotions
      ?.filter((p) => p.beneficiaryKind === BeneficiaryKind.Client)
      .map((p) => p.beneficiaryIds)
      .flat();

    const userIds = promotions
      ?.filter((p) => p.beneficiaryKind === BeneficiaryKind.User)
      .map((p) => p.beneficiaryIds)
      .flat();

    return await getAllFromApi(
      (nextToken) =>
        progressApi
          .listProgress(
            ids,
            undefined,
            userIds,
            clientIds,
            undefined,
            nextToken,
            realtime ? ProgressStateAggregation.Total : ProgressStateAggregation.Confirmed
          )
          .then((r) => r.data),
      (r) => r.data
    );
  });
};
