import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  AsyncButton,
  ButtonRadio,
  EmptyState,
  LoadingState,
  Pagination,
  PromotionClaimed,
  PromotionItem,
  PromotionItemContainer,
  PromotionTargetProgress,
  SearchInput
} from '@binhatch/ui';
import { fromPromotion, getAllFromApi, getCurrentPeriod, getFallbackPeriod, getLastPromotionClientProgress, getPromotionDays } from '@binhatch/utility';
import { Client, ProgressStateAggregation, PromotionSortByField, PromotionType, SortDirection } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useOutletContext } from 'react-router-dom';

import { Configuration } from '@/containers/useConfiguration';
import { progressApi, promotionApi } from '@/integrations/api';
import { urls } from '@/utils/url';

const statuses = ['active', 'scheduled', 'expired'] as const;
type Status = (typeof statuses)[number];

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const status: QueryParamConfig<Status> = {
  decode: (value) => {
    const v = decodeSingleQueryParam(value, undefined);

    if (v && statuses.includes(v as Status)) return v as Status;

    return 'active';
  },
  encode: (value) => value
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, status, search };

export const ClientPromotionListPage: React.FC = () => {
  const client = useOutletContext<Client | undefined>();
  const { clientId } = useStrictParams<{ clientId: string }>();
  const intl = useIntl();
  const [query, updateQuery] = useQueryParams({ config });
  const [claimed, setClaimed] = React.useState<string[]>([]);
  const [{ value: configuration }] = Configuration.useContainer();

  const dates = React.useMemo(
    () => ({
      startingAfter: query.status === 'scheduled' ? new Date().toISOString() : undefined,
      endingBefore: query.status === 'expired' ? new Date().toISOString() : undefined,
      startingBefore: query.status === 'active' ? new Date().toISOString() : undefined,
      endingAfter: query.status === 'active' ? new Date().toISOString() : undefined
    }),
    [query.status]
  );

  const promotions = useRemoteData(
    { key: `usePromotions`, clientId, dates, search: query.search, page: query.page },
    async ({ search, clientId, dates, page: token }) => {
      return await promotionApi
        .listPromotions(
          token,
          undefined,
          undefined,
          undefined,
          PromotionType.Regular,
          dates.startingAfter,
          dates.endingBefore,
          dates.startingBefore,
          dates.endingAfter,
          [clientId],
          search,
          PromotionSortByField.EndsAt,
          SortDirection.Desc
        )
        .then((r) => r.data)
        .then((r) => ({ ...r, promotions: r.promotions.map(fromPromotion) }));
    }
  );

  const claimable = useRemoteData({ key: `useClaimablePromotions`, clientId, dates, search: query.search }, async ({ search, clientId, dates }) => {
    const ids = await getAllFromApi(
      async (nextPage) => {
        const { data } = await promotionApi.listPromotions(
          nextPage,
          undefined,
          undefined,
          undefined,
          PromotionType.Regular,
          dates.startingAfter,
          dates.endingBefore,
          dates.startingBefore,
          dates.endingAfter,
          [clientId],
          search,
          undefined,
          undefined,
          true
        );

        return data;
      },
      (r) => r.promotions.map((p) => p.id)
    );

    return new Set(ids);
  });

  const progress = useRemoteData(
    { key: 'useProgress', ids: promotions.data?.promotions.map((p) => p.id) ?? [], realtime: configuration?.realtime },
    async ({ ids, realtime }) => {
      if (!ids.length) return;

      return await getAllFromApi(
        (nextToken) =>
          progressApi
            .listProgress(
              ids,
              undefined,
              undefined,
              [clientId],
              undefined,
              nextToken,
              realtime ? ProgressStateAggregation.Total : ProgressStateAggregation.Confirmed
            )
            .then((r) => r.data),
        (r) => r.data
      );
    }
  );

  const items = React.useMemo(() => {
    return (
      promotions.data?.promotions.map((promotion) => {
        const period = getCurrentPeriod(promotion.periods ?? []) ?? getFallbackPeriod(promotion.periods ?? []);

        const lastProgress = getLastPromotionClientProgress(promotion, period, progress.data ?? []);

        return {
          promotion,
          days: period ? getPromotionDays(period) : 0,
          period,
          progress: lastProgress,
          claimable: !!claimable.data?.has(promotion.id)
        };
      }) ?? []
    );
  }, [promotions.data, claimable.data, progress.data]);

  return (
    <div className="space-y-6">
      <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
        <ButtonRadio
          className="h-10"
          id="period"
          items={statuses.map((value) => ({
            value,
            name: intl.formatMessage({ id: translations.enum.promotionStatus[value] })
          }))}
          name="period"
          type="radio"
          value={query.status}
          onChange={(status: Status) => updateQuery({ page: undefined, status })}
        />

        <div className="flex items-center gap-4">
          <SearchInput
            className="w-full md:w-72"
            placeholder={intl.formatMessage({ id: translations.pages.promotionList.search })}
            value={query.search}
            onChange={(search: string) => updateQuery({ page: undefined, search })}
          />
        </div>
      </div>

      <LoadingState loading={promotions.isLoading || promotions.isValidating}>
        {!promotions.isLoading && !promotions.data?.promotions.length && (
          <EmptyState>
            <FormattedMessage id={translations.pages.promotionList.empty} />
          </EmptyState>
        )}

        <ul className="grid min-h-[7rem] w-full gap-4 rounded-lg xl:grid-cols-2 2xl:grid-cols-3">
          {items.map(({ promotion, days, period, progress, claimable }) => (
            <li className="min-w-0" key={promotion.id}>
              <PromotionItemContainer>
                <Link
                  className="flex flex-col gap-4 p-4"
                  state={{ from: 1 }}
                  to={urls.promotions.getOne({ promotionId: promotion.id }) + `?search=${client?.name}`}
                >
                  <PromotionItem {...{ promotion, period, days }} />

                  <PromotionTargetProgress {...{ promotion, progress }} />
                </Link>

                {(claimable || progress.claimed || claimed.includes(promotion.id)) && (
                  <div className="-mt-4 flex justify-end p-4">
                    {progress.claimed || claimed.includes(promotion.id) ? (
                      <PromotionClaimed />
                    ) : (
                      <AsyncButton
                        appearance="primary"
                        className="h-10 px-4"
                        onClick={async () => {
                          await promotionApi.claimPromotion(promotion.id, { clientId });
                          setClaimed((claimed) => [...claimed, promotion.id]);
                          return false;
                        }}
                      >
                        <FormattedMessage id={translations.pages.promotionList.provideBonus} />
                      </AsyncButton>
                    )}
                  </div>
                )}
              </PromotionItemContainer>
            </li>
          ))}
        </ul>
      </LoadingState>

      <Pagination
        hasNext={!!promotions.data?.nextToken}
        hasPrevious={!!promotions.data?.prevToken}
        onNext={() => updateQuery({ page: promotions.data?.nextToken })}
        onPrevious={() => updateQuery({ page: promotions.data?.prevToken })}
      />
    </div>
  );
};
