import { reportApi } from '@/integrations/api';
import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  Button,
  ButtonRadio,
  Modal,
  PageLoaderHeading,
  Skeleton,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  useModal
} from '@binhatch/ui';
import classNames from 'classnames';
import { ReportResponse } from 'flexinet-api';
import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

import { CreateReportModal } from './CreateReportModal';

const type: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const config = { type };

export const ReportsPage: React.FC = () => {
  const intl = useIntl();
  const [query, updateQuery] = useQueryParams({ config });

  const modal = useModal(CreateReportModal);

  const types = useRemoteData({ key: `useReportTypes` }, async () => reportApi.getReports().then((r) => r.data.types));

  const type = query.type ?? types.data?.[0];

  const download = React.useCallback(({ url }: ReportResponse) => {
    if (!url) return;

    window.open(url);
  }, []);

  const reports = useRemoteData(
    { key: `useReports`, type },
    async ({ type }) => {
      if (!type) return [];
      return reportApi.listReport(type).then((r) => r.data.reports);
    },
    { refreshInterval: 10000 }
  );

  return (
    <React.Fragment>
      <main className="space-y-6">
        <div className="flex items-center justify-between gap-4">
          <PageLoaderHeading loading={reports.isLoading}>
            <FormattedMessage id={translations.pages.reports.title} />
          </PageLoaderHeading>

          <div>
            <Button
              appearance="primary"
              className="h-10 px-4"
              onClick={() => {
                modal
                  .open({})
                  .then((report) => {
                    download(report);

                    reports.mutate().catch(() => void 0);
                  })
                  .catch(() => void 0);
              }}
            >
              <FormattedMessage id={translations.modals.createReport.title} />
            </Button>
          </div>
        </div>

        <Skeleton className="w-40" size="h-10" visible={!types.data?.length}>
          <ButtonRadio
            className="h-10"
            id="period"
            items={
              types.data?.map((value) => ({
                value,
                name: intl.formatMessage({
                  id:
                    value in translations.enum.reportType
                      ? // TODO
                        translations.enum.reportType[value as keyof typeof translations.enum.reportType]
                      : translations.enum.reportType.unknown
                })
              })) ?? []
            }
            name="period"
            type="radio"
            value={type}
            onChange={(type: string) => updateQuery({ type })}
          />
        </Skeleton>

        <Table>
          <TableHead>
            <TableHeadCell className="text-left">
              <FormattedMessage id={translations.pages.reports.table.id} />
            </TableHeadCell>

            <TableHeadCell className="text-left">
              <FormattedMessage id={translations.pages.reports.table.name} />
            </TableHeadCell>

            <TableHeadCell className="text-left">
              <FormattedMessage id={translations.pages.reports.table.type} />
            </TableHeadCell>

            <TableHeadCell className="text-left">
              <FormattedMessage id={translations.pages.reports.table.status} />
            </TableHeadCell>

            <TableHeadCell className="text-left">
              <FormattedMessage id={translations.pages.reports.table.createdAt} />
            </TableHeadCell>

            <TableHeadCell>
              <span className="sr-only">
                <FormattedMessage id={translations.pages.reports.table.actions} />
              </span>
            </TableHeadCell>
          </TableHead>

          <TableBody>
            {reports.data?.map((report, index) => (
              <TableRow key={index}>
                <TableBodyCell className="whitespace-nowrap font-medium text-gray-900">{index + 1}</TableBodyCell>

                <TableBodyCell>
                  <div className="max-w-xl truncate">{report.name}</div>
                </TableBodyCell>

                <TableBodyCell className="whitespace-nowrap">
                  <FormattedMessage
                    id={
                      report.type in translations.enum.reportType
                        ? // TODO
                          translations.enum.reportType[report.type as keyof typeof translations.enum.reportType]
                        : translations.enum.reportType.unknown
                    }
                  />
                </TableBodyCell>

                <TableBodyCell className="whitespace-nowrap">
                  <span
                    className={classNames('inline-flex items-center rounded-md px-2 py-1 text-xs font-medium', {
                      'bg-success text-white': !!report.url,
                      'bg-yellow-400 text-yellow-700': !report.url
                    })}
                  >
                    {report.url ? 'Completed' : 'In progress'}
                  </span>
                </TableBodyCell>

                <TableBodyCell className="whitespace-nowrap">
                  <FormattedDate dateStyle="long" timeStyle="short" value={report.createdAt} />
                </TableBodyCell>

                <TableBodyCell className="relative whitespace-nowrap text-right">
                  {report.url && (
                    <button className="font-semibold" type="button" onClick={() => download(report)}>
                      <FormattedMessage id={translations.pages.reports.table.download} />
                      <span className="sr-only">, {index + 1}</span>
                    </button>
                  )}
                </TableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </main>

      <Modal {...modal.props} />
    </React.Fragment>
  );
};
