import * as React from 'react';
import { createNumberMask } from 'text-mask-addons';

import { getValueFromEvent, MaskedInput } from './MaskedInput';

type PotentialAmount = string | number | null | undefined;

interface Props {
  value?: PotentialAmount;
  prefix?: string;
  suffix?: string;
  allowDecimal?: boolean;
  includeThousandsSeparator?: boolean;
  onChange?(value?: PotentialAmount): void;
}

export const NumberInput = React.forwardRef(function NumberInput(
  { prefix = '', suffix = '', includeThousandsSeparator = false, allowDecimal, ...props }: Props,
  ref: React.Ref<any>
) {
  const mask = React.useMemo(
    () => createNumberMask({ prefix, suffix, includeThousandsSeparator, allowDecimal }),
    [prefix, suffix, includeThousandsSeparator, allowDecimal]
  );

  const value = React.useMemo(() => props.value?.toString() ?? '', [props.value]);

  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = getValueFromEvent(event);
      const normalizedValue = value.replace(/[^\d.]/g, '');
      const parsedValue = parseFloat(normalizedValue);

      return props.onChange?.(isNaN(parsedValue) ? value : parsedValue);
    },
    [props]
  );

  return <MaskedInput {...props} {...{ ref, value, mask, onChange }} />;
});
