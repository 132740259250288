import { translations } from '@binhatch/locale';
import { fromDate, getUserName } from '@binhatch/utility';
import { Order, User, UserOrder } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type AnyOrder = Order | UserOrder;

interface Props {
  sourceReference: AnyOrder['sourceReference'];
}

const isUserSourceReference = (source?: Props['sourceReference']): source is User => !!source && !('promotion' in source);

export const OrderReference: React.FC<Props> = ({ sourceReference }) => {
  if (!sourceReference) return null;

  return (
    <div>
      {isUserSourceReference(sourceReference) ? (
        <FormattedMessage
          id={translations.components.transactionList.link.user}
          values={{
            name: getUserName(sourceReference) || sourceReference.details.email,
            a: (children) => children
          }}
        />
      ) : (
        <FormattedMessage
          id={translations.components.transactionList.link.promotion}
          values={{
            name: sourceReference.promotion.name,
            start: new Date(fromDate(sourceReference.period.startAt)),
            end: new Date(fromDate(sourceReference.period.endAt)),
            a: (children) => children
          }}
        />
      )}
    </div>
  );
};
