import { getAccessToken, renewAccessTokenInterceptor } from '@binhatch/utility';
import axios from 'axios';
import {
  AuditApi,
  BalanceApi,
  BulkApi,
  CategoryApi,
  ClientApi,
  Configuration,
  ConfigurationApi,
  CustomDealsApi,
  IntegrationApi,
  NotificationApi,
  OrderApi,
  ProductApi,
  ProgressApi,
  PromotionApi,
  ReportApi,
  SegmentApi,
  TagApi,
  TenantApi,
  TransactionApi,
  UserApi
} from 'flexinet-api';

import { API_URL } from '@/config';

const config = new Configuration({ accessToken: getAccessToken });

const apiClient = axios.create({ baseURL: API_URL });

apiClient.interceptors.response.use(undefined, renewAccessTokenInterceptor);

export const tenantApi = new TenantApi(config, undefined, apiClient);
export const segmentApi = new SegmentApi(config, undefined, apiClient);
export const promotionApi = new PromotionApi(config, undefined, apiClient);
export const productApi = new ProductApi(config, undefined, apiClient);
export const userApi = new UserApi(config, undefined, apiClient);
export const tagApi = new TagApi(config, undefined, apiClient);
export const orderApi = new OrderApi(config, undefined, apiClient);
export const balanceApi = new BalanceApi(config, undefined, apiClient);
export const categoryApi = new CategoryApi(config, undefined, apiClient);
export const progressApi = new ProgressApi(config, undefined, apiClient);
export const clientApi = new ClientApi(config, undefined, apiClient);
export const auditApi = new AuditApi(config, undefined, apiClient);
export const transactionApi = new TransactionApi(config, undefined, apiClient);
export const integrationApi = new IntegrationApi(config, undefined, apiClient);
export const configurationApi = new ConfigurationApi(config, undefined, apiClient);
export const reportApi = new ReportApi(config, undefined, apiClient);
export const bulkApi = new BulkApi(config, undefined, apiClient);
export const customDealsApi = new CustomDealsApi(config, undefined, apiClient);
export const notificationApi = new NotificationApi(config, undefined, apiClient);
