import { translations } from '@binhatch/locale';
import { Switch } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Configuration } from '@/containers/useConfiguration';

export const RealtimeToggle: React.FC = () => {
  const [{ value: config }, setConfig] = Configuration.useContainer();

  return (
    <Switch.Group as="div" className="relative flex select-none items-center">
      <Switch
        checked={!!config?.realtime}
        className={classNames(
          config?.realtime ? 'bg-brand' : 'bg-gray-300',
          'focus:ring-brand relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2'
        )}
        onChange={() => setConfig((c) => ({ ...c, realtime: !config?.realtime }))}
      >
        <span
          className={classNames(
            config?.realtime ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              config?.realtime ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
          >
            <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
              <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
            </svg>
          </span>
          <span
            aria-hidden="true"
            className={classNames(
              config?.realtime ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
              'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
          >
            <svg className="text-brand h-3 w-3" fill="currentColor" viewBox="0 0 12 12">
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </Switch>

      <Switch.Label as="span" className="ml-3 cursor-pointer text-sm font-semibold">
        <FormattedMessage id={translations.components.realtimeToggle.label} />
      </Switch.Label>

      <div className="peer p-2">
        <InformationCircleIcon className="h-5 w-5 stroke-2 text-slate-500" />
      </div>

      <div className="bg-shade-light absolute left-0 top-8 z-10 hidden rounded p-4 text-sm shadow-lg peer-hover:block">
        <FormattedMessage id={translations.components.realtimeToggle.description} />
      </div>
    </Switch.Group>
  );
};
