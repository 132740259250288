import axios, { AxiosResponse } from 'axios';

export type OnUploadProgress = (event: ProgressEvent | AxiosResponse) => void;

export interface UploadFileOptions {
  file: File;
  url: string;
  headers?: Record<string, string>;
  onUploadProgress?: OnUploadProgress;
}

export const uploadFile = async ({ url, file, headers, onUploadProgress }: UploadFileOptions) => {
  await axios.put(url, file, { headers, onUploadProgress });
};
