import { translations } from '@binhatch/locale';
import {
  AsyncButton,
  Button,
  Card,
  Coin,
  CustomDealRule,
  Form,
  InputWithLabel,
  LoadingButton,
  Modal,
  SubmitError,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  ValidatedField,
  useModal
} from '@binhatch/ui';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Feature, MinTurnoverRestrictionKindEnum } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import * as yup from 'yup';

import { Configuration } from '@/containers/useConfiguration';

import { useRestrictions } from '@/hooks/useRestrictions';
import { useTags } from '@/hooks/useTags';
import { configurationApi, customDealsApi } from '@/integrations/api';
import { CreateMinTurnoverRestrictionModal } from './CreateMinTurnoverRestrictionModal';

const schema = yup
  .object({
    refundLapsePeriod: yup.number().optional().label(translations.fields.refund.label)
  })
  .required();

const PromotionConfiguration: React.FC = () => {
  const intl = useIntl();
  const [{ value: configuration }, , reload] = Configuration.useContainer();

  const initialValues = React.useMemo(() => ({ refundLapsePeriod: configuration.promotionConfig?.refundLapsePeriod }), [configuration]);

  const onSubmit = React.useCallback(
    async ({ refundLapsePeriod }: yup.InferType<typeof schema>) => {
      await configurationApi.createConfiguration({
        promotionConfig: { refundLapsePeriod }
      });
      await reload();
    },
    [reload]
  );

  return (
    <Card className="flex gap-4">
      <Form {...{ initialValues, schema, onSubmit }}>
        {({ submitting, submitError, handleSubmit }) => (
          <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit}>
            <div className="text-sm font-semibold">
              <FormattedMessage id={translations.pages.settings.promotions.promotions.title} />
            </div>

            <ValidatedField
              field={InputWithLabel}
              id="refund-period"
              label={<FormattedMessage id={translations.fields.refund.label} />}
              name="refundLapsePeriod"
              placeholder={intl.formatMessage({ id: translations.fields.refund.placeholder })}
              readOnly={submitting}
              type="text"
            />

            <SubmitError error={submitError} />

            <div className="flex justify-end gap-4">
              <LoadingButton appearance="primary" className="h-10 px-4" loading={submitting} type="submit">
                <FormattedMessage id={translations.buttons.save} />
              </LoadingButton>
            </div>
          </form>
        )}
      </Form>
    </Card>
  );
};

const CustomDealConfiguration: React.FC = () => {
  const createMinTurnoverModal = useModal(CreateMinTurnoverRestrictionModal);

  const tags = useTags();
  const restrictions = useRestrictions();

  return (
    <React.Fragment>
      <Card className="flex flex-col gap-4">
        <div className="text-sm font-semibold">
          <FormattedMessage id={translations.pages.settings.promotions.customDeals.title} />
        </div>

        <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
          <div className="text-sm font-semibold">
            <FormattedMessage id={translations.pages.settings.promotions.customDeals.minTurnover} />
          </div>

          <Button
            appearance="outline"
            className="h-10 px-4"
            type="button"
            onClick={() =>
              createMinTurnoverModal
                .open({
                  value: {},
                  onUpdate: (value) =>
                    customDealsApi
                      .createCustomDealRestrictions({
                        kind: MinTurnoverRestrictionKindEnum.MinTurnover,
                        values: [...(restrictions.data?.minTurnover ?? []), value]
                      })
                      .then(() => void 0)
                })
                .then(() => restrictions.mutate())
                .catch(() => void 0)
            }
          >
            <FormattedMessage id={translations.pages.settings.promotions.customDeals.createMinTurnover} />
          </Button>
        </div>

        {!!restrictions.data?.minTurnover && (
          <Table>
            <TableHead>
              <TableHeadCell className="text-left">
                <FormattedMessage id={translations.fields.promotionRuleCondition.label} />
              </TableHeadCell>

              <TableHeadCell className="hidden w-16 text-right md:table-cell" />

              <TableHeadCell className="hidden w-20 text-left md:table-cell">
                <FormattedMessage id={translations.fields.priority.label} />
              </TableHeadCell>

              <TableHeadCell className="hidden w-20 text-right md:table-cell">
                <FormattedMessage id={translations.fields.bonusPointPercent.label} />
              </TableHeadCell>

              <TableHeadCell className="w-32">
                <span className="sr-only">
                  <FormattedMessage id={translations.pages.reports.table.actions} />
                </span>
              </TableHeadCell>
            </TableHead>

            <TableBody>
              {restrictions.data.minTurnover.map((value, index) => (
                <TableRow
                  className="hover:bg-shade-light group cursor-pointer"
                  key={index}
                  onClick={() =>
                    createMinTurnoverModal
                      .open({
                        value,
                        onUpdate: (v) =>
                          customDealsApi
                            .createCustomDealRestrictions({
                              kind: MinTurnoverRestrictionKindEnum.MinTurnover,
                              values: [...(restrictions.data?.minTurnover.filter((rv) => rv !== value) ?? []), v]
                            })
                            .then(() => void 0)
                      })
                      .then(() => restrictions.mutate())
                      .catch(() => void 0)
                  }
                >
                  <TableBodyCell className="font-semibold text-gray-900">
                    <div>
                      {value.rules.map((rule, index) => (
                        <CustomDealRule key={index} {...{ rule }} tags={tags.data} />
                      ))}
                    </div>
                  </TableBodyCell>

                  <TableBodyCell className="hidden whitespace-nowrap font-semibold text-gray-900 md:table-cell">
                    (<FormattedNumber style="percent" value={parseFloat(value.minTurnoverPercent) / 100} />)
                  </TableBodyCell>

                  <TableBodyCell className="hidden whitespace-nowrap md:table-cell">
                    <FormattedMessage id={translations.enum.customDealRestrictionPriority[value.priority ?? 'medium']} />
                  </TableBodyCell>

                  <TableBodyCell className="relative hidden whitespace-nowrap md:table-cell">
                    <div className="flex justify-end">
                      <Coin>
                        <FormattedNumber style="percent" value={parseFloat(value.pointsPercent) / 100} />
                      </Coin>
                    </div>
                  </TableBodyCell>

                  <TableBodyCell className="relative whitespace-nowrap">
                    <div className="flex justify-end">
                      <AsyncButton
                        appearance="secondary"
                        className="h-10 px-4"
                        type="button"
                        onClick={(event: MouseEvent) => {
                          event.stopPropagation();

                          return customDealsApi
                            .createCustomDealRestrictions({
                              kind: MinTurnoverRestrictionKindEnum.MinTurnover,
                              values: restrictions.data?.minTurnover.filter((rv) => rv !== value) ?? []
                            })
                            .then(() => restrictions.mutate());
                        }}
                      >
                        <div className="flex items-center">
                          <TrashIcon className="mr-2 h-4 w-4" />
                          <FormattedMessage id={translations.buttons.delete} />
                        </div>
                      </AsyncButton>
                    </div>
                  </TableBodyCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Card>

      <Modal {...createMinTurnoverModal.props} />
    </React.Fragment>
  );
};

export const SettingsPromotionsPage: React.FC = () => {
  const [{ value: config }] = Configuration.useContainer();

  return (
    <div className="flex max-w-3xl flex-col gap-8">
      <PromotionConfiguration />
      {config.features?.has(Feature.CustomDeals) && <CustomDealConfiguration />}
    </div>
  );
};
