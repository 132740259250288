import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import dayjs from 'dayjs';
import * as React from 'react';
import { FormattedDate, useIntl } from 'react-intl';

import { Button } from '../buttons';

interface MonthNavigationProps {
  value: Date;
  min?: Date;
  max?: Date;
  className?: string;
  onChange(value: Date): void;
}

export const MonthNavigation: React.FC<MonthNavigationProps> = ({ value, min, max, className, onChange }) => {
  const selectPrevious = React.useCallback(() => onChange(dayjs(value).subtract(1, 'month').toDate()), [value, onChange]);

  const selectNext = React.useCallback(() => onChange(dayjs(value).add(1, 'month').toDate()), [value, onChange]);

  const hasPrevious = React.useMemo(() => !min || dayjs(value).subtract(1, 'month').endOf('month').valueOf() >= min.getTime(), [value, min]);

  const hasNext = React.useMemo(() => !max || dayjs(value).add(1, 'month').startOf('month').valueOf() <= max.getTime(), [value, max]);

  return (
    <div className={classNames('grid grid-cols-7 items-center gap-2', className)}>
      <Button
        type="button"
        appearance="secondary"
        className={classNames({ 'pointer-events-none invisible': !hasPrevious })}
        disabled={!hasPrevious}
        onClick={selectPrevious}
      >
        <ChevronLeftIcon className="h-5 w-5" />
      </Button>

      <div className="col-span-5 flex items-center justify-center text-sm font-semibold capitalize">
        <FormattedDate year="numeric" month="long" {...{ value }} />
      </div>

      <Button
        type="button"
        appearance="secondary"
        className={classNames({ 'pointer-events-none invisible': !hasNext })}
        disabled={!hasNext}
        onClick={selectNext}
      >
        <ChevronRightIcon className="h-5 w-5" />
      </Button>
    </div>
  );
};

interface Day {
  value: Date;
  hidden?: boolean;
  disabled?: boolean;
  highlighted?: boolean;
}

// (1 = Monday, 7 = Sunday)
const getFirstDayOfWeek = () => {
  if (!window.Intl) return 7;

  const locale: any = new window.Intl.Locale('ro-RO');
  const info = locale.getWeekInfo?.() ?? locale.weekInfo;

  return info.firstDay ?? 7;
};

const getDaysForMonth = (value: Date, min: Date | undefined, max: Date | undefined) => {
  const startOfMonth = dayjs(value).startOf('month');
  const endOfMonth = dayjs(value).endOf('month');

  const firstDayOfWeek = getFirstDayOfWeek();

  // Adjusting the start of the week to Monday (0 = Monday, 6 = Sunday)
  const firstDayOfMonth = startOfMonth.day();
  const firstDayOffset = (firstDayOfMonth - firstDayOfWeek + 7) % 7;

  const startOfDays = startOfMonth.subtract(firstDayOffset, 'day');
  const endOfDays = endOfMonth.add(6 - ((endOfMonth.day() - firstDayOfWeek + 7) % 7), 'day');

  const count = endOfDays.diff(startOfDays, 'day') + 1;

  return Array.from({ length: count }).map<Day>((_, index) => {
    const date = dayjs(startOfDays).add(index, 'day');
    const timestamp = date.valueOf();

    const hidden = timestamp < startOfMonth.valueOf() || timestamp > endOfMonth.valueOf();
    const disabled = (!!min && timestamp < min.getTime()) || (!!max && timestamp > max.getTime());

    return { value: date.toDate(), hidden, disabled };
  });
};

interface MonthViewProps {
  value: Date;
  min?: Date;
  max?: Date;
  className?: string;
  getDay(day: Day): Day;
  onChange(value: Date): void;
  onMouseEnter?(value: Date): void;
  onMouseLeave?(value: Date): void;
}

export const MonthView: React.FC<MonthViewProps> = ({ value, min, max, getDay, className, onChange, onMouseEnter, onMouseLeave }) => {
  const intl = useIntl();

  const days = React.useMemo(() => getDaysForMonth(value, min, max), [value, min, max]);

  return (
    <div className={classNames('grid grid-cols-7 gap-1', className)}>
      {days.slice(0, 7).map(({ value }, index) => (
        <div
          key={index}
          className="flex h-9 w-9 items-center justify-center text-sm font-semibold uppercase text-gray-400"
          aria-label={intl.formatDate(value, { weekday: 'long' })}
        >
          <FormattedDate weekday="narrow" {...{ value }} />
        </div>
      ))}

      {days.map(getDay).map(({ value, disabled, hidden, highlighted }, index) => (
        <Button
          key={index}
          appearance={highlighted ? 'primary' : 'secondary'}
          type="button"
          className={classNames(
            'h-9 w-9 flex-shrink-0',
            {
              'pointer-events-none invisible opacity-0': hidden,
              'pointer-events-none cursor-default text-gray-300': disabled
            },
            className
          )}
          onClick={() => onChange(value)}
          onMouseEnter={() => onMouseEnter?.(value)}
          onMouseLeave={() => onMouseLeave?.(value)}
        >
          <FormattedDate day="numeric" {...{ value }} />
        </Button>
      ))}
    </div>
  );
};

interface Props {
  value?: Date;
  view?: Date;
  min?: Date;
  max?: Date;
  className?: string;
  onChange(value: Date): void;
}

export const DatePicker: React.FC<Props> = ({ value, min, max, view: defaultView, className, onChange }) => {
  const [view, setView] = React.useState(() => defaultView ?? value ?? new Date());

  const timestamp = React.useMemo(() => value?.getTime(), [value]);

  const getDay = React.useCallback(
    (day: Day) => ({
      ...day,
      highlighted: !!timestamp && dayjs(day.value).startOf('day').valueOf() <= timestamp && timestamp <= dayjs(day.value).endOf('day').valueOf()
    }),
    [timestamp]
  );

  return (
    <div className={classNames('relative whitespace-nowrap p-2', className)}>
      <MonthNavigation className="py-2" value={view} min={min} max={max} onChange={setView} />

      <MonthView value={view} {...{ min, max, getDay, onChange }} />
    </div>
  );
};
