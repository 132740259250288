import { translations } from '@binhatch/locale';
import { Segment } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DeletableTag } from '../DeletableTag';
import { Tag } from '../Tag';

interface Props {
  segments: Segment[];
  onDelete?(segment: Segment): void;
}

export const PromotionSegments: React.FC<Props> = ({ segments, onDelete }) => (
  <ul className="flex flex-wrap gap-2">
    {segments.map((segment) => (
      <li key={segment.id}>
        <DeletableTag onDelete={() => onDelete?.(segment)}>{segment.alias}</DeletableTag>
      </li>
    ))}

    {segments.length === 0 && (
      <li>
        <Tag>
          <FormattedMessage id={translations.components.promotionSegments.all} />
        </Tag>
      </li>
    )}
  </ul>
);
