import { useRemoteData } from '@binhatch/hooks';
import { getAllFromApi, getUserName } from '@binhatch/utility';
import { BeneficiaryKind, Promotion } from 'flexinet-api';

import { clientApi, userApi } from '@/integrations/api';
import { urls } from '@/utils/url';

export const useCustomDealBeneficiaries = (promotions?: Promotion[]) => {
  return useRemoteData({ key: 'useCustomDealBeneficiaries', promotions }, async ({ promotions }) => {
    const userIds = promotions
      ?.filter((p) => p.beneficiaryKind === BeneficiaryKind.User)
      .map((p) => p.beneficiaryIds)
      .flat();

    const clientIds = promotions
      ?.filter((p) => p.beneficiaryKind === BeneficiaryKind.Client)
      .map((p) => p.beneficiaryIds)
      .flat();

    const [users, clients] = await Promise.all([
      userIds?.length
        ? await getAllFromApi(
            (nextToken) => userApi.listUsers(nextToken, undefined, undefined, undefined, Array.from(new Set(userIds))).then((r) => r.data),
            (r) => r.users
          )
        : [],
      clientIds?.length
        ? await getAllFromApi(
            (nextToken) => clientApi.listClients(nextToken, undefined, undefined, Array.from(new Set(clientIds))).then((r) => r.data),
            (r) => r.clients
          )
        : []
    ]);

    return promotions?.reduce((map, promotion) => {
      map.set(
        promotion.id,
        promotion.beneficiaryKind === BeneficiaryKind.User
          ? users
              .filter((u) => promotion.beneficiaryIds.includes(u.id))
              .map((u) => ({
                id: u.id,
                name: getUserName(u) || u.details.email,
                url: u.clientId ? urls.clients.getOne({ clientId: u.clientId }, urls.clients.users) : undefined
              }))
          : clients.filter((c) => promotion.beneficiaryIds.includes(c.id)).map(({ id, name }) => ({ id, name, url: urls.clients.getOne({ clientId: id }) }))
      );

      return map;
    }, new Map<string, { id: string; name?: string; url?: string }[]>());
  });
};
