import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  Button,
  ButtonRadio,
  CustomDealItem,
  CustomDealItemContainer,
  CustomDealRule,
  EmptyState,
  LoadingState,
  PageLoaderHeading,
  Pagination,
  PromotionTargetProgress,
  SearchInput
} from '@binhatch/ui';
import { fromPromotion, getCurrentPeriod, getFallbackPeriod, getLastCustomDealClientProgress, getPromotionDays } from '@binhatch/utility';
import { PromotionSortByField, PromotionType, SortDirection } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { promotionApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { Configuration } from '@/containers/useConfiguration';

import { RealtimeToggle } from '@/components/RealtimeToggle';
import { useTags } from '@/hooks/useTags';
import { CustomDealBeneficiaries } from './CustomDealBeneficiaries';
import { CustomDealOwner } from './CustomDealOwner';
import { useCustomDealBeneficiaries } from './useCustomDealBeneficiaries';
import { useCustomDealOwner } from './useCustomDealOwner';
import { useCustomDealProgress } from './useCustomDealProgress';

const statuses = ['active', 'scheduled', 'expired'] as const;
type Status = (typeof statuses)[number];

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const status: QueryParamConfig<Status> = {
  decode: (value) => {
    const v = decodeSingleQueryParam(value, undefined);

    if (v && statuses.includes(v as Status)) return v as Status;

    return 'active';
  },
  encode: (value) => value
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, status, search };

export const CustomDealListPage: React.FC = () => {
  const intl = useIntl();
  const [{ value: configuration }] = Configuration.useContainer();
  const [query, updateQuery] = useQueryParams({ config });

  const promotions = useRemoteData(
    { key: `useCustomDeals`, status: query.status, search: query.search, page: query.page },
    async ({ search, status, page: token }) => {
      const startingAfter = status === 'scheduled' ? new Date().toISOString() : undefined;
      const endingBefore = status === 'expired' ? new Date().toISOString() : undefined;
      const startingBefore = status === 'active' ? new Date().toISOString() : undefined;
      const endingAfter = status === 'active' ? new Date().toISOString() : undefined;

      return await promotionApi
        .listPromotions(
          token,
          undefined,
          undefined,
          undefined,
          PromotionType.CustomDeal,
          startingAfter,
          endingBefore,
          startingBefore,
          endingAfter,
          undefined,
          search,
          PromotionSortByField.CreatedAt,
          SortDirection.Desc
        )
        .then((r) => r.data)
        .then((r) => ({ ...r, promotions: r.promotions.map(fromPromotion) }));
    }
  );

  const tags = useTags();
  const owners = useCustomDealOwner(promotions.data?.promotions);
  const beneficiaries = useCustomDealBeneficiaries(promotions.data?.promotions);

  const progress = useCustomDealProgress({
    promotions: promotions.data?.promotions,
    realtime: configuration?.realtime
  });

  const items = React.useMemo(() => {
    return (
      promotions.data?.promotions.map((promotion) => {
        const period = getCurrentPeriod(promotion.periods ?? []) ?? getFallbackPeriod(promotion.periods ?? []);

        return {
          promotion,
          days: period ? getPromotionDays(period) : 0,
          period,
          progress: promotion.ruleGroups.map((group) => ({
            group,
            progress: getLastCustomDealClientProgress(promotion, period, group.target, progress.data ?? [], (progress) => {
              if (!group.id || !progress.value_detailed?.[group.id]) return 0;
              return parseFloat(progress.value_detailed[group.id]);
            })
          }))
        };
      }) ?? []
    );
  }, [promotions.data, progress.data]);

  return (
    <main className="space-y-6">
      <div className="flex flex-col justify-between gap-2 md:flex-row md:items-center md:gap-4">
        <PageLoaderHeading loading={promotions.isLoading || promotions.isValidating}>
          <FormattedMessage id={translations.pages.customDealList.title} />
        </PageLoaderHeading>

        <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-4">
          <RealtimeToggle />

          <Button appearance="primary" as={Link} className="h-10 px-4" state={{ from: 1 }} to={urls.customDeals.new}>
            <FormattedMessage id={translations.pages.customDealList.create} />
          </Button>
        </div>
      </div>

      <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
        <ButtonRadio
          className="h-10"
          id="period"
          items={statuses.map((value) => ({
            value,
            name: intl.formatMessage({ id: translations.enum.promotionStatus[value] })
          }))}
          name="period"
          type="radio"
          value={query.status}
          onChange={(status: Status) => updateQuery({ page: undefined, status })}
        />

        <div className="flex items-center gap-4">
          <SearchInput
            className="w-full md:w-72"
            placeholder={intl.formatMessage({ id: translations.pages.customDealList.search })}
            value={query.search}
            onChange={(search: string) => updateQuery({ page: undefined, search })}
          />
        </div>
      </div>

      <LoadingState loading={promotions.isLoading || promotions.isValidating}>
        {!promotions.isLoading && !promotions.data?.promotions.length && (
          <EmptyState>
            <FormattedMessage id={translations.pages.customDealList.empty} />
          </EmptyState>
        )}

        <ul className="grid min-h-[7rem] w-full gap-4 rounded-lg xl:grid-cols-2 2xl:grid-cols-3">
          {items.map(({ promotion, days, period, progress }) => (
            <li className="min-w-0" key={promotion.id}>
              <CustomDealItemContainer>
                <div className="flex flex-col justify-between px-4 pt-4 text-sm xl:flex-row xl:gap-4">
                  <CustomDealOwner owner={owners.data?.get(promotion.id)} />

                  <div>
                    <CustomDealBeneficiaries beneficiaries={beneficiaries.data?.get(promotion.id)} type={promotion.beneficiaryKind} />
                  </div>
                </div>

                <Link className="flex flex-col gap-4 p-4" state={{ from: 1 }} to={urls.customDeals.getOne({ promotionId: promotion.id })}>
                  <CustomDealItem {...{ promotion, period, days }} />

                  {progress.map(({ group, progress }, index) => (
                    <PromotionTargetProgress
                      key={index}
                      name={
                        <React.Fragment key={index}>
                          {group.rules.map((rule, index) => (
                            <CustomDealRule {...{ rule }} key={index} tags={tags.data} />
                          ))}
                        </React.Fragment>
                      }
                      {...{ promotion, progress }}
                    />
                  ))}
                </Link>
              </CustomDealItemContainer>
            </li>
          ))}
        </ul>
      </LoadingState>

      <Pagination
        hasNext={!!promotions.data?.nextToken}
        hasPrevious={!!promotions.data?.prevToken}
        onNext={() => updateQuery({ page: promotions.data?.nextToken })}
        onPrevious={() => updateQuery({ page: promotions.data?.prevToken })}
      />
    </main>
  );
};
