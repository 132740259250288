import { useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  Button,
  Card,
  ClientAvatar,
  CoinValue,
  DynamicBackButton,
  LoadingState,
  Modal,
  PageLoaderHeading,
  Skeleton,
  Tab,
  TabList,
  useModal
} from '@binhatch/ui';
import { BeneficiaryKind, Feature, TransactionCurrencyKind, UserRole } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Outlet } from 'react-router';

import { balanceApi, clientApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';

import { useBalances } from '@/hooks/useBalances';

import { RealtimeToggle } from '@/components/RealtimeToggle';
import { Configuration } from '@/containers/useConfiguration';
import { useGroupedBalances } from '@/hooks/useGroupedBalances';
import { AllocateBalanceModal } from '@/modals/AllocateBalanceModal';

export const ClientDetailPage: React.FC = () => {
  const [{ value: config }] = Configuration.useContainer();
  const { context } = Auth.useContainer();

  const { clientId } = useStrictParams<{ clientId: string }>();
  const allocateBalanceModal = useModal(AllocateBalanceModal);

  const client = useRemoteData({ key: `useClient`, clientId, skip: !clientId }, async ({ clientId }) => {
    if (!clientId) return;

    return clientApi.getClient(clientId).then((r) => r.data);
  });

  const balances = useBalances({ ids: clientId ? [clientId] : [], beneficiaryKind: BeneficiaryKind.Client });
  const groupedBalances = useGroupedBalances(balances.data);

  if (!clientId) return <Navigate to={urls.clients.root} />;

  return (
    <React.Fragment>
      <main className="space-y-6">
        <div className="flex flex-col justify-between gap-2 md:flex-row md:items-center md:gap-4">
          <div>
            <DynamicBackButton />

            <PageLoaderHeading className="flex-1" loading={client.isLoading || client.isValidating}>
              <FormattedMessage id={translations.pages.clientList.title} />
            </PageLoaderHeading>
          </div>

          <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-4">
            <RealtimeToggle />

            {context?.user.role === UserRole.SystemAdmin && (
              <Button
                appearance="primary"
                className="h-10 px-4"
                onClick={() =>
                  allocateBalanceModal
                    .open({
                      balance: groupedBalances?.get(clientId)?.client?.amount ?? 0,
                      currency: TransactionCurrencyKind.Points,
                      onUpdate: ({ value, transactionKind, currency, description }) =>
                        balanceApi
                          .updateBalance(clientId, {
                            amount: value,
                            beneficiaryKind: BeneficiaryKind.Client,
                            transactionKind,
                            currency,
                            description
                          })
                          .then(() => void 0)
                    })
                    .then(() => balances.mutate())
                    .catch(() => void 0)
                }
              >
                <FormattedMessage id={translations.pages.clientDetail.updateBalance} />
              </Button>
            )}
          </div>
        </div>

        <LoadingState loading={client.isLoading || client.isValidating}>
          <Card className="flex flex-col gap-4 md:flex-row md:items-center">
            <div className="flex flex-1 items-center gap-4">
              <ClientAvatar className="flex-shrink-0" />

              <div className="min-w-0 flex-1">
                <Skeleton className="mb-1 w-48" size="h-5" visible={!client.data}>
                  <div className="truncate font-semibold">{client.data?.name ?? '???'}</div>
                </Skeleton>

                <Skeleton className="mb-1 w-32 truncate" size="h-5" visible={!client.data}>
                  <div>
                    {client.data?.referenceId ?? '???'}

                    {!!client.data?.isSuspended && (
                      <React.Fragment>
                        {' - '}
                        <span className="text-error">
                          <FormattedMessage id={translations.pages.clientDetail.clientSuspended} />
                        </span>
                      </React.Fragment>
                    )}

                    {!!client.data?.isExcluded && (
                      <React.Fragment>
                        {' - '}
                        <span className="text-error">
                          <FormattedMessage id={translations.pages.clientDetail.clientExcluded} />
                        </span>
                      </React.Fragment>
                    )}
                  </div>
                </Skeleton>
              </div>
            </div>

            <div className="flex flex-col items-end">
              <div>
                <FormattedMessage id={translations.utils.balance} />
              </div>

              <CoinValue value={groupedBalances?.get(clientId)?.client?.amount ?? 0} />
            </div>
          </Card>
        </LoadingState>

        <TabList>
          <Tab to={urls.clients.getOne({ clientId }, urls.clients.promotions)}>
            <FormattedMessage id={translations.tabs.promotions} />
          </Tab>

          {config.features?.has(Feature.CustomDeals) && (
            <Tab to={urls.clients.getOne({ clientId }, urls.clients.customDeals)}>
              <FormattedMessage id={translations.tabs.customDeals} />
            </Tab>
          )}

          <Tab to={urls.clients.getOne({ clientId }, urls.clients.orders)}>
            <FormattedMessage id={translations.tabs.orders} />
          </Tab>

          <Tab to={urls.clients.getOne({ clientId }, urls.clients.transactions)}>
            <FormattedMessage id={translations.tabs.transactions} />
          </Tab>

          <Tab to={urls.clients.getOne({ clientId }, urls.clients.users)}>
            <FormattedMessage id={translations.tabs.users} />
          </Tab>
        </TabList>

        <Outlet context={client.data} />
      </main>

      <Modal {...allocateBalanceModal.props} />
    </React.Fragment>
  );
};
