import { useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { EmptyState, LoadingState } from '@binhatch/ui';
import { BeneficiaryKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { userApi } from '@/integrations/api';

import { useBalances } from '@/hooks/useBalances';

import { useGroupedBalances } from '@/hooks/useGroupedBalances';
import { SalesUserItem } from './components/SalesUserItem';

export const SalesUserSubordinatePage: React.FC = () => {
  const { userId } = useStrictParams<{ userId: string }>();

  const users = useRemoteData({ key: `useSalesUserSubordinates`, userId }, async ({ userId }) => {
    if (!userId) return [];

    return userApi.getUserSubordinates(userId).then((r) => r.data);
  });

  const balances = useBalances({ ids: users.data?.map((u) => u.id), beneficiaryKind: BeneficiaryKind.User });
  const groupedBalances = useGroupedBalances(balances.data);

  return (
    <LoadingState loading={users.isLoading || users.isValidating}>
      {!users.isLoading && !users.data?.length && (
        <EmptyState>
          <FormattedMessage id={translations.pages.userList.empty} />
        </EmptyState>
      )}

      <ul className="flex flex-col gap-4">
        {users.data?.map((user, index) => (
          <li key={index}>
            <SalesUserItem {...{ user }} balance={groupedBalances?.get(user.id)} />
          </li>
        ))}
      </ul>
    </LoadingState>
  );
};
