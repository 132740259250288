import { translations } from '@binhatch/locale';
import { Button, Card, Checkbox, Form, InputWithLabel, LoadingButton, SubmitError, ValidatedField } from '@binhatch/ui';
import { PlusIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import { NotificationChannel, NotificationKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { configurationApi } from '@/integrations/api';

import { Configuration } from '@/containers/useConfiguration';

const schema = yup
  .object({
    emails: yup.array().of(yup.string().email().required().label(translations.fields.email.label)).min(0).required()
  })
  .required();

const ProductNotifications: React.FC = () => {
  const intl = useIntl();
  const [{ value: configuration }, , reload] = Configuration.useContainer();

  const initialValues = React.useMemo(
    () => ({ emails: configuration.notificationEmailConfig?.emails ?? [] }),
    [configuration]
  );

  const onSubmit = React.useCallback(
    async ({ emails }: yup.InferType<typeof schema>) => {
      await configurationApi.createConfiguration({
        notificationEmailConfig: { emails: emails.filter(Boolean) }
      });
      await reload();
    },
    [reload]
  );

  return (
    <Card className="flex items-end gap-4">
      <Form {...{ initialValues, schema, onSubmit }}>
        {({ values, submitting, submitError, handleSubmit, form }) => (
          <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit}>
            <div className="text-sm font-semibold">
              <FormattedMessage id={translations.pages.settings.notifications.lead.title} />
            </div>

            <ul className="grid gap-4">
              {values.emails.map((_, index) => (
                <li className="flex gap-4" key={index}>
                  <ValidatedField
                    className="flex-1"
                    field={InputWithLabel}
                    id={`email-${index + 1}`}
                    label={<FormattedMessage id={translations.fields.email.label} />}
                    name={`emails[${index}]`}
                    placeholder={intl.formatMessage({ id: translations.fields.email.placeholder })}
                    readOnly={submitting}
                    type="text"
                  />

                  <Button
                    appearance="secondary"
                    className="mt-6 h-10 px-4"
                    type="button"
                    onClick={() => {
                      const emails = values.emails.slice();
                      emails.splice(index, 1);
                      form.change('emails', emails.length ? emails : ['']);
                    }}
                  >
                    <TrashIcon className="mr-2 h-4 w-4" />
                    <FormattedMessage id={translations.buttons.delete} />
                  </Button>
                </li>
              ))}

              <li>
                <Button
                  appearance="outline"
                  className="h-10 px-4"
                  type="button"
                  onClick={() => form.change('emails', [...values.emails, ''])}
                >
                  <PlusIcon className="mr-2 h-4 w-4" />
                  <FormattedMessage id={translations.buttons.add} />
                </Button>
              </li>
            </ul>

            <SubmitError error={submitError} />

            <div className="flex justify-end gap-4">
              <LoadingButton appearance="primary" className="h-10 px-4" loading={submitting} type="submit">
                <FormattedMessage id={translations.buttons.save} />
              </LoadingButton>
            </div>
          </form>
        )}
      </Form>
    </Card>
  );
};

const NotificationsChannels: React.FC = () => {
  const [{ value: configuration }, , reload] = Configuration.useContainer();

  return (
    <Card>
      <div className="flex flex-col gap-x-16 gap-y-4 lg:grid-cols-2">
        {[
          NotificationKind.PromotionStart,
          NotificationKind.PromotionHalfTime,
          NotificationKind.PromotionEnd,
          NotificationKind.NewProduct,
          NotificationKind.ExpiryReminder,
          NotificationKind.OrderConfirmation,
          NotificationKind.ProductRequestResponse
        ].map((notification) => (
          <div className="flex items-center justify-between gap-8" key={notification}>
            <div className="font-semibold">
              <FormattedMessage id={translations.enum.notificationKind[notification]} />
            </div>

            <ul className="flex gap-8">
              {[NotificationChannel.Email, NotificationChannel.Sms]
                .map((channel) => ({
                  channel,
                  checked: !!configuration.notificationPreferences?.find(
                    (n) => n.kind === notification && n.channel === channel
                  )?.enabled
                }))
                .map(({ channel, checked }) => (
                  <li key={channel}>
                    <Checkbox
                      id={`notification-${notification}-${channel}`}
                      name={`notification-${notification}-${channel}`}
                      type="checkbox"
                      {...{ checked }}
                      onChange={() =>
                        configurationApi
                          .createConfiguration({
                            notificationPreferences: [{ channel, kind: notification, enabled: !checked }]
                          })
                          .then(() => reload())
                      }
                    >
                      <FormattedMessage id={translations.enum.notificationChannel[channel]} />
                    </Checkbox>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </Card>
  );
};

export const SettingsNotificationsPage: React.FC = () => (
  <div className="flex max-w-3xl flex-col gap-8">
    <ProductNotifications />

    <NotificationsChannels />
  </div>
);
