import { translations } from '@binhatch/locale';
import { XMarkIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Tag } from './Tag';
import { AsyncButton } from './buttons';

interface Props extends React.ComponentProps<typeof Tag> {
  onDelete?(): Promise<void> | void;
}

export const DeletableTag: React.FC<React.PropsWithChildren<Props>> = ({ children, onDelete, ...props }) => (
  <Tag {...props}>
    {children}

    {!!onDelete && (
      <AsyncButton
        className="group relative -mr-1 h-5 w-5 rounded-sm border-transparent hover:bg-gray-500/20 disabled:pointer-events-none"
        type="button"
        onClick={(event: any) => {
          event.stopPropagation();
          onDelete();
        }}
      >
        <span className="sr-only">
          <FormattedMessage id={translations.buttons.delete} />
        </span>

        <XMarkIcon className="h-5 w-5" />
      </AsyncButton>
    )}
  </Tag>
);
