import {
  AccountInfo,
  AuthenticationResult,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  IPublicClientApplication
} from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useRemoteData } from '@binhatch/hooks';
import { updateApiAccessToken } from '@binhatch/utility';
import React, { useLayoutEffect } from 'react';
import { createContainer } from 'unstated-next';

import { userApi } from '@/integrations/api';

const getAccessToken = async (
  instance: IPublicClientApplication,
  { account, scopes }: { account: AccountInfo; scopes: string[] }
) => {
  try {
    return await instance.acquireTokenSilent({ account, scopes });
  } catch (error) {
    if (!(error instanceof InteractionRequiredAuthError)) throw error;

    return instance.acquireTokenRedirect({ account, scopes });
  }
};

const useAuth = () => {
  const { instance, inProgress } = useMsal();

  const [account, setAccount] = React.useState(() => instance.getActiveAccount());
  const [accessToken, setAccessToken] = React.useState<string>();
  const authenticated = useIsAuthenticated();

  useLayoutEffect(() => {
    const scopes = [`api://${instance.getConfiguration().auth.clientId}/access_as_user`];

    const id = instance.addEventCallback((message) => {
      switch (message.eventType) {
        case EventType.ACTIVE_ACCOUNT_CHANGED:
          return setAccount(message.payload as AccountInfo);
        case EventType.HANDLE_REDIRECT_END: {
          if (!account) return instance.loginRedirect({ scopes });

          return getAccessToken(instance, { account, scopes });
        }
        case EventType.ACQUIRE_TOKEN_SUCCESS: {
          const accessToken = (message.payload as AuthenticationResult)?.accessToken;

          updateApiAccessToken(accessToken, async () => {
            const result = await instance.acquireTokenSilent({ scopes, forceRefresh: true });

            setAccessToken(result.accessToken);

            return result.accessToken;
          });

          return setAccessToken(accessToken);
        }
      }
    });

    return () => instance.removeEventCallback(id ?? '');
  }, [instance, account]);

  const profile = useRemoteData({ key: 'getProfile', skip: !authenticated || !accessToken, accessToken }, () =>
    userApi.getCurrentUser().then((r) => r.data)
  );

  const loading =
    ([InteractionStatus.Startup, InteractionStatus.HandleRedirect] as InteractionStatus[]).includes(inProgress) ||
    (authenticated && !accessToken) ||
    profile.isLoading;

  const context = React.useMemo(() => {
    const user = profile.data?.user;

    if (!user) return;

    return { user };
  }, [profile]);

  return React.useMemo(
    () => ({
      authenticated,
      loading,
      context,
      logout: () => instance.logoutRedirect({ account: instance.getActiveAccount() })
    }),
    [authenticated, loading, instance, context]
  );
};

export const Auth = createContainer(useAuth);
