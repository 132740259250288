import { useAsyncFn } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Card, FileInput, LoadingButton, SubmitError } from '@binhatch/ui';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline';
import { ClientUpdateRequest } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import template from '@/assets/promotion-clients.csv';

import { bulkApi } from '@/integrations/api';
import { getItemsFromCsv } from '@/utils/csv';

export const SettingsClientsPage: React.FC = () => {
  const [{ value, loading, error }, create] = useAsyncFn(async (files: File[]) => {
    const truthyValues: (string | number | undefined)[] = ['true', 'TRUE', '1', 1];

    const clients = await files.reduce<Promise<ClientUpdateRequest[]>>(async (promise, file) => {
      const items = await promise;

      const newItems = await getItemsFromCsv(file, {
        referenceId: (v) => v?.trim() ?? '',
        isExcluded: (v) => truthyValues.includes(v?.trim())
      });

      return [...items, ...newItems.filter(({ referenceId }) => !!referenceId.length)];
    }, Promise.resolve([]));

    const items = await bulkApi.bulkUpdateClients({ clients }).then((r) => r.data);

    const updated = items.filter((c) => c.id);
    const missed = items.filter((c) => !c.id);

    return { updated, missed };
  });

  return (
    <div className="flex max-w-3xl flex-col gap-8">
      <Card className="flex flex-col gap-4">
        <div className="text-sm font-semibold">
          <FormattedMessage id={translations.pages.settings.clients.title} />
        </div>

        <div className="text-sm">
          <FormattedMessage
            id={translations.pages.settings.clients.template}
            values={{
              a: (children) => (
                <a className="underline" download href={template}>
                  {children}
                </a>
              )
            }}
          />
        </div>

        <div className="m-0 grid gap-4">
          <FileInput
            accept="text/plain,text/csv,application/vnd.ms-excel,text/x-csv"
            className="cursor-pointer"
            disabled={loading}
            onChange={(files) => create(files).catch(() => void 0)}
          >
            <LoadingButton as="div" className="h-10 px-4" {...{ loading }}>
              <div className="flex items-center gap-2">
                <ArrowUpOnSquareIcon className="h-5 w-5" />
                <FormattedMessage id={translations.buttons.uploadCsv} />
              </div>
            </LoadingButton>
          </FileInput>

          <SubmitError {...{ error }} />

          {!!value && value.updated.length + value.missed.length > 0 && (
            <div className="text-sm">
              {value.updated.length > 0 && (
                <div>
                  <FormattedMessage id={translations.pages.settings.clients.updated} values={{ count: value.updated.length }} />
                </div>
              )}

              {value.missed.length > 0 && (
                <div className="text-error">
                  <div>
                    <FormattedMessage id={translations.pages.settings.clients.missed} values={{ count: value.missed.length }} />
                  </div>

                  <ul>
                    {value.missed.map((c, index) => (
                      <li key={index}>{c.referenceId}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
