import { translations } from '@binhatch/locale';
import {
  Button,
  ButtonRadio,
  EmptyState,
  LoadingState,
  PageLoaderHeading,
  Pagination,
  PromotionItem,
  PromotionItemContainer,
  PromotionProgress,
  SearchInput
} from '@binhatch/ui';
import { fromPromotion, getCurrentPeriod, getFallbackPeriod, getPromotionDays } from '@binhatch/utility';
import { MetricsCollection, PromotionSortByField, PromotionType, SortDirection, UserRole } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { promotionApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';
import { Configuration } from '@/containers/useConfiguration';

import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData } from '@binhatch/hooks';

import { RealtimeToggle } from '@/components/RealtimeToggle';

const statuses = ['active', 'scheduled', 'expired'] as const;
type Status = (typeof statuses)[number];

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const status: QueryParamConfig<Status> = {
  decode: (value) => {
    const v = decodeSingleQueryParam(value, undefined);

    if (v && statuses.includes(v as Status)) return v as Status;

    return 'active';
  },
  encode: (value) => value
};

const search: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => (value ? value : undefined)
};

const config = { page, status, search };

export const PromotionListPage: React.FC = () => {
  const intl = useIntl();
  const { context } = Auth.useContainer();
  const [{ value: configuration }] = Configuration.useContainer();
  const [query, updateQuery] = useQueryParams({ config });

  const availableStatuses = React.useMemo<Status[]>(() => {
    return context?.user.role === UserRole.SystemAdmin ? ['active', 'scheduled', 'expired'] : ['active', 'expired'];
  }, [context?.user.role]);

  const promotions = useRemoteData(
    { key: `usePromotions`, status: query.status, search: query.search, page: query.page },
    async ({ search, status, page: token }) => {
      const startingAfter = status === 'scheduled' ? new Date().toISOString() : undefined;
      const endingBefore = status === 'expired' ? new Date().toISOString() : undefined;
      const startingBefore = status === 'active' ? new Date().toISOString() : undefined;
      const endingAfter = status === 'active' ? new Date().toISOString() : undefined;

      return await promotionApi
        .listPromotions(
          token,
          undefined,
          undefined,
          undefined,
          PromotionType.Regular,
          startingAfter,
          endingBefore,
          startingBefore,
          endingAfter,
          undefined,
          search,
          PromotionSortByField.CreatedAt,
          SortDirection.Desc
        )
        .then((r) => r.data)
        .then((r) => ({ ...r, promotions: r.promotions.map(fromPromotion) }));
    }
  );

  const items = React.useMemo(() => {
    return (
      promotions.data?.promotions.map((promotion) => {
        const period = getCurrentPeriod(promotion.periods ?? []) ?? getFallbackPeriod(promotion.periods ?? []);
        const metricKey: keyof MetricsCollection = configuration?.realtime ? 'total' : 'active';

        return {
          promotion,
          days: period ? getPromotionDays(period) : 0,
          period,
          metrics: period ? promotion.metrics?.perPeriod?.[period.id]?.[metricKey] : undefined
        };
      }) ?? []
    );
  }, [promotions.data, configuration?.realtime]);

  return (
    <main className="space-y-6">
      <div className="flex flex-col justify-between gap-2 md:flex-row md:items-center md:gap-4">
        <PageLoaderHeading loading={promotions.isLoading || promotions.isValidating}>
          <FormattedMessage id={translations.pages.promotionList.title} />
        </PageLoaderHeading>

        <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-4">
          <RealtimeToggle />

          {context?.user.role === UserRole.SystemAdmin && (
            <Button appearance="primary" as={Link} className="h-10 px-4" state={{ from: 1 }} to={urls.promotions.new}>
              <FormattedMessage id={translations.pages.promotionList.create} />
            </Button>
          )}
        </div>
      </div>

      <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
        <ButtonRadio
          className="h-10"
          id="period"
          items={availableStatuses.map((value) => ({
            value,
            name: intl.formatMessage({ id: translations.enum.promotionStatus[value] })
          }))}
          name="period"
          type="radio"
          value={query.status}
          onChange={(status: Status) => updateQuery({ page: undefined, status })}
        />

        <div className="flex items-center gap-4">
          <SearchInput
            className="w-full md:w-72"
            placeholder={intl.formatMessage({ id: translations.pages.promotionList.search })}
            value={query.search}
            onChange={(search: string) => updateQuery({ page: undefined, search })}
          />
        </div>
      </div>

      <LoadingState loading={promotions.isLoading || promotions.isValidating}>
        {!promotions.isLoading && !promotions.data?.promotions.length && (
          <EmptyState>
            <FormattedMessage id={translations.pages.promotionList.empty} />
          </EmptyState>
        )}

        <ul className="grid min-h-[7rem] w-full gap-4 rounded-lg xl:grid-cols-2 2xl:grid-cols-3">
          {items.map(({ promotion, days, period, metrics }) => (
            <li className="min-w-0" key={promotion.id}>
              <Link className="flex items-stretch" state={{ from: 1 }} to={urls.promotions.getOne({ promotionId: promotion.id })}>
                <PromotionItemContainer className="space-y-4 p-4">
                  <PromotionItem {...{ promotion, period, days }} />

                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-4">
                      <div>
                        <FormattedMessage id={translations.pages.promotionList.participation} />
                      </div>

                      <div>
                        <FormattedNumber style="percent" value={(metrics?.participationCount ?? 0) / Math.max(metrics?.clientCount ?? 0, 1)} />
                      </div>

                      <div>
                        {metrics?.participationCount ?? 0} / {metrics?.clientCount ?? 0}
                      </div>
                    </div>

                    <PromotionProgress {...{ metrics }} />
                  </div>
                </PromotionItemContainer>
              </Link>
            </li>
          ))}
        </ul>
      </LoadingState>

      <Pagination
        hasNext={!!promotions.data?.nextToken}
        hasPrevious={!!promotions.data?.prevToken}
        onNext={() => updateQuery({ page: promotions.data?.nextToken })}
        onPrevious={() => updateQuery({ page: promotions.data?.prevToken })}
      />
    </main>
  );
};
