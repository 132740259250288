import { translations } from '@binhatch/locale';
import {
  AsyncSelect,
  Button,
  CoinValue,
  DynamicBackButton,
  Form,
  InputWithLabel,
  Item,
  LoadingButton,
  LoadingState,
  PageLoaderHeading,
  ProductImage,
  SelectItem,
  SubmitError,
  ValidatedField
} from '@binhatch/ui';
import { getAllFromApi } from '@binhatch/utility';
import { CheckIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import { BeneficiaryKind, Client } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Navigate } from 'react-router-dom';
import * as yup from 'yup';

import { urls } from '@/utils/url';

import { Cart } from '@/containers/useCart';

import { useBalance } from '@/hooks/useBalance';
import { clientApi, orderApi } from '@/integrations/api';

interface BeneficiarySelectItem extends Item<string> {
  description?: string;
}

const mapClient = (client: Client): BeneficiarySelectItem => ({
  value: client.id,
  name: client.name,
  description: client.referenceId
});

export const CartPage: React.FC = () => {
  const intl = useIntl();

  const cart = Cart.useContainer();
  const balance = useBalance();

  const balanceId = balance.data?.salesBudget?.id;

  const schema = React.useMemo(() => {
    const beneficiary = yup.string().label(translations.enum.beneficiaryKind.client);

    return yup.object({ beneficiary: cart.total >= 100 ? beneficiary.required() : beneficiary }).required();
  }, [cart.total]);

  const onSubmit = React.useCallback(
    async ({ beneficiary }: yup.InferType<typeof schema>) => {
      if (!balanceId) throw new Error(`Balance does not exist.`);

      const items = cart.items.map((item) => ({ qty: item.quantity, productId: item.product.id }));

      await orderApi.createAdminOrder({
        items,
        beneficiary: beneficiary ? { kind: BeneficiaryKind.Client, value: beneficiary } : undefined
      });

      await cart.clear();

      balance.mutate().catch(() => void 0);
    },
    [balanceId, balance, cart]
  );

  return (
    <main className="flex flex-1 flex-col space-y-6">
      <Form {...{ schema, onSubmit }}>
        {({ submitting, submitSucceeded, submitError, handleSubmit }) => (
          <form className="flex w-full flex-1 flex-col gap-4" onSubmit={handleSubmit}>
            {!cart.loading && cart.items.length === 0 && !submitSucceeded && <Navigate to={urls.shop.root} />}

            {!submitSucceeded ? (
              <React.Fragment>
                <div>
                  <DynamicBackButton />

                  <PageLoaderHeading loading={cart.loading}>
                    <FormattedMessage id={translations.pages.cart.title} />
                  </PageLoaderHeading>
                </div>

                <div className="flex flex-col gap-8 rounded-lg bg-white p-8">
                  <ValidatedField
                    className="w-full"
                    field={InputWithLabel}
                    getItemsByIds={(ids: string[]) => {
                      if (!ids.length) return [];

                      return getAllFromApi(
                        (nextToken) => clientApi.listClients(nextToken, undefined, undefined, ids).then((r) => r.data),
                        (r) => r.clients.map(mapClient)
                      );
                    }}
                    getItemsBySearch={(search: string) => {
                      if (search.length < 2) return [];

                      return search.length > 3
                        ? getAllFromApi(
                            (nextToken) => clientApi.listClients(nextToken, search).then((r) => r.data),
                            (r) => r.clients.map(mapClient)
                          )
                        : clientApi.listClients(undefined, search).then((r) => r.data.clients.map(mapClient));
                    }}
                    id="beneficiary"
                    input={AsyncSelect}
                    label={<FormattedMessage id={translations.enum.beneficiaryKind.client} />}
                    name="beneficiary"
                    placeholder={intl.formatMessage({ id: translations.buttons.select })}
                    // readOnly={!!submitting}
                    selectItem={({ item, getDisplayName, ...props }: any) => (
                      <SelectItem {...props}>
                        <div className="font-medium">{item.name}</div>
                        <div>{item.description}</div>
                      </SelectItem>
                    )}
                  />

                  <LoadingState loading={cart.loading}>
                    <ul className="grid gap-4">
                      {cart.items.map(({ product, quantity }) => (
                        <li
                          className="relative flex flex-col justify-between gap-4 overflow-hidden md:flex-row md:items-center"
                          key={product.id}
                        >
                          <div className="flex items-center gap-4">
                            <ProductImage className="w-16 flex-shrink-0" square src={product.media[0]} />

                            <div className="space-y-2">
                              <div className="font-semibold">{product.name}</div>

                              <Button appearance="secondary" type="button" onClick={() => cart.update(product, 0)}>
                                <TrashIcon className="mr-2 h-4 w-4 stroke-2" />
                                <FormattedMessage id={translations.buttons.delete} />
                              </Button>
                            </div>
                          </div>

                          <div className="flex items-center justify-end whitespace-nowrap">
                            <div className="">{quantity} &times;&nbsp;</div>

                            <CoinValue value={product.value} />
                          </div>
                        </li>
                      ))}
                    </ul>

                    <div className="border-shade mt-4 flex items-center justify-end gap-4 border-t pt-4 font-semibold">
                      <FormattedMessage id={translations.pages.shop.cart.total} />

                      <CoinValue value={cart.total} />
                    </div>
                  </LoadingState>
                </div>

                <SubmitError error={submitError} />

                <div className="flex sm:justify-end">
                  <LoadingButton
                    appearance="primary"
                    className="h-14 px-4"
                    disabled={!balance.data}
                    loading={submitting}
                    type="submit"
                  >
                    <FormattedMessage id={translations.pages.order.purchase} />
                  </LoadingButton>
                </div>
              </React.Fragment>
            ) : (
              <div className="flex flex-1 flex-col items-center justify-center gap-4 text-center">
                <div className="bg-brand flex h-20 w-20 items-center justify-center rounded-full text-white md:h-36 md:w-36">
                  <CheckIcon className="h-10 w-10 md:h-20 md:w-20" />
                </div>

                <div className="text-xl font-semibold">
                  <FormattedMessage id={translations.pages.order.purchased.title} />
                </div>

                <div>
                  <FormattedMessage id={translations.pages.order.purchased.message} />
                </div>

                <Button appearance="secondary" as={Link} className="h-14 px-4" state={{ from: 1 }} to={urls.shop.root}>
                  <FormattedMessage id={translations.pages.order.back} />
                </Button>
              </div>
            )}
          </form>
        )}
      </Form>
    </main>
  );
};
