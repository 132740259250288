import { translations } from '@binhatch/locale';
import classNames from 'classnames';
import { Metrics, ProgressInterval } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProgressBarContainer } from '../ProgressBar';

const steps = [
  [ProgressInterval._501, 'bg-error'],
  [ProgressInterval._7550, 'bg-brand'],
  [ProgressInterval._9975, 'bg-warning-light'],
  [ProgressInterval._100, 'bg-success']
] as const;

interface Props {
  metrics?: Metrics;
}

export const PromotionProgress: React.FC<Props> = ({ metrics }) => {
  const total = metrics?.participationCount || 1;

  return (
    <ProgressBarContainer color="bg-error">
      {steps.map(([value, color]) => (
        <div
          className={classNames('h-full', color)}
          key={value}
          style={{ width: `${((metrics?.participationCountPerProgress?.[value] ?? 0) / total) * 100}%` }}
        />
      ))}
    </ProgressBarContainer>
  );
};

export const PromotionLabels: React.FC = () => (
  <div className="flex flex-wrap gap-x-8 gap-y-2">
    {steps.map(([value, color]) => (
      <div className="flex items-center gap-2 text-sm leading-5" key={value}>
        <div className={classNames('h-2 w-5 rounded', color)} />
        <FormattedMessage id={translations.components.promotionProgress[value]} />
      </div>
    ))}
  </div>
);
