import { useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Button, Card, CoinValue, DynamicBackButton, LoadingState, Modal, Tab, TabList, useModal } from '@binhatch/ui';
import { BeneficiaryKind, Feature, TransactionCurrencyKind, UserRole } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Outlet } from 'react-router';

import { balanceApi, userApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';
import { useBalances } from '@/hooks/useBalances';

import { useGroupedBalances } from '@/hooks/useGroupedBalances';
import { AllocateBalanceModal } from '@/modals/AllocateBalanceModal';
import { TransferBalanceModal } from '@/modals/TransferBalanceModal';

import { Configuration } from '@/containers/useConfiguration';
import { useBalance } from '@/hooks/useBalance';
import { SalesUser } from './components/SalesUserItem';

export const SalesUserDetailPage: React.FC = () => {
  const { context } = Auth.useContainer();
  const [{ value: config }] = Configuration.useContainer();
  const ownerBalance = useBalance();

  const { userId } = useStrictParams<{ userId: string }>();
  const allocateBalanceModal = useModal(AllocateBalanceModal);
  const transferBalanceModal = useModal(TransferBalanceModal);

  const user = useRemoteData({ key: `useSalesUser`, userId, skip: !userId }, async ({ userId }) => {
    if (!userId) return;

    return userApi.getUserByID(userId).then((r) => r.data);
  });

  const balances = useBalances({ ids: userId ? [userId] : [], beneficiaryKind: BeneficiaryKind.User });
  const groupedBalances = useGroupedBalances(balances.data);

  const balance = user.data?.id ? groupedBalances?.get(user.data.id) : undefined;

  if (!userId) return <Navigate to={urls.sales.root} />;

  return (
    <React.Fragment>
      <main className="space-y-6">
        <div className="flex items-center justify-between gap-4">
          <DynamicBackButton />
        </div>

        <LoadingState loading={user.isLoading || user.isValidating}>
          <Card className="flex flex-col flex-wrap gap-4 md:flex-row">
            <div className="flex flex-1 flex-wrap gap-4">
              <SalesUser user={user.data} />
            </div>

            <div className="flex flex-col gap-2">
              {[
                ...(config.features?.has(Feature.CustomDeals) ? [TransactionCurrencyKind.CustomDealBudget] : []),
                ...(config.features?.has(Feature.SalesBudget) ? [TransactionCurrencyKind.SalesBudget] : [])
              ].map((currency) => (
                <div className="flex flex-col whitespace-nowrap sm:flex-row sm:items-center sm:gap-4" key={currency}>
                  <div className="flex flex-1 items-center gap-4">
                    <FormattedMessage id={translations.enum.transactionCurrencyKind[currency]} />

                    <div className="flex flex-1 justify-end">
                      <CoinValue value={balance?.[currency]?.amount ?? 0} />
                    </div>
                  </div>

                  {!!context && (
                    <div className="">
                      <Button
                        appearance="transparent"
                        className="w-auto"
                        onClick={() => {
                          if (context.user.role === UserRole.SystemAdmin)
                            return allocateBalanceModal
                              .open({
                                balance: groupedBalances?.get(userId)?.[currency]?.amount ?? 0,
                                currency,
                                onUpdate: ({ value: amount, transactionKind, currency, description }) =>
                                  balanceApi
                                    .updateBalance(userId, {
                                      amount,
                                      beneficiaryKind: BeneficiaryKind.User,
                                      transactionKind,
                                      currency,
                                      description
                                    })
                                    .then(() => void 0)
                              })
                              .then(() => Promise.all([balances.mutate(), ownerBalance.mutate()]))
                              .catch(() => void 0);

                          return transferBalanceModal
                            .open({
                              balance: ownerBalance.data?.[currency]?.amount ?? 0,
                              currency,
                              onUpdate: ({ value: amount, transactionKind, currency, description }) =>
                                balanceApi
                                  .transferBalance(userId, {
                                    amount,
                                    beneficiaryKind: BeneficiaryKind.User,
                                    transactionKind,
                                    currency,
                                    description
                                  })
                                  .then(() => void 0)
                            })
                            .then(() => Promise.all([balances.mutate(), ownerBalance.mutate()]))
                            .catch(() => void 0);
                        }}
                      >
                        <FormattedMessage
                          id={
                            context.user.role === UserRole.SystemAdmin ? translations.modals.allocateBalance.title : translations.modals.transferBalance.title
                          }
                          values={{ currency }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Card>
        </LoadingState>

        <TabList>
          <Tab to={urls.sales.getOne({ userId }, urls.sales.subordinates)}>
            <FormattedMessage id={translations.tabs.userSubordinates} />
          </Tab>

          {config.features?.has(Feature.CustomDeals) && (
            <Tab to={urls.sales.getOne({ userId }, urls.sales.customDeals)}>
              <FormattedMessage id={translations.tabs.customDeals} />
            </Tab>
          )}

          <Tab to={urls.sales.getOne({ userId }, urls.sales.transactions)}>
            <FormattedMessage id={translations.tabs.budgetTransactions} />
          </Tab>

          <Tab to={urls.sales.getOne({ userId }, urls.sales.clients)}>
            <FormattedMessage id={translations.tabs.clients} />
          </Tab>
        </TabList>

        <Outlet />
      </main>

      <Modal {...allocateBalanceModal.props} />
      <Modal {...transferBalanceModal.props} />
    </React.Fragment>
  );
};
