import { translations } from '@binhatch/locale';
import { UserAvatar } from '@binhatch/ui';
import { getUserName } from '@binhatch/utility';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import { User } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  user?: User;
}

export const ClientUserItem: React.FC<React.PropsWithChildren<Props>> = ({ user, children }) => (
  <div className="flex flex-col gap-4 rounded bg-white p-8 shadow md:flex-row md:items-center">
    <div className="flex flex-1 items-center gap-4">
      <div className="relative flex-shrink-0">
        <UserAvatar />
        <BriefcaseIcon className="bg-shade absolute bottom-2 right-2 h-4 w-4 rounded-full stroke-2" />
      </div>

      <div className="flex-1">
        <div className="truncate font-semibold">{user ? getUserName(user) || user.details.email : '???'}</div>
        <div className="truncate">{!!user?.details && <FormattedMessage id={translations.enum.userRole[user.role]} />}</div>
      </div>
    </div>

    <div className="flex flex-col items-end">{children}</div>
  </div>
);
