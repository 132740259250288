import axios, { AxiosError } from 'axios';

export interface ApiErrorResult {
  code: number;
  key: ApiErrorCode;
  message?: string;
}

export enum ApiErrorCode {
  InvalidRequest = 'invalid_request',
  ExpiredToken = 'token_expired',
  RestrictionNotFound = 'restriction_not_found'
}

export const isApiError = (error: unknown): error is AxiosError<ApiErrorResult> => {
  return axios.isAxiosError(error) && !!(error.response?.data as ApiErrorResult)?.['key'];
};

interface ApiErrorHandler<T> {
  (result: ApiErrorResult): T;
}

interface ApiErrorHandlerOptions<T> {
  status?: Record<number, ApiErrorHandler<T>>;
  code?: { [x in ApiErrorCode]?: ApiErrorHandler<T> };
  fallback?: ApiErrorHandler<T>;
}

export const handleApiError = <T>(error: Error, handler: ApiErrorHandlerOptions<T>) => {
  if (!isApiError(error)) throw error;

  const response = error.response!;
  const result = response.data;

  const codeHandler = handler.code?.[result.key];

  if (codeHandler) return codeHandler(result);
  if (handler.status?.[response.status]) return handler.status[response.status](result);
  if (handler.fallback) return handler.fallback(result);

  throw error;
};
