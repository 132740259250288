import { translations } from '@binhatch/locale';
import {
  Form,
  InputWithLabel,
  InstanceProps,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  Select,
  SubmitError,
  ValidatedField
} from '@binhatch/ui';
import classnames from 'classnames';
import { WebhookKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { integrationApi } from '@/integrations/api';

interface Props extends InstanceProps<void> {}

const schema = yup
  .object({
    kind: yup.mixed<WebhookKind>().required().label(translations.fields.webhookType.label),
    url: yup.string().url().required().label(translations.fields.webhookUrl.label)
  })
  .required();

export const CreateWebhookModal = React.forwardRef<HTMLDivElement, Props>(
  ({ initialFocus, className, onAction, onClose }, ref) => {
    const intl = useIntl();

    const initialValues = React.useMemo(() => ({ kind: WebhookKind.Order, url: undefined as unknown as string }), []);

    const onSubmit = React.useCallback(
      async ({ kind, url }: yup.InferType<typeof schema>) => {
        await integrationApi.setWebhook({ kind, url });
        onAction();
      },
      [onAction]
    );

    return (
      <div {...{ ref }} className={classnames(className, 'max-w-md')}>
        <ModalLayout>
          <ModalHeader {...{ onClose }}>
            <FormattedMessage id={translations.modals.createWebhook.title} />
          </ModalHeader>

          <Form {...{ schema, initialValues, onSubmit }}>
            {({ submitting, submitError, handleSubmit }) => (
              <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                <ValidatedField
                  field={InputWithLabel}
                  id="type"
                  input={Select}
                  items={[WebhookKind.Order].map((value) => ({
                    value,
                    name: intl.formatMessage({ id: translations.enum.webhookKind[value] })
                  }))}
                  label={<FormattedMessage id={translations.fields.webhookType.label} />}
                  name="kind"
                  placeholder={intl.formatMessage({ id: translations.fields.webhookType.placeholder })}
                  readOnly={submitting}
                  type="text"
                />

                <ValidatedField
                  field={InputWithLabel}
                  id="url"
                  label={<FormattedMessage id={translations.fields.webhookUrl.label} />}
                  name="url"
                  placeholder={intl.formatMessage({ id: translations.fields.webhookUrl.placeholder })}
                  readOnly={submitting}
                  type="text"
                />

                <SubmitError error={submitError} />

                <div className="flex flex-row-reverse space-x-2">
                  <ModalPrimaryButton ref={initialFocus} onAction={() => handleSubmit()}>
                    <FormattedMessage id={translations.buttons.save} />
                  </ModalPrimaryButton>

                  <ModalSecondaryButton {...{ onClose }}>
                    <FormattedMessage id={translations.buttons.back} />
                  </ModalSecondaryButton>
                </div>
              </form>
            )}
          </Form>
        </ModalLayout>
      </div>
    );
  }
);
