import { QueryParamConfig, decodeSingleQueryParam, useQueryParams, useRemoteData, useStrictParams } from '@binhatch/hooks';
import { Pagination, TransactionList } from '@binhatch/ui';
import React from 'react';

import { transactionApi } from '@/integrations/api';
import { transactionUrlMapper } from '@/utils/transactionMapper';

const page: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const config = { page };

export const ClientTransactionPage: React.FC = () => {
  const { clientId } = useStrictParams<{ clientId: string }>();
  const [query, updateQuery] = useQueryParams({ config });

  const transactions = useRemoteData({ key: `useTransactions`, clientId, nextToken: query.page }, async ({ clientId, nextToken }) => {
    if (!clientId) return;

    return transactionApi.listTransactions(nextToken, [clientId]).then((r) => r.data);
  });

  return (
    <div className="space-y-6">
      <TransactionList
        loading={transactions.isLoading || transactions.isValidating}
        transactions={transactions.data?.transactions}
        urlMapper={transactionUrlMapper}
      />

      <Pagination
        hasNext={!!transactions.data?.nextToken}
        hasPrevious={!!transactions.data?.prevToken}
        onNext={() => updateQuery({ page: transactions.data?.nextToken })}
        onPrevious={() => updateQuery({ page: transactions.data?.prevToken })}
      />
    </div>
  );
};
