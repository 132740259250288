import { translations } from '@binhatch/locale';
import { getUserName } from '@binhatch/utility';
import classNames from 'classnames';
import {
  BalanceWithBeneficiaryRef,
  BeneficiaryKind,
  Client,
  Order,
  PeriodPromotion,
  Transaction,
  TransactionKind,
  TransactionSource,
  TransactionSourceReference,
  User
} from 'flexinet-api';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Card } from '../Card';
import { CoinValue } from '../CoinValue';
import { EmptyState } from '../EmptyState';
import { LoadingState } from '../LoadingState';

export type TransactionUrlMapper = {
  [x in TransactionSource]?: (id: string) => string;
};

interface TransactionLinkWrapperProps {
  to?: string;
}

const TransactionLinkWrapper: React.FC<React.PropsWithChildren<TransactionLinkWrapperProps>> = ({ to, ...props }) => (
  <span>{to ? <Link {...props} state={{ from: 1 }} {...{ to }} className="text-brand font-semibold" /> : <span {...props} />}</span>
);

interface TransactionLinkProps extends TransactionLinkWrapperProps {
  source: TransactionSource;
  reference?: TransactionSourceReference;
}

interface Props {
  loading?: boolean;
  transactions?: Transaction[];
  urlMapper: TransactionUrlMapper;
}

const TransactionLink: React.FC<TransactionLinkProps> = ({ source, reference, to }) => {
  if (!reference) return null;

  switch (source) {
    case TransactionSource.SalesEvent:
    case TransactionSource.Promotion: {
      const { promotion, period } = getSourceReference(source, reference) ?? {};

      return (
        <FormattedMessage
          id={translations.components.transactionList.link.promotion}
          values={{
            name: promotion?.name,
            start: new Date(period?.startAt ?? Date.now()),
            end: new Date(period?.endAt ?? Date.now()),
            a: (children) => <TransactionLinkWrapper {...{ to }}>{children}</TransactionLinkWrapper>
          }}
        />
      );
    }
    case TransactionSource.SalesBudget:
    case TransactionSource.Webshop: {
      const order = getSourceReference(source, reference);

      return (
        <FormattedMessage
          id={translations.components.transactionList.link.order}
          values={{
            id: order?.id,
            a: (children) => <TransactionLinkWrapper {...{ to }}>{children}</TransactionLinkWrapper>
          }}
        />
      );
    }
    case TransactionSource.Manual: {
      const user = getSourceReference(source, reference);

      return (
        <FormattedMessage
          id={translations.components.transactionList.link.user}
          values={{
            name: user ? getUserName(user) || user.details.email : undefined,
            a: (children) => <TransactionLinkWrapper {...{ to }}>{children}</TransactionLinkWrapper>
          }}
        />
      );
    }
    case TransactionSource.Transfer: {
      const beneficiary = getSourceReference(source, reference);
      const type = beneficiary?.beneficiaryRef.kind ?? BeneficiaryKind.User;

      const getUserNameWithFallback = (user: User) => getUserName(user) || user.details.email;

      const name = beneficiary
        ? type === BeneficiaryKind.Client
          ? (beneficiary.beneficiaryRef.reference as Client).name
          : getUserNameWithFallback(beneficiary.beneficiaryRef.reference as User)
        : undefined;

      return (
        <FormattedMessage
          id={translations.components.transactionList.link.transfer}
          values={{
            name,
            type,
            a: (children) => <TransactionLinkWrapper {...{ to }}>{children}</TransactionLinkWrapper>
          }}
        />
      );
    }
  }

  return null;
};

interface ReferenceMap {
  [TransactionSource.SalesEvent]: PeriodPromotion;
  [TransactionSource.Promotion]: PeriodPromotion;
  [TransactionSource.Webshop]: Order;
  [TransactionSource.Manual]: User;
  [TransactionSource.Transfer]: BalanceWithBeneficiaryRef;
  [TransactionSource.Expiry]: undefined;
  [TransactionSource.SalesBudget]: Order;
}

const getSourceReference = <S extends TransactionSource>(source: S, reference?: TransactionSourceReference): ReferenceMap[S] | undefined => {
  return reference as ReferenceMap[S];
};

const getSourceReferenceId = (source: TransactionSource, reference?: TransactionSourceReference) => {
  switch (source) {
    case TransactionSource.SalesEvent:
    case TransactionSource.Promotion:
      return getSourceReference(source, reference)?.promotion.id;
    case TransactionSource.Webshop:
    case TransactionSource.Manual:
    case TransactionSource.Transfer:
    case TransactionSource.SalesBudget:
      return getSourceReference(source, reference)?.id;
  }
};

export const TransactionList: React.FC<Props> = ({ loading = false, transactions, urlMapper }) => (
  <LoadingState {...{ loading }}>
    {!loading && !transactions?.length && (
      <EmptyState>
        <FormattedMessage id={translations.components.transactionList.empty} />
      </EmptyState>
    )}

    <ul className="flex flex-col gap-4">
      {transactions?.map((transaction, index) => (
        <li key={index}>
          <Card className="flex flex-wrap items-center justify-between gap-2">
            <div className="w-full md:w-auto">
              <div>
                <FormattedMessage
                  id={translations.components.transactionList.source[transaction.source] ?? translations.components.transactionList.source.unknown}
                />
                <span> </span>
                <TransactionLink
                  to={
                    getSourceReferenceId(transaction.source, transaction.sourceReference)
                      ? urlMapper[transaction.source]?.(getSourceReferenceId(transaction.source, transaction.sourceReference)!)
                      : undefined
                  }
                  source={transaction.source}
                  reference={transaction.sourceReference}
                />
                <span> </span>
                (<FormattedMessage id={translations.enum.transactionCurrencyKind[transaction.currency]} />)
              </div>

              <div>
                <FormattedDate dateStyle="long" timeStyle="short" value={transaction.createdAt} />
              </div>

              {!!transaction.description && <div>{transaction.description}</div>}
            </div>

            <div
              className={classNames(
                'flex items-center gap-2 rounded px-3 py-2',
                transaction.kind === TransactionKind.Credit ? 'bg-success-light' : 'bg-error-light'
              )}
            >
              {transaction.kind === TransactionKind.Credit ? '+' : '-'}
              <CoinValue value={Math.abs(transaction.amount)} />
            </div>
          </Card>
        </li>
      ))}
    </ul>
  </LoadingState>
);
