import { translations } from '@binhatch/locale';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface Props {
  owner?: { id: string; name?: string; url: string };
}

export const CustomDealOwner: React.FC<Props> = ({ owner }) => {
  if (!owner) return null;

  return (
    <div className="truncate">
      <FormattedMessage
        id={translations.components.customDealOwner.owner}
        values={{
          owner: owner.name ?? '???',
          a: (children) => (
            <Link className="font-semibold" state={{ from: 1 }} to={owner.url}>
              {children}
            </Link>
          )
        }}
      />
    </div>
  );
};
