import { useRemoteData } from '@binhatch/hooks';
import { ensureArray } from '@binhatch/utility';
import classNames from 'classnames';
import React from 'react';

import { Loading, LoadingIndicator } from '../Loading';
import { InputProps } from './Input';
import { SearchableSelect } from './SearchableSelect';
import { Item } from './Select';

export type AsyncProps = Omit<InputProps<'input'>, 'value' | 'onChange'> & {
  id?: string;
  value?: string | string[];
  getItemsBySearch(search: string): Promise<Item<string>[]>;
  getItemsByIds(ids: string[]): Promise<Item<string>[]>;
  onChange?(value?: string): void;
};

export const AsyncSelect = React.forwardRef(function AsyncSelect(
  { value, className, getItemsBySearch, getItemsByIds, ...props }: AsyncProps,
  ref: React.Ref<HTMLInputElement>
) {
  const [inputValue, onInputValueChange] = React.useState('');

  const { data, isLoading } = useRemoteData(
    { key: 'useAsyncSelect' + props.id + props.name, search: inputValue, value },
    ({ search, value }) => {
      if (search.length) return getItemsBySearch(search);
      return getItemsByIds(value ? ensureArray(value) : []);
    }
  );

  const items = data ?? [];

  return (
    <div className={classNames('relative', className)}>
      <SearchableSelect {...props} {...{ ref, value, items, inputValue, onInputValueChange }} className="pr-14" />

      <Loading className="absolute right-9 top-2.5" visible={isLoading}>
        <LoadingIndicator className="h-5 w-5" />
      </Loading>
    </div>
  );
});
