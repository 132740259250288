import { translations } from '@binhatch/locale';
import {
  Checkbox,
  DeletableTag,
  Form,
  InputWithLabel,
  InstanceProps,
  Label,
  Loading,
  LoadingIndicator,
  LoadingMessage,
  ModalHeader,
  ModalLayout,
  ModalPrimaryButton,
  ModalSecondaryButton,
  SearchableSelect,
  Select,
  SelectItem,
  SubmitError,
  ValidatedField
} from '@binhatch/ui';
import classnames from 'classnames';
import { Condition, RuleKind, TagRule, TagRuleKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { useTagValues } from '@/hooks/useTagValues';
import { useTags } from '@/hooks/useTags';
import { ensureArray } from '@binhatch/utility';

interface Props extends InstanceProps<TagRule, Partial<TagRule>> {}

const schema = yup
  .object({
    tagKey: yup.string().required().label(translations.fields.promotionRuleCondition.label),
    allowAny: yup.boolean().required().label(translations.fields.allowAnyValue.label),
    tagValue: yup
      .array()
      .of(yup.string().min(0).required().label(translations.fields.promotionRuleValue.label))
      .required()
      .when('allowAny', { is: false, then: (schema) => schema.min(1) })
      .label(translations.fields.promotionRuleValue.label)
  })
  .required();

export const CreateRestrictionConditionModal = React.forwardRef<HTMLDivElement, Props>(({ data, initialFocus, className, onAction, onClose }, ref) => {
  const intl = useIntl();
  const [tagKey, setTagKey] = React.useState(data.tagKey ?? '');

  const tags = useTags();
  const tagValues = useTagValues(tagKey);

  const initialValues = React.useMemo<yup.InferType<typeof schema>>(
    () => ({
      tagKey: data.tagKey ?? '',
      allowAny: data.condition !== Condition.Contains,
      tagValue: ensureArray(data.value?.value ?? []).map((v) => v)
    }),
    [data]
  );

  const onSubmit = React.useCallback(
    async ({ tagKey, allowAny, tagValue }: yup.InferType<typeof schema>) => {
      await onAction({
        kind: RuleKind.Tag,
        condition: allowAny ? Condition.Exists : Condition.Contains,
        tagKey,
        value: allowAny ? undefined : { kind: TagRuleKind.Array, value: tagValue }
      });
    },
    [onAction]
  );

  return (
    <div {...{ ref }} className={classnames(className, 'max-w-sm')}>
      <ModalLayout>
        <ModalHeader {...{ onClose }}>
          <FormattedMessage id={translations.modals.updateRule.createTitle} values={{ isNegation: false }} />
        </ModalHeader>

        <div className="relative min-h-28">
          <Loading className="absolute inset-0 z-10 flex items-center justify-center" visible={tags.isLoading}>
            <LoadingMessage center>
              <LoadingIndicator className="h-5 w-5" />
              <div>
                <FormattedMessage id={translations.utils.loading} />
              </div>
            </LoadingMessage>
          </Loading>

          {!!tags.data && (
            <Form {...{ schema, initialValues, onSubmit }}>
              {({ values, invalid, dirtySinceLastSubmit, submitting, submitError, handleSubmit, form }) => (
                <form className="m-0 grid gap-4" onSubmit={handleSubmit}>
                  <ValidatedField
                    field={InputWithLabel}
                    id="tag-key"
                    input={Select}
                    items={
                      tags.data?.map(({ key, description }) => ({
                        value: key,
                        name: key,
                        description
                      })) ?? []
                    }
                    label={<FormattedMessage id={translations.fields.promotionRuleCondition.label} />}
                    name="tagKey"
                    placeholder={intl.formatMessage({ id: translations.fields.promotionRuleCondition.placeholder })}
                    readOnly={!!submitting}
                    selectItem={({ item, getDisplayName, ...props }: any) => (
                      <SelectItem {...props}>
                        <div className="font-medium capitalize">{item.description}</div>
                        <div>{item.name}</div>
                      </SelectItem>
                    )}
                    onChange={(tagKey: string) => {
                      form.change('tagValue', []);
                      setTagKey(tagKey);
                    }}
                  />

                  <ValidatedField field={Checkbox} fieldClassName="items-center" id="allow-any" name="allowAny" readOnly={submitting} type="checkbox">
                    <Label as="div">
                      <FormattedMessage id={translations.fields.allowAnyValue.label} />
                    </Label>
                  </ValidatedField>

                  {/** This is required because of a fringe bug. If we don't read the data length, the select is sometimes empty. */}
                  <div className="hidden">{tagValues.data?.length}</div>

                  {!!values.tagKey && !values.allowAny && !!tagValues.data && (
                    <div className="flex flex-col gap-2">
                      {tagValues.data.length > 0 ? (
                        <React.Fragment>
                          <ValidatedField
                            as={SearchableSelect}
                            field={InputWithLabel}
                            id="rule-value"
                            items={tagValues.data?.map(({ value, description }) => ({ value, name: value, description })) ?? []}
                            label={<div className="capitalize">{values.tagKey}</div>}
                            multiple
                            name="tagValue"
                            placeholder={intl.formatMessage({ id: translations.buttons.select })}
                            readOnly={!!submitting}
                            selectItem={({ item, getDisplayName, ...props }: any) => (
                              <SelectItem {...props}>
                                <div className="font-medium capitalize">{item.description}</div>
                                <div>{item.name}</div>
                              </SelectItem>
                            )}
                          />

                          <ul className="flex flex-wrap gap-2">
                            {values.tagValue.map((value) => (
                              <li key={value}>
                                <DeletableTag
                                  onDelete={() =>
                                    form.change(
                                      'tagValue',
                                      values.tagValue.filter((v) => v !== value)
                                    )
                                  }
                                >
                                  {value}
                                </DeletableTag>
                              </li>
                            ))}
                          </ul>
                        </React.Fragment>
                      ) : (
                        <ValidatedField
                          as="textarea"
                          description={values.tagValue.filter(Boolean).length}
                          field={InputWithLabel}
                          format={(v: string | string[]) => (Array.isArray(v) ? v.join('\r\n') : v)}
                          id="rule-value"
                          inputClassName="min-h-[8rem] h-full resize-none"
                          label={<div className="capitalize">{values.tagKey}</div>}
                          name="tagValue"
                          parse={(v: string | string[]) => (Array.isArray(v) ? v : v.split(/\r?\n|,|;/).map((v) => v?.trim() ?? ''))}
                          placeholder={intl.formatMessage({ id: translations.buttons.select })}
                          readOnly={!!submitting}
                          validated={false}
                        />
                      )}
                    </div>
                  )}

                  <SubmitError error={submitError} />

                  <div className="flex flex-row-reverse space-x-2">
                    <ModalPrimaryButton disabled={invalid && !dirtySinceLastSubmit} ref={initialFocus} onAction={() => handleSubmit()}>
                      <FormattedMessage id={translations.buttons.select} />
                    </ModalPrimaryButton>

                    <ModalSecondaryButton {...{ onClose }}>
                      <FormattedMessage id={translations.buttons.back} />
                    </ModalSecondaryButton>
                  </div>
                </form>
              )}
            </Form>
          )}
        </div>
      </ModalLayout>
    </div>
  );
});
