export interface PaginatedResult {
  nextToken?: string;
}

export const getAllFromApi = <T extends PaginatedResult, I>(
  callback: (nextPage?: string) => Promise<T>,
  builder: (result: T) => I[],
  max: number = Infinity
) => {
  const iterator = async (max: number, nextPage?: string): Promise<I[]> => {
    const result = await callback(nextPage);
    const items = builder(result);

    max -= items.length;

    if (!result.nextToken || max <= 0) return items;

    return [...items, ...(await iterator(max, result.nextToken))];
  };

  return iterator(max);
};
