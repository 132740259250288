import { translations } from '@binhatch/locale';
import { PageHeading, Tab, TabList } from '@binhatch/ui';
import { Feature, UserRole } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router';

import { Auth } from '@/containers/useAuth';
import { Configuration } from '@/containers/useConfiguration';

import { urls } from '@/utils/url';

export const SettingsPage: React.FC = () => {
  const { context } = Auth.useContainer();
  const [{ value: config }] = Configuration.useContainer();

  return (
    <main className="space-y-2 md:space-y-6">
      <PageHeading title={<FormattedMessage id={translations.pages.settings.title} />} />

      <TabList>
        <Tab end to={urls.settings.root}>
          <FormattedMessage id={translations.tabs.settings.general} />
        </Tab>

        {config.features?.has(Feature.CustomDeals) && context?.user.role === UserRole.SystemAdmin && (
          <Tab to={urls.settings.promotions}>
            <FormattedMessage id={translations.tabs.settings.promotions} />
          </Tab>
        )}

        {context?.user.role === UserRole.SystemAdmin && (
          <Tab to={urls.settings.integrations}>
            <FormattedMessage id={translations.tabs.settings.integrations} />
          </Tab>
        )}

        {context?.user.role === UserRole.SystemAdmin && (
          <Tab to={urls.settings.notifications}>
            <FormattedMessage id={translations.tabs.settings.notifications} />
          </Tab>
        )}

        {context?.user.role === UserRole.SystemAdmin && (
          <Tab to={urls.settings.clients}>
            <FormattedMessage id={translations.tabs.settings.clients} />
          </Tab>
        )}
      </TabList>

      <Outlet />
    </main>
  );
};
