import classNames from 'classnames';
import { Period, Promotion, UserPromotion } from 'flexinet-api';
import React from 'react';

import { PromotionArticles } from './PromotionArticle';
import { PromotionImage } from './PromotionImage';
import { PromotionItemPeriod } from './PromotionItemPeriod';

interface Props {
  promotion: Promotion | UserPromotion;
  days: number;
  period?: Period;
  className?: string;
}

export const PromotionItemContainer: React.FC<React.PropsWithChildren<{ className?: string }>> = (props) => (
  <div {...props} className={classNames('w-full overflow-hidden rounded-lg bg-white shadow', props.className)} />
);

export const PromotionItem: React.FC<Props> = ({ promotion, period, days, className }) => (
  <div className={classNames('flex flex-1 gap-4', className)}>
    <PromotionImage className="w-16 flex-shrink-0" image={promotion.imageURL} logo={promotion.brandLogoURL} />

    <div className="flex flex-1 flex-col gap-1">
      <PromotionItemPeriod {...{ promotion, period, days }} />

      <div className="font-semibold">{promotion.name}</div>

      <PromotionArticles tags={promotion.tags} />

      <div className="line-clamp-2 text-sm">{promotion.description}</div>
    </div>
  </div>
);
