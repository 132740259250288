import { translations } from '@binhatch/locale';
import { Loading, LoadingIndicator, LoadingMessage } from '@binhatch/ui';
import { UserRole } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Outlet, Route, Routes } from 'react-router';

import { urls } from '@/utils/url';

import { Auth } from '@/containers/useAuth';
import { Cart } from '@/containers/useCart';
import { SideNavigation } from '@/containers/useSideNavigation';

import { Navigation } from '@/components/Navigation';

import { ClientCustomDealListPage } from '@/pages/clients/ClientCustomDealListPage';
import { ClientDetailPage } from '@/pages/clients/ClientDetailPage';
import { ClientListPage } from '@/pages/clients/ClientListPage';
import { ClientOrderListPage } from '@/pages/clients/ClientOrderListPage';
import { ClientPromotionListPage } from '@/pages/clients/ClientPromotionListPage';
import { ClientTransactionPage } from '@/pages/clients/ClientTransactionPage';
import { ClientUserListPage } from '@/pages/clients/ClientUserListPage';
import { CustomDealDetailPage } from '@/pages/customDeals/CustomDealDetailPage';
import { CustomDealListPage } from '@/pages/customDeals/CustomDealListPage';
import { CustomDealProgressPage } from '@/pages/customDeals/CustomDealProgressPage';
import { CustomDealUpdatePage } from '@/pages/customDeals/CustomDealUpdatePage';
import { PromotionConfigurationPage } from '@/pages/promotions/PromotionConfigurationPage';
import { PromotionDetailPage } from '@/pages/promotions/PromotionDetailPage';
import { PromotionHistoryPage } from '@/pages/promotions/PromotionHistoryPage';
import { PromotionListPage } from '@/pages/promotions/PromotionListPage';
import { PromotionProgressPage } from '@/pages/promotions/PromotionProgressPage';
import { PromotionUpdatePage } from '@/pages/promotions/PromotionUpdatePage';
import { ReportsPage } from '@/pages/reports/ReportsPage';
import { SalesUserClientPage } from '@/pages/sales/SalesUserClientPage';
import { SalesUserCustomDealListPage } from '@/pages/sales/SalesUserCustomDealListPage';
import { SalesUserDetailPage } from '@/pages/sales/SalesUserDetailPage';
import { SalesUserListPage } from '@/pages/sales/SalesUserListPage';
import { SalesUserSubordinatePage } from '@/pages/sales/SalesUserSubordinatePage';
import { SalesUserTransactionPage } from '@/pages/sales/SalesUserTransactionPage';
import { SettingsClientsPage } from '@/pages/settings/SettingsClientsPage';
import { SettingsGeneralPage } from '@/pages/settings/SettingsGeneralPage';
import { SettingsIntegrationsPage } from '@/pages/settings/SettingsIntegrationsPage';
import { SettingsNotificationsPage } from '@/pages/settings/SettingsNotificationsPage';
import { SettingsPage } from '@/pages/settings/SettingsPage';
import { SettingsPromotionsPage } from '@/pages/settings/SettingsPromotionsPage';
import { CartPage } from '@/pages/shop/CartPage';
import { CartSlideOver } from '@/pages/shop/CartSlideOver';
import { OrderDetailPage } from '@/pages/shop/OrderDetailPage';
import { OrderListPage } from '@/pages/shop/OrderListPage';
import { ProductDetailPage } from '@/pages/shop/ProductDetailPage';
import { ProductReviewPage } from '@/pages/shop/ProductReviewPage';
import { ProductUpdatePage } from '@/pages/shop/ProductUpdatePage';
import { ShopManagerPage } from '@/pages/shop/ShopManagerPage';
import { ShopPage } from '@/pages/shop/ShopPage';

export const App: React.FC = () => {
  const { authenticated, loading, context } = Auth.useContainer();

  if (!authenticated || loading)
    return (
      <Loading className="flex flex-1 flex-col justify-center" visible>
        <LoadingMessage center>
          <LoadingIndicator className="h-6 w-6" />
          <div>
            <FormattedMessage id={translations.utils.loading} />
          </div>
        </LoadingMessage>
      </Loading>
    );

  if (!context) return null;

  return (
    <div className="flex flex-1 flex-col lg:flex-row">
      <SideNavigation.Provider>
        <Navigation />

        <div className="flex min-w-0 flex-1 flex-col p-4 md:px-12 md:py-6">
          <Routes>
            <Route element={<PromotionListPage />} path={urls.promotions.root} />
            {context?.user.role === UserRole.SystemAdmin && (
              <React.Fragment>
                <Route element={<PromotionUpdatePage />} path={urls.promotions.new} />
                <Route element={<PromotionUpdatePage />} path={urls.promotions.update} />
                <Route element={<PromotionUpdatePage clone />} path={urls.promotions.clone} />
              </React.Fragment>
            )}
            <Route element={<PromotionDetailPage />} path={urls.promotions.one}>
              <Route element={<PromotionProgressPage />} path={urls.promotions.one} />
              <Route element={<PromotionConfigurationPage />} path={urls.promotions.config} />
              {context?.user.role === UserRole.SystemAdmin && <Route element={<PromotionHistoryPage />} path={urls.promotions.history} />}
            </Route>

            <Route element={<CustomDealListPage />} path={urls.customDeals.root} />
            <Route element={<CustomDealUpdatePage />} path={urls.customDeals.new} />
            <Route element={<CustomDealUpdatePage />} path={urls.customDeals.update} />
            <Route element={<CustomDealUpdatePage clone />} path={urls.customDeals.clone} />
            <Route element={<CustomDealDetailPage />} path={urls.customDeals.one}>
              <Route element={<CustomDealProgressPage />} path={urls.customDeals.one} />
              <Route element={<PromotionConfigurationPage />} path={urls.customDeals.config} />
              <Route element={<PromotionHistoryPage />} path={urls.customDeals.history} />
            </Route>

            {context?.user.role === UserRole.SystemAdmin ? (
              <React.Fragment>
                <Route element={<ShopManagerPage />} path={urls.shop.root} />
                <Route element={<ProductUpdatePage />} path={urls.shop.products.one} />
                <Route element={<ProductReviewPage />} path={urls.shop.products.review} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Route
                  element={
                    <Cart.Provider>
                      <Outlet />
                      <CartSlideOver />
                    </Cart.Provider>
                  }
                >
                  <Route element={<ShopPage />} path={urls.shop.root} />
                  <Route element={<CartPage />} path={urls.shop.cart} />
                  <Route element={<ProductDetailPage />} path={urls.shop.products.one} />
                </Route>
              </React.Fragment>
            )}

            <Route element={<OrderListPage />} path={urls.orders.root} />
            <Route element={<OrderDetailPage />} path={urls.orders.one} />

            <Route element={<SalesUserListPage />} path={urls.sales.root} />
            <Route element={<SalesUserDetailPage />} path={urls.sales.one}>
              <Route element={<SalesUserSubordinatePage />} path={urls.sales.subordinates} />
              <Route element={<SalesUserCustomDealListPage />} path={urls.sales.customDeals} />
              <Route element={<SalesUserTransactionPage />} path={urls.sales.transactions} />
              <Route element={<SalesUserClientPage />} path={urls.sales.clients} />
            </Route>

            <Route element={<ClientListPage />} path={urls.clients.root} />
            <Route element={<ClientDetailPage />} path={urls.clients.one}>
              <Route element={<ClientPromotionListPage />} path={urls.clients.promotions} />
              <Route element={<ClientCustomDealListPage />} path={urls.clients.customDeals} />
              <Route element={<ClientOrderListPage />} path={urls.clients.orders} />
              <Route element={<ClientTransactionPage />} path={urls.clients.transactions} />
              <Route element={<ClientUserListPage />} path={urls.clients.users} />
            </Route>

            {context?.user.role === UserRole.SystemAdmin && <Route element={<ReportsPage />} path={urls.reports} />}

            <Route element={<SettingsPage />} path={urls.settings.root}>
              <Route element={<SettingsGeneralPage />} path={urls.settings.root} />
              {context?.user.role === UserRole.SystemAdmin && <Route element={<SettingsPromotionsPage />} path={urls.settings.promotions} />}
              {context?.user.role === UserRole.SystemAdmin && <Route element={<SettingsIntegrationsPage />} path={urls.settings.integrations} />}
              {context?.user.role === UserRole.SystemAdmin && <Route element={<SettingsNotificationsPage />} path={urls.settings.notifications} />}
              {context?.user.role === UserRole.SystemAdmin && <Route element={<SettingsClientsPage />} path={urls.settings.clients} />}
              <Route element={<Navigate to={urls.settings.integrations} />} path={urls.settings.root} />
            </Route>

            <Route element={<Navigate replace to={urls.promotions.root} />} path="*" />
          </Routes>
        </div>
      </SideNavigation.Provider>
    </div>
  );
};
