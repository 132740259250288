import { useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { DynamicBackButton, OrderDetail, PageLoaderHeading } from '@binhatch/ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { clientApi, orderApi, userApi } from '@/integrations/api';
import { urls } from '@/utils/url';

export const OrderDetailPage: React.FC = () => {
  const { orderId } = useStrictParams<{ orderId: string }>();

  const order = useRemoteData({ key: `useOrder`, orderId }, async ({ orderId }) => {
    return orderApi.getOrder(orderId).then((r) => r.data);
  });

  const userId = order.data?.userID;

  const user = useRemoteData({ key: `useUser`, userId, skip: !userId }, async ({ userId }) => {
    if (!userId) return;
    return userApi.getUserByID(userId).then((r) => ({ user: r.data, url: urls.sales.getOne({ userId }) }));
  });

  const clientId = user.data?.user.clientId;

  const client = useRemoteData({ key: `useClient`, clientId, skip: !clientId }, async ({ clientId }) => {
    if (!clientId) return;
    return clientApi.getClient(clientId).then((r) => ({ client: r.data, url: urls.clients.getOne({ clientId }) }));
  });

  return (
    <main className="space-y-6">
      <div>
        <DynamicBackButton />

        <PageLoaderHeading className="flex-1" loading={order.isLoading || order.isValidating}>
          <FormattedMessage id={translations.components.orderDetail.title} />
          {order.data?.id ? ` #${order.data?.id}` : ''}
        </PageLoaderHeading>
      </div>

      <OrderDetail
        client={client.data}
        getProductUrl={(productId) => urls.shop.products.getOne({ productId })}
        loading={order.isLoading || order.isValidating}
        order={order.data}
        user={user.data}
      />
    </main>
  );
};
