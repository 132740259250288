import { translations } from '@binhatch/locale';
import { ensureArray } from '@binhatch/utility';
import { Condition, Tag, TagRule } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  rule: TagRule;
  tags?: Tag[];
  className?: string;
}

export const CustomDealRule: React.FC<Props> = ({ rule, tags, className }) => (
  <div {...{ className }}>
    <span className="font-semibold capitalize">{tags?.find((tag) => tag.key === rule.tagKey)?.description ?? rule.tagKey}:</span>{' '}
    {rule.condition === Condition.Exists ? (
      <FormattedMessage id={translations.fields.allowAnyValue.label} />
    ) : (
      ensureArray(rule.value?.value)
        .slice(0, 3)
        .join(', ')
    )}
  </div>
);
