import { useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Card, LoadingState } from '@binhatch/ui';
import { getUserName } from '@binhatch/utility';
import classNames from 'classnames';
import { AuditLogObjectType, Promotion } from 'flexinet-api';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link, useOutletContext } from 'react-router-dom';

import { auditApi } from '@/integrations/api';
import { urls } from '@/utils/url';

export const PromotionHistoryPage: React.FC = () => {
  const promotion = useOutletContext<Promotion>();

  const promotionId = promotion.id;

  const audit = useRemoteData({ key: 'usePromotionAudit', promotionId }, ({ promotionId }) =>
    auditApi.listAuditLogs(AuditLogObjectType.Promotion, promotionId).then((r) => r.data.auditLogs)
  );

  return (
    <Card className="flex flex-col gap-8">
      <LoadingState loading={audit.isLoading}>
        <ol className="grid gap-8">
          {audit.data?.map((log, index, logs) => (
            <li className="relative pl-8" key={index}>
              {index < logs.length - 1 && <div className="bg-shade absolute -bottom-8 left-0.5 top-4 w-0.5 translate-x-1/2" />}
              <div
                className={classNames(
                  'absolute left-1 top-3 h-8 w-8 -translate-x-1/2 -translate-y-1/2 rounded-full border-[12px] border-white',
                  index === 0 ? 'bg-brand' : 'bg-shade'
                )}
              />

              <div>
                <div className="truncate">
                  <FormattedMessage
                    id={translations.enum.promotionAuditLogAction[log.action]}
                    values={{
                      type: promotion.type,
                      strong: (children) => <strong className="font-semibold">{children}</strong>,
                      user: (
                        <Link className="font-semibold" state={{ from: 1 }} to={urls.sales.getOne({ userId: log.user.id })}>
                          {getUserName(log.user) || log.user.details.email || '???'}
                        </Link>
                      )
                    }}
                  />
                </div>

                <FormattedDate dateStyle="long" timeStyle="short" value={log.timestamp} />
              </div>
            </li>
          ))}
        </ol>
      </LoadingState>
    </Card>
  );
};
