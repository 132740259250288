import { BonusValue, Card, CustomDealRule, PromotionTargetProgress } from '@binhatch/ui';
import { getCurrentPeriod, getFallbackPeriod, getLastCustomDealClientProgress, getPromotionDays } from '@binhatch/utility';
import { Promotion } from 'flexinet-api';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { Configuration } from '@/containers/useConfiguration';

import { useTags } from '@/hooks/useTags';
import { productApi } from '@/integrations/api';
import { useCustomDealProgress } from './useCustomDealProgress';

export const CustomDealProgressPage: React.FC = () => {
  const [{ value: configuration }] = Configuration.useContainer();

  const promotion = useOutletContext<Promotion>();
  const tags = useTags();

  const progress = useCustomDealProgress({ promotions: [promotion], realtime: configuration?.realtime });

  const { groups } = React.useMemo(() => {
    const period = getCurrentPeriod(promotion.periods ?? []) ?? getFallbackPeriod(promotion.periods ?? []);

    return {
      promotion,
      days: period ? getPromotionDays(period) : 0,
      period,
      groups: promotion.ruleGroups.map((group) => ({
        rules: group.rules,
        progress: getLastCustomDealClientProgress(promotion, period, group.target, progress.data ?? [], (progress) => {
          if (!group.id || !progress.value_detailed?.[group.id]) return 0;
          return parseFloat(progress.value_detailed[group.id]);
        })
      }))
    };
  }, [promotion, progress.data]);

  return (
    <React.Fragment>
      <Card className="flex flex-col gap-8">
        {groups.map(({ rules, progress }, index) => (
          <PromotionTargetProgress
            key={index}
            name={
              <React.Fragment>
                {rules.map((rule, index) => (
                  <CustomDealRule {...{ rule }} key={index} tags={tags.data} />
                ))}
              </React.Fragment>
            }
            {...{ promotion, progress }}
          >
            <div className="flex flex-1 justify-end">
              <BonusValue
                getProduct={(id) => productApi.getUserProductById(id).then((r) => r.data)}
                reference={progress.level.bonus.ref}
                unit={progress.level.bonus.mu}
                value={progress.level.bonus.value}
              />
            </div>
          </PromotionTargetProgress>
        ))}
      </Card>
    </React.Fragment>
  );
};
