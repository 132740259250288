import { useDynamicBack, useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import {
  Button,
  Card,
  Checkbox,
  CoinInput,
  DynamicBackButton,
  Form,
  GalleryModal,
  InputWithLabel,
  LoadingButton,
  LoadingState,
  Modal,
  PageHeading,
  ProductImage,
  PromotionSegments,
  Skeleton,
  SubmitError,
  ValidatedField,
  useModal
} from '@binhatch/ui';
import { ShoppingCartIcon, UserIcon } from '@heroicons/react/24/outline';
import { Product, ProductStatus, ProductUsage, Segment } from 'flexinet-api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { productApi } from '@/integrations/api';

import { SelectSegmentModal } from '../promotions/modals/SelectSegmentModal';

const schema = yup
  .object({
    value: yup.number().min(0).required().label(translations.fields.productPrice.label),
    usage: yup
      .array()
      .of(yup.mixed<ProductUsage>().oneOf(Object.values(ProductUsage)).required())
      .min(0)
      .required(),
    segments: yup.array().of(yup.mixed<Segment>().required())
  })
  .required();

export const ProductUpdatePage: React.FC = () => {
  const intl = useIntl();
  const { productId } = useStrictParams<{ productId: string }>();
  const onBack = useDynamicBack();

  const segmentModal = useModal(SelectSegmentModal);

  const product = useRemoteData({ key: `useProduct`, productId }, async ({ productId }): Promise<Product> => {
    return productApi.getProductById(productId).then((r) => r.data);
  });

  const onSubmit = React.useCallback(
    async ({ value, usage, segments = [] }: yup.InferType<typeof schema>) => {
      if (!productId || !product.data) throw new Error(`Product not specified.`);

      await productApi.updateProduct(productId, {
        ...product.data,
        value,
        usage,
        segments,
        status: ProductStatus.Active
      });

      onBack();
    },
    [productId, product, onBack]
  );

  const initialValues = React.useMemo(
    () => ({
      value: product.data?.value as unknown as number,
      usage: product.data?.usage ?? [],
      segments: product.data?.segments ?? []
    }),
    [product]
  );

  const galleryModal = useModal(GalleryModal);

  return (
    <React.Fragment>
      <main>
        <LoadingState loading={product.isLoading || product.isValidating}>
          <div className="flex flex-col items-start gap-4">
            <DynamicBackButton />

            <Form {...{ initialValues, schema, onSubmit }}>
              {({ values, submitting, submitError, handleSubmit, form }) => (
                <form className="flex w-full flex-col gap-4" onSubmit={handleSubmit}>
                  <Card className="flex flex-col gap-8">
                    <div className="flex flex-col gap-8 lg:flex-row">
                      <div className="flex gap-4 lg:w-64 lg:flex-col">
                        <button
                          className="block w-full cursor-pointer"
                          type="button"
                          onClick={() => galleryModal.open({ images: product.data?.media ?? [], selected: product.data?.media[0] }).catch(() => void 0)}
                        >
                          <ProductImage src={product.data?.media[0]} />
                        </button>

                        <ul className="flex w-1/4 flex-col gap-4 lg:grid lg:w-auto lg:grid-cols-3">
                          {(product.data?.media.slice(0, 3) ?? Array.from({ length: 3 })).map((src, index) => (
                            <li key={index}>
                              <button
                                className="block w-full cursor-pointer"
                                type="button"
                                onClick={() => galleryModal.open({ images: product.data?.media ?? [], selected: src }).catch(() => void 0)}
                              >
                                <ProductImage {...{ src }} />
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className="flex max-w-xl flex-1 flex-col gap-4">
                        <div className="flex flex-col gap-4">
                          <Skeleton className="mb-2 w-32" visible={!product.data}>
                            <PageHeading title={product.data?.name} />
                          </Skeleton>

                          <div className="grid grid-cols-2 gap-4">
                            <InputWithLabel as="div" label={<FormattedMessage id={translations.pages.productDetail.sku} />} readOnly>
                              <Skeleton className="mb-2 w-32" size="h-5" visible={!product.data}>
                                <div>{product.data?.productCode}</div>
                              </Skeleton>
                            </InputWithLabel>

                            <InputWithLabel as="div" label={<FormattedMessage id={translations.pages.productDetail.category} />} readOnly>
                              <Skeleton className="mb-2 w-32" size="h-5" visible={!product.data}>
                                <div>{product.data?.category}</div>
                              </Skeleton>
                            </InputWithLabel>
                          </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                          <ValidatedField
                            field={InputWithLabel}
                            id="price"
                            input={CoinInput}
                            inputClassName="w-full"
                            label={<FormattedMessage id={translations.fields.productPrice.label} />}
                            name="value"
                            placeholder={intl.formatMessage({ id: translations.fields.productPrice.placeholder })}
                            readOnly={submitting}
                          />

                          <InputWithLabel as="div" className="text-right" label={<FormattedMessage id={translations.fields.productQuantity.label} />} readOnly>
                            <Skeleton className="mb-2 w-32" size="h-5" visible={!product.data}>
                              <div>{product.data?.quantity}</div>
                            </Skeleton>
                          </InputWithLabel>
                        </div>
                      </div>
                    </div>

                    <div className="w-full space-y-1">
                      <div className="font-semibold">
                        <FormattedMessage id={translations.pages.productDetail.description} />
                      </div>
                      <Skeleton className="mb-2 inline-flex w-full" size="h-5" visible={!product.data} />
                      <Skeleton className="mb-2 inline-flex w-full" size="h-5" visible={!product.data} />
                      <Skeleton className="mb-2 inline-flex w-32" size="h-5" visible={!product.data} />
                      {product.data?.description?.split(/\r?\n/).map((line, index) => <div key={index}>{line || '\xa0'}</div>)}
                    </div>

                    <div className="grid w-full gap-8 xl:grid-cols-2">
                      <div className="flex flex-col gap-2">
                        <div className="bg-shade-light flex items-center p-3 px-4 font-semibold">
                          <ShoppingCartIcon className="mr-2 h-5 w-5" />
                          <FormattedMessage id={translations.pages.productDetail.visibility.title} />
                        </div>

                        <ValidatedField field={Checkbox} id="usage-promotion" name="usage" type="checkbox" value={ProductUsage.Promotion}>
                          <FormattedMessage id={translations.pages.productDetail.visibility.promotion} />
                        </ValidatedField>

                        <ValidatedField field={Checkbox} id="usage-webshop" name="usage" type="checkbox" value={ProductUsage.Webshop}>
                          <FormattedMessage id={translations.pages.productDetail.visibility.webshop} />
                        </ValidatedField>

                        {values.usage.includes(ProductUsage.Webshop) && (
                          <div className="flex flex-col gap-2">
                            <div className="text-sm font-semibold leading-5">
                              <FormattedMessage id={translations.pages.productDetail.visibility.selectSegment} />
                            </div>

                            <div>
                              <Button
                                appearance="outline"
                                className="h-10 px-4"
                                type="button"
                                onClick={() =>
                                  segmentModal
                                    .open({ segments: values.segments ?? [] })
                                    .then(({ segments }) => form.change('segments', segments))
                                    .catch(() => void 0)
                                }
                              >
                                <FormattedMessage id={translations.buttons.selectAudience} />
                              </Button>
                            </div>

                            <PromotionSegments
                              segments={values.segments ?? []}
                              onDelete={(segment) => form.change('segments', values.segments?.filter((s) => s.id !== segment.id) ?? [])}
                            />
                          </div>
                        )}
                      </div>

                      <div className="flex flex-col gap-2">
                        <div className="bg-shade-light flex items-center p-3 px-4 font-semibold">
                          <UserIcon className="mr-2 h-5 w-5" />
                          <FormattedMessage id={translations.pages.productDetail.balance.title} />
                        </div>

                        <ValidatedField field={Checkbox} id="usage-sales" name="usage" type="checkbox" value={ProductUsage.SalesBudget}>
                          <FormattedMessage id={translations.pages.productDetail.balance.usable} />
                        </ValidatedField>
                      </div>
                    </div>

                    <SubmitError error={submitError} />

                    <div className="flex justify-end gap-4">
                      <LoadingButton appearance="primary" className="h-14 px-4" loading={submitting} type="submit">
                        <FormattedMessage id={translations.buttons.save} />
                      </LoadingButton>
                    </div>
                  </Card>
                </form>
              )}
            </Form>
          </div>
        </LoadingState>
      </main>

      <Modal {...segmentModal.props} />
      <Modal {...galleryModal.props} className="items-stretch" important />
    </React.Fragment>
  );
};
