import { useRemoteData, useStrictParams } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { Button, Card, DynamicBackButton, Modal, PageHeading, PromotionArticles, PromotionItemPeriod, Tab, TabList, useModal } from '@binhatch/ui';
import { fromPromotion, getCurrentPeriod, getFallbackPeriod, getPromotionDays, sortPeriods } from '@binhatch/utility';
import { BeneficiaryKind } from 'flexinet-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import { promotionApi } from '@/integrations/api';
import { urls } from '@/utils/url';

import { DeleteConfirmationModal } from '@/modals/DeleteConfirmationModal';

import { CustomDealBeneficiaries } from './CustomDealBeneficiaries';
import { CustomDealOwner } from './CustomDealOwner';
import { useCustomDealBeneficiaries } from './useCustomDealBeneficiaries';
import { useCustomDealOwner } from './useCustomDealOwner';

export const CustomDealDetailPage: React.FC = () => {
  const { promotionId } = useStrictParams<{ promotionId: string }>();
  const navigate = useNavigate();

  const deleteModal = useModal(DeleteConfirmationModal);

  const promotion = useRemoteData({ key: 'usePromotion', promotionId, skip: !promotionId }, async ({ promotionId }) => {
    if (!promotionId) throw new Error('Promotion id is missing.');

    const promotion = await promotionApi
      .getPromotion(promotionId)
      .then((r) => r.data)
      .then(fromPromotion);

    return { ...promotion, periods: sortPeriods(promotion.periods ?? []) };
  });

  const owners = useCustomDealOwner(promotion.data ? [promotion.data] : []);
  const beneficiaries = useCustomDealBeneficiaries(promotion.data ? [promotion.data] : []);

  const period = React.useMemo(() => {
    if (!promotion.data) return;

    return getCurrentPeriod(promotion.data.periods) ?? getFallbackPeriod(promotion.data.periods);
  }, [promotion.data]);

  return (
    <React.Fragment>
      <main className="space-y-6">
        <div className="flex justify-between gap-4">
          <DynamicBackButton />

          <div className="flex flex-1 items-center justify-end gap-4">
            <Button
              appearance="secondary"
              as={Link}
              className="h-10 px-4"
              state={{ from: 1 }}
              to={urls.customDeals.getOne({ promotionId }, urls.customDeals.clone)}
            >
              <FormattedMessage id={translations.buttons.copy} />
            </Button>

            <Button
              appearance="secondary"
              className="h-10 px-4"
              onClick={() =>
                deleteModal
                  .open({ callback: () => promotionApi.deletePromotion(promotionId).then(() => void 0) })
                  .then(() => navigate(-1))
                  .catch(() => void 0)
              }
            >
              <FormattedMessage id={translations.buttons.delete} />
            </Button>
          </div>
        </div>

        <Card className="flex flex-col gap-4">
          <div className="flex flex-1 flex-wrap items-center justify-between gap-x-4 gap-y-1">
            <CustomDealBeneficiaries beneficiaries={beneficiaries.data?.get(promotionId)} type={promotion.data?.beneficiaryKind ?? BeneficiaryKind.Client} />

            <CustomDealOwner owner={owners.data?.get(promotionId)} />
          </div>

          <div className="flex w-full flex-1 flex-col gap-4">
            {!!promotion.data && <PromotionItemPeriod days={period ? getPromotionDays(period) : 0} promotion={promotion.data} {...{ period }} />}

            <PageHeading title={promotion.data?.name} />

            <PromotionArticles tags={promotion.data?.tags ?? {}} />

            <div>{promotion.data?.description}</div>

            <div>{promotion.data?.reference}</div>
          </div>
        </Card>

        <TabList>
          <Tab end to={urls.customDeals.getOne({ promotionId })}>
            <FormattedMessage id={translations.tabs.promotionProgress} />
          </Tab>

          <Tab to={urls.customDeals.getOne({ promotionId }, urls.customDeals.history)}>
            <FormattedMessage id={translations.tabs.promotionHistory} />
          </Tab>
        </TabList>

        {!!promotion.data && <Outlet context={promotion.data} />}
      </main>

      <Modal {...deleteModal.props} />
    </React.Fragment>
  );
};
