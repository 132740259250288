import { QueryParamConfig, decodeSingleQueryParam, useOrderParams, useQueryParams, useRemoteData } from '@binhatch/hooks';
import { translations } from '@binhatch/locale';
import { OrderItem, OrderList, PageHeading, Pagination, SearchInput } from '@binhatch/ui';
import { getAllFromApi } from '@binhatch/utility';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { clientApi, orderApi, userApi } from '@/integrations/api';
import { urls } from '@/utils/url';

const userId: QueryParamConfig<string | undefined> = {
  decode: (value) => decodeSingleQueryParam(value, undefined),
  encode: (value) => value
};

const config = { userId };

export const OrderListPage: React.FC = () => {
  const intl = useIntl();
  const [{ userId }] = useQueryParams({ config });
  const [query, updateQuery] = useOrderParams();

  const orders = useRemoteData(
    { key: `useOrders`, search: query.search, before: query.before, after: query.after, page: query.page, userId },
    async ({ search, before, after, page: nextToken, userId }) => {
      const { orders, ...r } = await orderApi
        .listOrders(nextToken, search, undefined, undefined, after?.toISOString(), before?.toISOString(), userId ? [userId] : [])
        .then((r) => r.data);

      const userIds = Array.from(new Set(orders.map((o) => o.userID).filter((id): id is string => !!id)));
      const clientIds = Array.from(new Set(orders.map((o) => o.clientID).filter((id): id is string => !!id)));

      const [users, clients] = await Promise.all([
        userIds.length
          ? getAllFromApi(
              (nextToken) => userApi.listUsers(nextToken, undefined, undefined, undefined, userIds).then((r) => r.data),
              (r) => r.users
            )
          : [],
        clientIds.length
          ? getAllFromApi(
              (nextToken) => clientApi.listClients(nextToken, undefined, undefined, clientIds).then((r) => r.data),
              (r) => r.clients
            )
          : []
      ]);

      return {
        ...r,
        orders: orders.map<OrderItem>((order) => {
          const user = users.find((u) => u.id === order.userID);
          const client = clients.find((c) => c.id === order.clientID);

          return { order, user, client, url: urls.orders.getOne({ orderId: order.id ?? '' }) };
        })
      };
    }
  );

  return (
    <main className="space-y-6">
      <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
        <PageHeading title={<FormattedMessage id={translations.pages.orderList.title} />} />

        <SearchInput
          className="w-full md:w-72"
          placeholder={intl.formatMessage({ id: translations.pages.orderList.search })}
          value={query.search}
          onChange={(search: string) => updateQuery({ page: undefined, search })}
        />
      </div>

      <OrderList loading={orders.isLoading || orders.isValidating} orders={orders.data?.orders ?? []} />

      <Pagination
        hasNext={!!orders.data?.nextToken}
        hasPrevious={!!orders.data?.prevToken}
        onNext={() => updateQuery({ page: orders.data?.nextToken })}
        onPrevious={() => updateQuery({ page: orders.data?.prevToken })}
      />
    </main>
  );
};
