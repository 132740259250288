import classNames from 'classnames';
import React from 'react';

export const Table: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="flow-root">
    <div className="-mx-4 -my-2 overflow-x-auto">
      <div className="inline-block min-w-full px-4 py-2 align-middle">
        <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
          <table className="w-full table-fixed divide-y divide-gray-300">{children}</table>
        </div>
      </div>
    </div>
  </div>
);

export const TableHead: React.FC<React.PropsWithChildren> = ({ children }) => (
  <thead className="bg-gray-50 text-sm font-semibold text-gray-900">
    <tr>{children}</tr>
  </thead>
);

export const TableCell: React.FC<React.PropsWithChildren<{ as: keyof JSX.IntrinsicElements; className?: string }>> = ({
  as: Component,
  className,
  ...props
}) => <Component {...props} className={classNames('px-3 py-3.5 first:pl-4 last:pr-4', className)} />;

export const TableHeadCell: React.FC<React.PropsWithChildren<{ className?: string }>> = (props) => <TableCell as="th" {...props} />;

export const TableBody: React.FC<React.PropsWithChildren> = (props) => <tbody {...props} className="divide-y divide-gray-200 bg-white text-sm" />;

export const TableRow: React.FC<React.PropsWithChildren<{ className?: string; onClick?(): void }>> = (props) => <tr {...props} />;

export const TableBodyCell: React.FC<React.PropsWithChildren<{ className?: string; colSpan?: number }>> = (props) => <TableCell as="td" {...props} />;
