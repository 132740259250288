import { customDealsApi } from '@/integrations/api';
import { useRemoteData } from '@binhatch/hooks';
import { MinTurnoverRestriction, MinTurnoverRestrictionKindEnum } from 'flexinet-api';

export const useRestrictions = () => {
  return useRemoteData({ key: 'useRestrictions' }, async () => {
    const { restrictions } = await customDealsApi.listCustomDealRestrictions().then((r) => r.data);

    const minTurnover = restrictions.find((r): r is MinTurnoverRestriction => r.kind === MinTurnoverRestrictionKindEnum.MinTurnover)?.values ?? [];

    return { minTurnover };
  });
};
